import * as dict from "../constants/Strings";
import {
  MILLISECONDS_IN_ONE_DAY,
  MILLISECONDS_IN_ONE_HOUR,
  MILLISECONDS_IN_ONE_MINUTE,
  MILLISECONDS_IN_ONE_MONTH,
  MILLISECONDS_IN_ONE_SECOND,
  MILLISECONDS_IN_ONE_YEAR,
  ZeroDate,
} from "../constants/Dates";
import { BOTypes } from "../constants/BOTypes";
import AppLogger from "./AppLogger";
import { round } from "./MathUtils";
import { StorageLevel } from "../constants/GlobalSettings";

export type TimeUnitTypes =
  | "year"
  | "month"
  | "day"
  | "hour"
  | "min"
  | "sec"
  | "ms";

export default class StringUtils {
  static _dict = dict.default;
  static _initialized = false;

  static get NewID() {
    return "new";
  }

  static isString(s) {
    if (typeof s === "string" || s instanceof String) {
      return true;
    }
    return false;
  }

  static shiftDate(date: Date, numDays: number) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
  }

  static getBeginningTimeForDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  // obj can be a parseable string, a millisecond timestamp, or a Date object
  static convertToDate(obj: string | number | Date) {
    return obj instanceof Date ? obj : new Date(obj);
  }

  static getDateTimeString(date: Date | undefined): string {
    if (date == null) {
      return "";
    }
    return date.toLocaleString();
  }

  static getDateString(date: Date | undefined): string {
    if (date == null) {
      return "";
    }
    return date.toLocaleDateString();
  }

  static getTimeString(date: Date | undefined): string {
    if (date == null) {
      return "";
    }
    return date.toLocaleTimeString();
  }

  static toyyyyMMdd(d: Date | undefined): string {
    if (d == null) {
      return "";
    }
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  static fromyyyyMMdd(d: string) {
    if (d?.length !== 10) {
      return undefined;
    }

    return new Date(
      Number(d.slice(0, 4)),
      Number(d.slice(5, 7)) - 1,
      Number(d.slice(8, 10))
    );
  }

  static tohh00(d: Date | undefined): string {
    if (d == null) {
      return "";
    }

    const hour = d.getHours().toString().padStart(2, "0");
    // const minute =  d.getMinutes().toString().padStart(2,"0");
    const minute = "00";
    return `${hour}:${minute}`;
  }

  static getLocaleString(ds: string | undefined | null) {
    if (ds == null) {
      return "";
    }

    return new Date(ds).toLocaleString();
  }

  static convertBytesOfSize(bytes: number): string {
    let size = bytes;
    let unit = "B";

    if (bytes / Math.pow(10, 12) > 1) {
      size = round(bytes / Math.pow(10, 12), 2);
      unit = "TB";
    } else if (bytes / Math.pow(10, 9) > 1) {
      size = round(bytes / Math.pow(10, 9), 2);
      unit = "GB";
    } else if (bytes / Math.pow(10, 6) > 1) {
      size = round(bytes / Math.pow(10, 6), 2);
      unit = "MB";
    } else if (bytes / Math.pow(10, 3) > 1) {
      size = round(bytes / Math.pow(10, 3), 2);
      unit = "kB";
    }

    return `${size} ${unit}`;
  }

  static convertToAmountOfTime(
    ds: string | number | Date,
    refDs: string | number | Date,
    smallestUnit?: TimeUnitTypes
  ) {
    const ms = new Date(refDs).getTime() - new Date(ds).getTime();
    let amountOfTime = 0;
    let unit = "";
    if (ms / 31536000000 > 1) {
      amountOfTime = Math.floor(ms / 31536000000);
      unit = "year";
    } else if (smallestUnit === "year") {
      unit = "year";
    } else if (ms / 2592000000 > 1) {
      amountOfTime = Math.floor(ms / 2592000000);
      unit = "month";
    } else if (smallestUnit === "month") {
      unit = "month";
    } else if (ms / 86400000 > 1) {
      amountOfTime = Math.floor(ms / 86400000);
      unit = "day";
    } else if (smallestUnit === "day") {
      unit = "day";
    } else if (ms / 3600000 > 1) {
      amountOfTime = Math.floor(ms / 3600000);
      unit = "hour";
    } else if (smallestUnit === "hour") {
      unit = "hour";
    } else if (ms / 60000 > 1) {
      amountOfTime = Math.floor(ms / 60000);
      unit = "min";
    } else if (smallestUnit === "min") {
      unit = "min";
    } else if (ms / 1000 > 1) {
      amountOfTime = Math.floor(ms / 1000);
      unit = "sec";
    } else if (smallestUnit === "sec") {
      unit = "sec";
    } else {
      amountOfTime = ms;
      unit = "ms";
    }
    return { amountOfTime, unit };
  }

  static convertFromTimeAgo(
    unit: TimeUnitTypes,
    amountOfTime?: number
  ): { start: number; end: number } {
    const now = Date.now();
    let start: number;
    let end: number;

    if (unit === "year" && amountOfTime) {
      start = now - (amountOfTime + 1) * MILLISECONDS_IN_ONE_YEAR;
      end = now - amountOfTime * MILLISECONDS_IN_ONE_YEAR;
    } else if (unit === "year") {
      start = now - MILLISECONDS_IN_ONE_YEAR;
      end = now;
    } else if (unit === "month" && amountOfTime) {
      start = now - (amountOfTime + 1) * MILLISECONDS_IN_ONE_MONTH;
      end = now - amountOfTime * MILLISECONDS_IN_ONE_MONTH;
    } else if (unit === "month") {
      start = now - MILLISECONDS_IN_ONE_MONTH;
      end = now;
    } else if (unit === "day" && amountOfTime) {
      start = now - (amountOfTime + 1) * MILLISECONDS_IN_ONE_DAY;
      end = now - amountOfTime * MILLISECONDS_IN_ONE_DAY;
    } else if (unit === "day") {
      start = now - MILLISECONDS_IN_ONE_DAY;
      end = now;
    } else if (unit === "hour" && amountOfTime) {
      start = now - (amountOfTime + 1) * MILLISECONDS_IN_ONE_HOUR;
      end = now - amountOfTime * MILLISECONDS_IN_ONE_HOUR;
    } else if (unit === "hour") {
      start = now - MILLISECONDS_IN_ONE_HOUR;
      end = now;
    } else if (unit === "min" && amountOfTime) {
      start = now - (amountOfTime + 1) * MILLISECONDS_IN_ONE_MINUTE;
      end = now - amountOfTime * MILLISECONDS_IN_ONE_MINUTE;
    } else if (unit === "min") {
      start = now - MILLISECONDS_IN_ONE_MINUTE;
      end = now;
    } else if (unit === "sec" && amountOfTime) {
      start = now - (amountOfTime + 1) * MILLISECONDS_IN_ONE_SECOND;
      end = now - amountOfTime * MILLISECONDS_IN_ONE_SECOND;
    } else if (unit === "sec") {
      start = now - MILLISECONDS_IN_ONE_SECOND;
      end = now;
    } else {
      start = now - (amountOfTime || 0);
      end = now;
    }

    return { start, end };
  }

  static getTimeOffsetString(
    ds: string | number | Date | undefined | null,
    refDs?: string | number | Date | undefined | null,
    smallestUnit?: TimeUnitTypes
  ) {
    if (ds == null) {
      return "";
    }
    if (refDs == null) {
      refDs = Date.now();
    }

    const { amountOfTime, unit } = StringUtils.convertToAmountOfTime(
      ds,
      refDs,
      smallestUnit
    );

    if (amountOfTime > 1) {
      return `${amountOfTime} ${unit}`;
    } else if (amountOfTime === 1) {
      return `${amountOfTime} ${unit}`;
    } else {
      return `< ${unit}`;
    }
  }

  static getDistanceString(dist: number, unitSystem: number = 0) {
    if (dist == null) {
      return null;
    }

    if (unitSystem === 0) {
      if (dist > 100000) {
        return `${round(dist / 1000, 0)} km`;
      } else if (dist > 10000) {
        return `${round(dist / 1000, 1)} km`;
      } else if (dist > 1000) {
        return `${round(dist / 1000, 2)} km`;
      } else {
        return `${round(dist, 0)} m`;
      }
    } else {
      let mile = 1609;

      if (dist > 100 * mile) {
        return round(dist / mile, 0) + " mi";
      } else if (dist > 10 * mile) {
        return round(dist / mile, 1) + " mi";
      } else if (dist > mile) {
        return round(dist / mile, 2) + " mi";
      } else {
        return round(dist * 1.094, 0) + " yd";
      }
    }
  }

  static getTimeAgoString(
    ds: string | number | Date | undefined | null,
    refDs?: string | number | Date | undefined | null,
    smallestUnit?: TimeUnitTypes
  ) {
    if (ds == null) {
      return "";
    }

    if (refDs == null) {
      refDs = Date.now();
    }

    if (smallestUnit == null) {
      smallestUnit = "min";
    }

    const { amountOfTime, unit } = StringUtils.convertToAmountOfTime(
      ds,
      refDs,
      smallestUnit
    );

    if (amountOfTime > 1) {
      return `${amountOfTime} ${unit}`;
    } else if (amountOfTime === 1) {
      return `${amountOfTime} ${unit}`;
    } else {
      return `< 1 ${unit}`;
    }
  }

  static fromTimeAgoString(timeAgo: string) {
    let amountOfTime: number | undefined;
    let unit: TimeUnitTypes;

    if (!timeAgo?.length) {
      return null;
    }

    const lessThanPrefix = "< 1 ";
    const amountAndUnitsSuffix = "";
    const amountAndUnitSuffix = "";
    if (timeAgo.startsWith(lessThanPrefix)) {
      unit = timeAgo
        .slice(lessThanPrefix.length)
        .split(" ")[0] as TimeUnitTypes;
    } else if (timeAgo.endsWith(amountAndUnitsSuffix)) {
      const arr = timeAgo.slice(0, -amountAndUnitsSuffix.length).split(" ");
      amountOfTime = Number(arr[0]);
      unit = arr[1] as TimeUnitTypes;
    } else if (timeAgo.endsWith(amountAndUnitSuffix)) {
      const arr = timeAgo.slice(0, -amountAndUnitSuffix.length).split(" ");
      amountOfTime = Number(arr[0]);
      unit = arr[1] as TimeUnitTypes;
    } else {
      return null;
    }

    const retval = StringUtils.convertFromTimeAgo(unit, amountOfTime);

    return retval;
  }

  static getUndefinedId(): string {
    // @ts-ignore
    return undefined;
  }

  static generateQuickGuid(): string {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  static generateTimeString(dateNumber: number) {
    return dateNumber.toString(16).substring(0, 12).padStart(12, "0");
  }

  static getRandom2FromID(id: string) {
    if (id?.length === 36) {
      return id.slice(19, 23);
    } else if (id?.length === 32) {
      return id.slice(18, 22);
    }
    return undefined;
  }

  static generateID(inOwner: string | null | undefined) {
    // {8}-{4}-{4}-{4}-{12}
    // {date1}-{date2}-{random}-{owner1}-{owner2}
    const datePart = this.generateTimeString(Date.now());

    let owner = inOwner?.replace("Google_", "");
    owner = owner?.replace("SignInWithApple_", "");
    owner = owner?.replace(/[^a-z0-9]/gi, "");
    if (!owner?.length || owner?.length < 12) {
      owner = Math.random().toString(16).substring(2, 14);
    }
    const random = Math.random().toString(16).substring(2, 14);
    const id = `${datePart.slice(0, 8)}-${datePart.slice(8, 12)}-${random.slice(
      0,
      4
    )}-${random.slice(4, 8)}-${owner.slice(-12)}`;
    return id;
  }

  static getDateFromId(id: string | null | undefined) {
    const idWO_ = id?.split("-")?.join("");
    const dateNumber = idWO_?.slice(0, 12);
    if (dateNumber && parseInt(dateNumber, 16)) {
      return new Date(parseInt(dateNumber, 16));
    }
    return null;
  }

  static replaceLocalCacheChars(path: string) {
    // return path.replace(/\/\:/gi,"_");
    return path.split("/").join("_");
  }

  static generateBOKey(
    ownerSub: string,
    owner: string | null | undefined,
    boParentType: BOTypes,
    boParentId: string,
    extension: string,
    level: string = StorageLevel,
    id: string | undefined = undefined
  ) {
    id = id ? id : this.generateID(owner);
    return `${level}/${ownerSub}/${boParentType?.toLowerCase()}/${boParentId}/${id}${
      extension?.length ? "." + extension : ""
    }`;
  }

  static getSessionStorageKey(
    level: string,
    ownerSub: string,
    owner: string,
    sessionId: string,
    extension: string
  ) {
    // Allow both extension with starting "." and not
    if (extension.startsWith(".")) {
      extension = extension.slice(1);
    }

    return StringUtils.generateBOKey(
      ownerSub,
      owner,
      "Session",
      sessionId,
      extension,
      level
    );
  }

  static generateAudioPath(owner: string, fileId: string): string {
    return `${owner}/audio/${fileId}`;
  }

  static getFolderPartId(id) {
    if (!id?.length) {
      AppLogger.debug(
        "Id does is either null or does not contain replace " + id
      );
      return undefined;
    }
    return id.replace("-", "").substring(0, 12);
  }

  static getTimeFromId(generatedId: string): number {
    const parts = generatedId?.split("-");
    if (parts.length !== 5) {
      return new Date(ZeroDate).getTime();
    } else {
      // Only first - needs to be replaced
      const timeString = this.getFolderPartId(generatedId);

      return new Date(timeString).getTime();
    }
  }

  static get2AlphaCode(input: string): string {
    if (!input?.length) {
      return "BB";
    } else {
      const replaced = input.replace(/[^a-z0-9]/gi, "");
      if (!replaced?.length) {
        return "CC";
      } else {
        return replaced[0] + replaced.length.toString(36)[0];
      }
    }
  }
}
