export class FileSystemWeb {
  public static _cacheDirectory = ''

  public static getCacheDirectory(): string | null {
    return this._cacheDirectory;
  }

  public static async downloadAsync(uri, fileUri) {
      return new Promise((resolve, reject) => {
        fetch(uri)
          .then(resp => resp.blob())
          .then(blob => {
            // @ts-ignore
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
              const filePath = this.fixWebFileUri(fileUri);
              // @ts-ignore
              window.localStorage.setItem(filePath, reader.result)
              resolve(reader.result);
            };
            reader.readAsDataURL(blob);
          })
          .catch(err => {
            reject(err);
          });
      })

  }

  public static async uploadAsync(uri, fileUri) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", fileUri);
        fetch(uri, { method: "POST", body: formData })
          .then(result => {
            resolve(result)
          })
          .catch(err => {
            reject(err);
          });
      })

  }

  public static async copyAsync(uri, fileUri) {

  }


  public static async getInfoAsync(fileUri): Promise<{ exists: boolean }> {
      return new Promise((resolve, reject) => {
        try {
          const filePath = this.fixWebFileUri(fileUri);
          // @ts-ignore
          resolve({ exists: window.localStorage.getItem(filePath) != null })
        } catch (err) {
          reject(err);
        }
      })

  }

  public static async readDirectoryAsync(fileUri): Promise<string[]> {
      return new Promise((resolve, reject) => {
        try {
          // @ts-ignore
          resolve(Object.entries(window.localStorage).map(arr => arr && arr[0]))
        } catch (err) {
          reject(err);
        }
      })

  }



  public static async readAsStringAsync(fileUri, webNotLocal?: boolean): Promise<string> {
      return new Promise(async (resolve, reject) => {
        try {
          if (webNotLocal) {
            const fetchResult = await fetch(fileUri)
            const textString = await fetchResult.text();
            resolve(textString)
          } else {
            const filePath = this.fixWebFileUri(fileUri);
            // @ts-ignore
            resolve(window.localStorage.getItem(filePath))
          }
        } catch (err) {
          reject(err);
        }
      })

  }


  public static async writeAsStringAsync(fileUri: string, content: any, webNotLocal?: boolean): Promise<void> {
    // //AppLogger.debug(`writeAsStringAsync ${fileUri}`)
      return new Promise((resolve, reject) => {
        try {
          if (webNotLocal) {
            // skip it
            resolve()
          } else {
            const filePath = this.fixWebFileUri(fileUri);
            // @ts-ignore
            window.localStorage.setItem(filePath, content);
            resolve()
          }
        } catch (err) {
          reject(err);
        }
      })

  }




  public static async deleteAsync(fileUri: string): Promise<void> {
    // //AppLogger.debug(`deleteAsync ${fileUri}`)
      return new Promise((resolve, reject) => {
        try {
          const filePath = this.fixWebFileUri(fileUri);
          // @ts-ignore
          window.localStorage.removeItem(filePath);
          resolve()
        } catch (err) {
          reject(err);
        }
      })

  }

  public static getDirectoryUri(fileUri: string) {
    const parts = fileUri.split('/')
    parts.splice(-1, 1)
    return parts.join('/')
  }

  public static fixWebFileUri(fileUri): string {
    const filePath = fileUri.replace("file://", "").replace(/\//g, "_");
    return filePath;
  }

  public static checkFilePrefix(fileUri: string): string {
    if (fileUri.startsWith("file://") || fileUri.startsWith("https://") ) {
      return fileUri;
    } else {
      return "file://" + fileUri;
    }
  }

}
