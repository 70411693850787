/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const updateInAppPurchases = /* GraphQL */ `mutation UpdateInAppPurchases($accountId: ID!) {
  updateInAppPurchases(accountId: $accountId) {
    id
    members
    purchases
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInAppPurchasesMutationVariables,
  APITypes.UpdateInAppPurchasesMutation
>;
export const createProfile = /* GraphQL */ `mutation CreateProfile(
  $input: CreateProfileInput!
  $condition: ProfileCondition
) {
  createProfile(input: $input, condition: $condition) {
    id
    name
    description
    owner
    email
    userCode
    sub
    internals
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileMutationVariables,
  APITypes.CreateProfileMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile(
  $input: UpdateProfileInput!
  $condition: ProfileCondition
) {
  updateProfile(input: $input, condition: $condition) {
    id
    name
    description
    owner
    email
    userCode
    sub
    internals
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const deleteProfile = /* GraphQL */ `mutation DeleteProfile(
  $input: DeleteProfileInput!
  $condition: ProfileCondition
) {
  deleteProfile(input: $input, condition: $condition) {
    id
    name
    description
    owner
    email
    userCode
    sub
    internals
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileMutationVariables,
  APITypes.DeleteProfileMutation
>;
export const createFamilyUser = /* GraphQL */ `mutation CreateFamilyUser(
  $input: CreateFamilyUserInput!
  $condition: FamilyUserCondition
) {
  createFamilyUser(input: $input, condition: $condition) {
    id
    name
    description
    owner
    internals
    members
    editors
    familyUserFamilyId
    memberUpdates
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFamilyUserMutationVariables,
  APITypes.CreateFamilyUserMutation
>;
export const updateFamilyUser = /* GraphQL */ `mutation UpdateFamilyUser(
  $input: UpdateFamilyUserInput!
  $condition: FamilyUserCondition
) {
  updateFamilyUser(input: $input, condition: $condition) {
    id
    name
    description
    owner
    internals
    members
    editors
    familyUserFamilyId
    memberUpdates
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFamilyUserMutationVariables,
  APITypes.UpdateFamilyUserMutation
>;
export const deleteFamilyUser = /* GraphQL */ `mutation DeleteFamilyUser(
  $input: DeleteFamilyUserInput!
  $condition: FamilyUserCondition
) {
  deleteFamilyUser(input: $input, condition: $condition) {
    id
    name
    description
    owner
    internals
    members
    editors
    familyUserFamilyId
    memberUpdates
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFamilyUserMutationVariables,
  APITypes.DeleteFamilyUserMutation
>;
export const createFamily = /* GraphQL */ `mutation CreateFamily($input: CreateFamilyInput!, $condition: FamilyCondition) {
  createFamily(input: $input, condition: $condition) {
    id
    name
    description
    owner
    internals
    members
    editors
    editorCode
    memberCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFamilyMutationVariables,
  APITypes.CreateFamilyMutation
>;
export const updateFamily = /* GraphQL */ `mutation UpdateFamily($input: UpdateFamilyInput!, $condition: FamilyCondition) {
  updateFamily(input: $input, condition: $condition) {
    id
    name
    description
    owner
    internals
    members
    editors
    editorCode
    memberCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFamilyMutationVariables,
  APITypes.UpdateFamilyMutation
>;
export const deleteFamily = /* GraphQL */ `mutation DeleteFamily($input: DeleteFamilyInput!, $condition: FamilyCondition) {
  deleteFamily(input: $input, condition: $condition) {
    id
    name
    description
    owner
    internals
    members
    editors
    editorCode
    memberCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFamilyMutationVariables,
  APITypes.DeleteFamilyMutation
>;
export const createPost = /* GraphQL */ `mutation CreatePost($input: CreatePostInput!, $condition: PostCondition) {
  createPost(input: $input, condition: $condition) {
    id
    name
    description
    owner
    postType
    members
    editors
    internals
    issues
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePostMutationVariables,
  APITypes.CreatePostMutation
>;
export const updatePost = /* GraphQL */ `mutation UpdatePost($input: UpdatePostInput!, $condition: PostCondition) {
  updatePost(input: $input, condition: $condition) {
    id
    name
    description
    owner
    postType
    members
    editors
    internals
    issues
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePostMutationVariables,
  APITypes.UpdatePostMutation
>;
export const deletePost = /* GraphQL */ `mutation DeletePost($input: DeletePostInput!, $condition: PostCondition) {
  deletePost(input: $input, condition: $condition) {
    id
    name
    description
    owner
    postType
    members
    editors
    internals
    issues
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePostMutationVariables,
  APITypes.DeletePostMutation
>;
export const deleteSession = /* GraphQL */ `mutation DeleteSession(
  $input: DeleteSessionInput!
  $condition: SessionCondition
) {
  deleteSession(input: $input, condition: $condition) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSessionMutationVariables,
  APITypes.DeleteSessionMutation
>;
export const archiveSession = /* GraphQL */ `mutation ArchiveSession(
  $input: ArchiveSessionInput!
  $condition: SessionCondition
) {
  archiveSession(input: $input, condition: $condition) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ArchiveSessionMutationVariables,
  APITypes.ArchiveSessionMutation
>;
export const restoreSession = /* GraphQL */ `mutation RestoreSession(
  $input: RestoreSessionInput!
  $condition: SessionCondition
) {
  restoreSession(input: $input, condition: $condition) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RestoreSessionMutationVariables,
  APITypes.RestoreSessionMutation
>;
export const deleteArchiveSession = /* GraphQL */ `mutation DeleteArchiveSession(
  $input: ArchiveSessionInput!
  $condition: SessionCondition
) {
  deleteArchiveSession(input: $input, condition: $condition) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteArchiveSessionMutationVariables,
  APITypes.DeleteArchiveSessionMutation
>;
export const joinFamilyWithCode = /* GraphQL */ `mutation JoinFamilyWithCode($input: JoinFamilyWithCodeInput) {
  joinFamilyWithCode(input: $input) {
    id
    name
    description
    owner
    internals
    members
    editors
    editorCode
    memberCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.JoinFamilyWithCodeMutationVariables,
  APITypes.JoinFamilyWithCodeMutation
>;
export const removeMemberEditorFromObject = /* GraphQL */ `mutation RemoveMemberEditorFromObject($input: RemoveMemberEditorInput) {
  removeMemberEditorFromObject(input: $input) {
    id
    boType
    result
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveMemberEditorFromObjectMutationVariables,
  APITypes.RemoveMemberEditorFromObjectMutation
>;
export const updateAccountWithPurchaseInfo = /* GraphQL */ `mutation UpdateAccountWithPurchaseInfo($input: [PurchaseInfoInput]) {
  updateAccountWithPurchaseInfo(input: $input) {
    id
    members
    purchases
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountWithPurchaseInfoMutationVariables,
  APITypes.UpdateAccountWithPurchaseInfoMutation
>;
export const deleteDeviceTrigger = /* GraphQL */ `mutation DeleteDeviceTrigger(
  $input: DeleteDeviceTriggerInput!
  $condition: DeviceTriggerCondition
) {
  deleteDeviceTrigger(input: $input, condition: $condition) {
    id
    name
    description
    deviceID
    sessionID
    commandID
    dogID
    members
    editors
    internals
    templateID
    dataType
    triggerState
    triggeringDevice
    startedAt
    pausedAt
    stoppedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceTriggerMutationVariables,
  APITypes.DeleteDeviceTriggerMutation
>;
export const updatePublicLink = /* GraphQL */ `mutation UpdatePublicLink(
  $input: PublicLinkInput!
  $condition: PublicLinkCondition
) {
  updatePublicLink(input: $input, condition: $condition) {
    id
    name
    description
    isValid
    internals
    postType
    published
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePublicLinkMutationVariables,
  APITypes.UpdatePublicLinkMutation
>;
export const deletePublicLink = /* GraphQL */ `mutation DeletePublicLink(
  $input: DeletePublicLinkInput!
  $condition: PublicLinkCondition
) {
  deletePublicLink(input: $input, condition: $condition) {
    id
    name
    description
    isValid
    internals
    postType
    published
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePublicLinkMutationVariables,
  APITypes.DeletePublicLinkMutation
>;
export const joinExternalDevice = /* GraphQL */ `mutation JoinExternalDevice($input: ExternalDeviceInput!) {
  joinExternalDevice(input: $input) {
    id
    name
    description
    uniqueId
    deviceType
    autoJoinSessions
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.JoinExternalDeviceMutationVariables,
  APITypes.JoinExternalDeviceMutation
>;
export const createUserAccount = /* GraphQL */ `mutation CreateUserAccount(
  $input: CreateUserAccountInput!
  $condition: ModelUserAccountConditionInput
) {
  createUserAccount(input: $input, condition: $condition) {
    id
    members
    purchases
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserAccountMutationVariables,
  APITypes.CreateUserAccountMutation
>;
export const updateUserAccount = /* GraphQL */ `mutation UpdateUserAccount(
  $input: UpdateUserAccountInput!
  $condition: ModelUserAccountConditionInput
) {
  updateUserAccount(input: $input, condition: $condition) {
    id
    members
    purchases
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserAccountMutationVariables,
  APITypes.UpdateUserAccountMutation
>;
export const deleteUserAccount = /* GraphQL */ `mutation DeleteUserAccount(
  $input: DeleteUserAccountInput!
  $condition: ModelUserAccountConditionInput
) {
  deleteUserAccount(input: $input, condition: $condition) {
    id
    members
    purchases
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserAccountMutationVariables,
  APITypes.DeleteUserAccountMutation
>;
export const createSession = /* GraphQL */ `mutation CreateSession(
  $input: CreateSessionInput!
  $condition: ModelSessionConditionInput
) {
  createSession(input: $input, condition: $condition) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSessionMutationVariables,
  APITypes.CreateSessionMutation
>;
export const updateSession = /* GraphQL */ `mutation UpdateSession(
  $input: UpdateSessionInput!
  $condition: ModelSessionConditionInput
) {
  updateSession(input: $input, condition: $condition) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSessionMutationVariables,
  APITypes.UpdateSessionMutation
>;
export const createDeviceTrigger = /* GraphQL */ `mutation CreateDeviceTrigger(
  $input: CreateDeviceTriggerInput!
  $condition: ModelDeviceTriggerConditionInput
) {
  createDeviceTrigger(input: $input, condition: $condition) {
    id
    name
    description
    deviceID
    sessionID
    commandID
    dogID
    members
    editors
    internals
    templateID
    dataType
    triggerState
    triggeringDevice
    startedAt
    pausedAt
    stoppedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceTriggerMutationVariables,
  APITypes.CreateDeviceTriggerMutation
>;
export const updateDeviceTrigger = /* GraphQL */ `mutation UpdateDeviceTrigger(
  $input: UpdateDeviceTriggerInput!
  $condition: ModelDeviceTriggerConditionInput
) {
  updateDeviceTrigger(input: $input, condition: $condition) {
    id
    name
    description
    deviceID
    sessionID
    commandID
    dogID
    members
    editors
    internals
    templateID
    dataType
    triggerState
    triggeringDevice
    startedAt
    pausedAt
    stoppedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceTriggerMutationVariables,
  APITypes.UpdateDeviceTriggerMutation
>;
export const createDeviceInput = /* GraphQL */ `mutation CreateDeviceInput(
  $input: CreateDeviceInputInput!
  $condition: ModelDeviceInputConditionInput
) {
  createDeviceInput(input: $input, condition: $condition) {
    id
    name
    description
    deviceID
    userID
    sessionID
    triggerID
    triggerTemplateID
    commandID
    dogID
    members
    editors
    startedAt
    stoppedAt
    internals
    dataType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceInputMutationVariables,
  APITypes.CreateDeviceInputMutation
>;
export const updateDeviceInput = /* GraphQL */ `mutation UpdateDeviceInput(
  $input: UpdateDeviceInputInput!
  $condition: ModelDeviceInputConditionInput
) {
  updateDeviceInput(input: $input, condition: $condition) {
    id
    name
    description
    deviceID
    userID
    sessionID
    triggerID
    triggerTemplateID
    commandID
    dogID
    members
    editors
    startedAt
    stoppedAt
    internals
    dataType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceInputMutationVariables,
  APITypes.UpdateDeviceInputMutation
>;
export const deleteDeviceInput = /* GraphQL */ `mutation DeleteDeviceInput(
  $input: DeleteDeviceInputInput!
  $condition: ModelDeviceInputConditionInput
) {
  deleteDeviceInput(input: $input, condition: $condition) {
    id
    name
    description
    deviceID
    userID
    sessionID
    triggerID
    triggerTemplateID
    commandID
    dogID
    members
    editors
    startedAt
    stoppedAt
    internals
    dataType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceInputMutationVariables,
  APITypes.DeleteDeviceInputMutation
>;
export const createDog = /* GraphQL */ `mutation CreateDog(
  $input: CreateDogInput!
  $condition: ModelDogConditionInput
) {
  createDog(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDogMutationVariables,
  APITypes.CreateDogMutation
>;
export const updateDog = /* GraphQL */ `mutation UpdateDog(
  $input: UpdateDogInput!
  $condition: ModelDogConditionInput
) {
  updateDog(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDogMutationVariables,
  APITypes.UpdateDogMutation
>;
export const deleteDog = /* GraphQL */ `mutation DeleteDog(
  $input: DeleteDogInput!
  $condition: ModelDogConditionInput
) {
  deleteDog(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDogMutationVariables,
  APITypes.DeleteDogMutation
>;
export const createMap = /* GraphQL */ `mutation CreateMap(
  $input: CreateMapInput!
  $condition: ModelMapConditionInput
) {
  createMap(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMapMutationVariables,
  APITypes.CreateMapMutation
>;
export const updateMap = /* GraphQL */ `mutation UpdateMap(
  $input: UpdateMapInput!
  $condition: ModelMapConditionInput
) {
  updateMap(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMapMutationVariables,
  APITypes.UpdateMapMutation
>;
export const deleteMap = /* GraphQL */ `mutation DeleteMap(
  $input: DeleteMapInput!
  $condition: ModelMapConditionInput
) {
  deleteMap(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMapMutationVariables,
  APITypes.DeleteMapMutation
>;
export const createCommand = /* GraphQL */ `mutation CreateCommand(
  $input: CreateCommandInput!
  $condition: ModelCommandConditionInput
) {
  createCommand(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    dogID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommandMutationVariables,
  APITypes.CreateCommandMutation
>;
export const updateCommand = /* GraphQL */ `mutation UpdateCommand(
  $input: UpdateCommandInput!
  $condition: ModelCommandConditionInput
) {
  updateCommand(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    dogID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommandMutationVariables,
  APITypes.UpdateCommandMutation
>;
export const deleteCommand = /* GraphQL */ `mutation DeleteCommand(
  $input: DeleteCommandInput!
  $condition: ModelCommandConditionInput
) {
  deleteCommand(input: $input, condition: $condition) {
    id
    name
    description
    internals
    members
    editors
    dogID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommandMutationVariables,
  APITypes.DeleteCommandMutation
>;
export const createDevice = /* GraphQL */ `mutation CreateDevice(
  $input: CreateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  createDevice(input: $input, condition: $condition) {
    id
    name
    description
    uniqueId
    deviceType
    autoJoinSessions
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceMutationVariables,
  APITypes.CreateDeviceMutation
>;
export const updateDevice = /* GraphQL */ `mutation UpdateDevice(
  $input: UpdateDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  updateDevice(input: $input, condition: $condition) {
    id
    name
    description
    uniqueId
    deviceType
    autoJoinSessions
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceMutationVariables,
  APITypes.UpdateDeviceMutation
>;
export const deleteDevice = /* GraphQL */ `mutation DeleteDevice(
  $input: DeleteDeviceInput!
  $condition: ModelDeviceConditionInput
) {
  deleteDevice(input: $input, condition: $condition) {
    id
    name
    description
    uniqueId
    deviceType
    autoJoinSessions
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceMutationVariables,
  APITypes.DeleteDeviceMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    name
    description
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    name
    description
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    name
    description
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createIssue = /* GraphQL */ `mutation CreateIssue(
  $input: CreateIssueInput!
  $condition: ModelIssueConditionInput
) {
  createIssue(input: $input, condition: $condition) {
    id
    name
    description
    issueType
    members
    editors
    internals
    posts
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIssueMutationVariables,
  APITypes.CreateIssueMutation
>;
export const updateIssue = /* GraphQL */ `mutation UpdateIssue(
  $input: UpdateIssueInput!
  $condition: ModelIssueConditionInput
) {
  updateIssue(input: $input, condition: $condition) {
    id
    name
    description
    issueType
    members
    editors
    internals
    posts
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIssueMutationVariables,
  APITypes.UpdateIssueMutation
>;
export const deleteIssue = /* GraphQL */ `mutation DeleteIssue(
  $input: DeleteIssueInput!
  $condition: ModelIssueConditionInput
) {
  deleteIssue(input: $input, condition: $condition) {
    id
    name
    description
    issueType
    members
    editors
    internals
    posts
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIssueMutationVariables,
  APITypes.DeleteIssueMutation
>;
