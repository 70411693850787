import { Component } from "react";
import styles from "../constants/Styles";
import AppLogger from "../utils/AppLogger";
import { LocationMatch, LocationHistory } from "../utils/NavUtils";
import { Link as PostLink } from "../utils/PostInternals";
import { LinkArticle } from "./links/LinkArticle";
import { Spinner, View } from "./ui";

export interface PrivacyPolicyProps {
  match: LocationMatch;
  history: LocationHistory;
}

export interface PrivacyPolicyState {
  loading: boolean;

  links: PostLink[];
  lastError: any;
}

export class PrivacyPolicy extends Component<PrivacyPolicyProps, PrivacyPolicyState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      links: [],
      lastError: null
    };
  }

  async componentDidMount() {
    try {
      const links = (await this.listPrivacyPolicyLinks()) || [];

      this.setState({ links, loading: false });
    } catch (err) {
      AppLogger.error(`Error in links ${err}`, null);
      this.setState({ loading: false });
    }
  }
  async listPrivacyPolicyLinks() {
    try {
      const privacypolicy = await fetch(`firstpage/privacypolicy.json`).then(r => r.json());

      return [privacypolicy] as PostLink[];
    } catch (err) {
      AppLogger.error(`Error in listFirstPageLinks ${err}`);
    }
  }

  render() {
    const { loading, links } = this.state;
    const { history, match } = this.props;



    if (loading) {
      return <Spinner  style={styles.containerStyle} />;
    }


    return (
      <View key="privacypolicy" style={{ margin: styles.margin, paddingTop:50 }}>
        <LinkArticle

          activeLink={links[0]}
          history={history}
          match={match}
        />
      </View>
    );
  }
}
