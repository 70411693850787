import { Component } from "react";
import Icons, { getIconElement } from "../../constants/Icons";
import styles from "../../constants/Styles";
import { AuthUtils } from "../../utils/AuthUtils";
import { getBOUserRightsFromBO } from "../../utils/BOUtils";
import { round } from "../../utils/MathUtils";
import { LocationHistory, LocationMatch } from "../../utils/NavUtils";
import { ContentBlock, Link } from "../../utils/PostInternals";
import { Button, Span, Spinner, Text, View } from "../ui";
import { getMediaBlocks } from "./utils/LinkContentUtils";
import {
  block2React,
  getAuthor,
  getCardImage as getArticleImage,
  getExcerpt,
  getLinkType,
  getTags,
  getTitle,
} from "./utils/LinkReactUtils";

export interface LinkArticleProps {
  activePost?: any;
  activeLink: Link | undefined;
  user?: any;
  history: LocationHistory;
  match: LocationMatch;
  containerStyle?: any;
  firstPageMode?: boolean;
}

export interface LinkArticleState {
  firstPageMode?: boolean;
  collapsed?: boolean;
}

export class LinkArticle extends Component<LinkArticleProps, LinkArticleState> {
  constructor(props) {
    super(props);

    this.state = {
      firstPageMode: props.firstPageMode === true,
      collapsed: props.firstPageMode === true,
    };
  }

  onInfoBlockClick() {
    const { collapsed } = this.state;
    const {firstPageMode: propsFirstPageMode} = this.props;
    let updatedState: LinkArticleState = {  };
    if (propsFirstPageMode && collapsed) {
      updatedState.firstPageMode = false;
      updatedState.collapsed = false;
    } else {
      updatedState.collapsed = !collapsed;
      updatedState.firstPageMode = propsFirstPageMode;
    }
    this.setState(updatedState);
  }

  getInfoBlock(activeLink: Link, containerProps) {
    if (!activeLink || activeLink?.hideTitle) {
      return null;
    }

    const { firstPageMode } = this.state;
    if (firstPageMode) {

      return (
        <View
          key={"infoblock" + activeLink?.id}
          onClick={this.onInfoBlockClick.bind(this)}
          style={{ ...containerProps?.style, bottom: 130 }}
        >
          {/* {getLinkType(activeLink, {})} */}
          <View style={{ margin: 0 }} />
          {getTitle(activeLink, {})}
          <View style={{ margin: 0 }} />
          {getExcerpt(activeLink, {})}
        </View>
      );
    } else {
      return (
        <View
          key={"infoblock" + activeLink?.id}
          style={containerProps?.style}
          onClick={this.onInfoBlockClick.bind(this)}
        >
          {getLinkType(activeLink)}
          <View style={{ margin: 0 }} />
          {this.getTitle(activeLink)}
          <View style={{ margin: 0 }} />
          {this.getExcerpt(activeLink)}
        </View>
      );
    }
  }

  getTitle(activeLink: Link, infoblockProps?) {
    return (
      <Text
        style={{
          boxShadow: "-5px 0 0 white",
          paddingRight: 5,
          textAlign: "start",
          background: "white",
          display: "inline",
          textTransform: "uppercase",
          fontSize: 33,
          fontWeight: "bold",
          color: "purple",
          paddingBottom: 14,
        }}
      >
        {activeLink?.title || "No title"}
      </Text>
    );
  }

  getExcerpt(activeLink: Link) {
    return (
      <View
        style={{ background: "white", display: "inline", paddingBottom: 14 }}
      >
        <Span
          style={{
            textAlign: "start",
            fontSize: 33,
            position: "relative",
            lineHeight: "33px",
            verticalAlign: "middle",
            boxShadow: "-5px 0 0 white",
            marginRight: 15,
          }}
        >
          {`${activeLink?.content?.excerpt}`}
        </Span>
      </View>
    );
  }

  getArticleContent(activeLink: Link) {
    if (!activeLink?.content?.blocks?.length) {
      return null;
    }

    const blocks = activeLink?.content?.blocks;
    const mediaBlocks = getMediaBlocks(blocks);
    let indexFeatureImage: number | undefined;
    if (mediaBlocks?.length) {
      indexFeatureImage = mediaBlocks.indexOf(mediaBlocks[0]);
    }

    const content: JSX.Element[] = [];
    for (let i = 0; i < blocks.length; i++) {
      if (indexFeatureImage != null && i === indexFeatureImage) {
        continue;
      }
      const b = blocks[i];
      const blockStyle = this.getBlockStyle(activeLink, b);

      const el = block2React(activeLink, b, blockStyle, "block" + i);
      if (el) {
        content.push(el);
        content.push(
          <View key={"break" + i} style={{ margin: styles.margin }} />
        );
      }
    }
    return content;
  }

  getBlockStyle(activeLink: Link, b: ContentBlock) {
    const blockStyle: any = {};
    if (b?.blockType === "image" || b?.blockType === "video") {
      blockStyle.maxHeight = styles.drawHeight / 2;
      blockStyle.maxWidth = "100%";
    } else if (b?.blockType === "heading" && b.settings.size === "small") {
      blockStyle.fontSize = styles.cardHeading?.fontSize - 5;
    } else if (b?.blockType === "heading" && b.settings.size === "large") {
      blockStyle.fontSize = styles.cardHeading?.fontSize + 5;
    }
    return blockStyle;
  }

  onEditLink() {
    const { history, activePost, activeLink, match } = this.props;

    const location = match?.path;
    if (
      location?.startsWith("/postedit") ||
      location?.startsWith("/linkedit")
    ) {
      return;
    }

    if (activePost?.id) {
      history.push(`/postedit/${activePost.id}`);
    } else if (activeLink?.id) {
      history.push(`/linkedit/${activePost.id}`);
    }
  }

  getEditButton() {
    const { activePost, user } = this.props;
    if (!activePost) {
      return null;
    }

    const userId = AuthUtils.getUserName(user);
    const boUserRights = getBOUserRightsFromBO(activePost, userId);

    if (boUserRights?.canUpdate) {
      return (
        <Button
          key={"editButton"}
          title="Edit"
          leftIcon={getIconElement(Icons.edit)}
          onClick={this.onEditLink.bind(this)}
        />
      );
    }
    return null;
  }

  render() {
    const { activeLink, containerStyle } = this.props;
    const { firstPageMode, collapsed } = this.state;

    let width = styles.drawWidth;
    //width = Math.min(width, styles.maxViewWidth);

    const height = round((600 * width) / 800, 0);
    const hideTitle = activeLink?.hideTitle;

    let articleStyleMarginTop = -70;
    if(hideTitle){
      articleStyleMarginTop = 0;
    }

    if (!activeLink) {
      return (
        <View key="No PostLink selected" style={{ width, height }}>
          <Text>Loading...</Text>
          <Spinner size={"large"} />
        </View>
      );
    }

    const containerProps = {
      style: {
        display: "block",
        position: "relative",

        bottom: 100,
        zIndex: 3,
        textAlign: "start",
        left: "5%",
        width: width,
      },
    };

    const articleStyle: any = {
      marginLeft: styles.margin,
      marginRight: styles.margin,
      marginTop: articleStyleMarginTop,
      textAlign: "start",
      width: width,
    };

    // var featureBlocks = getFeatureBlocks(activeLink);
    const featureBlocks = activeLink?.content?.blocks;
    const mediaBlocks = getMediaBlocks(featureBlocks || []);
    const size: { width?: number; height?: number; background?: string } = {
      width: styles.drawWidth,
    };

    const blockStyle = { ...size, maxWidth: styles.drawWidth };
    if (!mediaBlocks?.length) {
      // @ts-ignore
      containerProps.style.position = undefined;
      // @ts-ignore
      containerProps.style.height = containerStyle?.height || 150;

      articleStyle.margin = 10;
      articleStyle.marginLeft = undefined;
      articleStyle.marginRight = undefined;
      articleStyle.maxWidth = styles.drawWidth;
    }

    return (
      <View
        key="linkarticle"
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          width: width,
          height: firstPageMode ? styles.drawWidth * 0.75 : undefined,
          textAlign: "start",
          margin: "0px auto",
          overflowHorizontal: "hidden",
          ...containerStyle,
        }}
      >
        {/* {mediaBlocks?.length?<ImageGalleria style={blockStyle} getImages={this.getImages.bind(this)}/>:null} */}
        <View style={{ ...blockStyle }} onClick={this.onInfoBlockClick.bind(this)}>
          {mediaBlocks?.length
            ? getArticleImage(activeLink, "100%", undefined)
            : null}
          {this.getInfoBlock(activeLink, containerProps)}
        </View>
        {!firstPageMode && !collapsed ? (
          <View style={articleStyle}>
            {getAuthor(activeLink)}
            {this.getEditButton()}
            <View style={{ margin: 10 }} />
            {getTags(activeLink)}
            {this.getArticleContent(activeLink)}
            <hr />
          </View>
        ) : null}
      </View>
    );
  }
}
