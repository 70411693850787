
export const FontSize = {
  label:12
}

export const Colors = {
  label:"#a6a6a6",
  background:"white"
}

