import React, { Component } from "react";
import Icons, { getIconElement } from "../../constants/Icons";
import styles from "../../constants/Styles";
import { Post } from "../../API";
import { AuthUtils } from "../../utils/AuthUtils";
import { getBOUserRightsFromBO } from "../../utils/BOUtils";
import { Link } from "../../utils/PostInternals";
import { strEqualObj } from "../../utils/SerializationUtils";
import { Button, PickerRow, Spinner, SuperInput, Text, View } from "../ui";
import { S3Storage } from "./utils/LinkContentUtils";
import { getPublicLinkData } from "./utils/LinkGraphQLUtils";

interface LinkSaveProps {
  activePost: any;
  activeLink: Link | undefined;
  user: any;
  onChangePost: (post: Post) => void;
  onChangeLink: (link: Link) => void;
  onSavePost: (post: Post, link: Link) => void;
  onPublishLink: (link: Link) => void;
  dirty: boolean;
}

interface LinkSaveState {
  contentText: string;
  user: any;
  loading: boolean;
  isAdmin: boolean;
  publicLinkId?: string;
  publicLink: Link | undefined;
  linkValidation: string | undefined;
  linkValid: boolean;
}

export class LinkSave extends Component<LinkSaveProps, LinkSaveState> {
  refName: any;
  refDescription: any;
  refPostType: any;
  refLinkId: any;

  constructor(props) {
    super(props);

    this.refName = React.createRef();
    this.refDescription = React.createRef();
    this.refPostType = React.createRef();
    this.refLinkId = React.createRef();

    this.state = {
      contentText: "",
      user: undefined,
      loading: true,
      isAdmin: false,
      publicLinkId: undefined,
      publicLink: undefined,
      linkValidation: undefined,
      linkValid: false
    };
  }

  async componentDidMount() {
    AuthUtils.getUser().then(user => {
      const isAdmin = AuthUtils.isUserAdmin(user);
      this.setState({ user, loading: false, isAdmin });
    });
    await this.updateValidation();
  }

  validatePost() {
    const { activePost, user } = this.props;
    const userId = AuthUtils.getUserName(user);

    const boUserRights = getBOUserRightsFromBO(activePost, userId);
    if (!boUserRights.canUpdate) {
      return false;
    }

    if (!activePost?.name?.length) {
      return false;
    }
    return true;
  }

  validateLink(state?) {
    const { activeLink, user } = this.props;
    const { publicLinkId, publicLink } = state || this.state;

    if (!AuthUtils.isUserAdmin(user)) {
      return false;
    }
    if (activeLink?.id?.length) {
      this.setState({
        linkValidation: "Link id cannot be empty",
        linkValid: false
      });
    }

    if (activeLink?.id !== publicLinkId) {
      this.setState({
        linkValidation: "Not compared to current link id",
        linkValid: false
      });
      return false;
    }
    if (strEqualObj(publicLink, activeLink)) {
      this.setState({
        linkValidation: "Published link is same as current link",
        linkValid: false
      });
      return false;
    }
    this.setState({
      linkValidation: `Link checked ${new Date(Date.now()).toLocaleTimeString() }`,
      linkValid: true
    });
  }

  async updateValidation() {
    const { activeLink } = this.props;
    if (activeLink?.id?.length) {
      const publicLinkId = activeLink?.id;
      const publicLink = await getPublicLinkData(activeLink?.id);
      this.validateLink({...this.state, publicLink, publicLinkId })
      this.setState({ publicLink, publicLinkId });
    }
  }

  getPublishPart() {
    const { isAdmin, linkValid, linkValidation } = this.state;
    const {
      onPublishLink,
      onChangeLink,
      activeLink,
      activePost,
      user
    } = this.props;
    const userId = AuthUtils.getUserName(user);
    const boUserRights = getBOUserRightsFromBO(activePost, userId);

    if (!isAdmin || !activeLink) {
      return null;
    }
    const controlStyle = { width: "100%",margin:styles.margin };
    const superInputContainerProps = {
      style: {
        ...controlStyle,
        marginTop: styles.margin * 2.5,
        width: undefined
      }
    };
    const superInputTextProps = { style: { ...controlStyle } };

    return (
      <View key={"publishPart"} style={{ textAlign: "start" }}>
        <h3>Publish</h3>
        <View style={{ textAlign: "end" }}>
          <SuperInput
            key={"txtLinkId"}
            ref={this.refLinkId}
            label="Link id"
            containerProps={superInputContainerProps}
            textProps={superInputTextProps}
            value={activeLink?.id}
            disabled={!boUserRights?.canUpdate}
            onChange={e => {
              onChangeLink({ ...activeLink, id: e.target.value });
            }}
          />

          <Button
            title="Validate"
            style={{ ...styles.defaultButtonStyle, marginTop: styles.margin }}
            leftIcon={getIconElement(Icons.save)}
            onClick={this.updateValidation.bind(this)}
          />
          <View>
          <a href={`${S3Storage}public/links/${activeLink.id}.json`} rel="noopener noreferrer" target="_blank\">{`${activeLink.id}.json`}</a>

          </View>
          <Text >{linkValidation}</Text>

          <Button
            title="Publish"
            style={{ ...styles.defaultButtonStyle, marginTop: styles.margin }}
            leftIcon={getIconElement(Icons.save)}
            onClick={() => onPublishLink(activeLink)}
            disabled={!linkValid}
          />
        </View>
      </View>
    );
  }

  render() {
    const {
      onSavePost,
      activePost,
      activeLink,
      user,
      onChangePost
    } = this.props;
    const userName = AuthUtils.getUserName(user);

    const { loading } = this.state;
    const boUserRights = getBOUserRightsFromBO(activePost, userName);

    if (loading) {
      return <Spinner />;
    }

    if (!activeLink || !activePost) {
      return (
        <View key={"linksave"}>
          <Text>Error, link or post not set</Text>
        </View>
      );
    }
    const controlStyle = { width: "100%",margin:styles.margin };
    const superInputContainerProps = {
      style: {
        ...controlStyle,
        marginTop: styles.margin * 2.5,
        width: undefined,

      }
    };
    const superInputTextProps = { style: { ...controlStyle } };
    const pickerProps = { style: { width:"100%", marginLeft:10 } };
    const pickerTextProps = { style: { ...controlStyle, margin:`${styles.margin}px ${styles.margin}px ${styles.margin}px ${styles.margin}px` } };

    return (
      <View key={"linksave"} style={{ textAlign: "start" }}>
        <h3>Post</h3>
        <SuperInput
          key={"txtTitle"}
          ref={this.refName}
          label="Name"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={activePost?.name}
          disabled={!boUserRights?.canUpdate}
          onChange={e => {
            onChangePost({ ...activePost, name: e.target.value });
          }}
        />

        <SuperInput
          key={"txtExcerpt"}
          ref={this.refDescription}
          label="Description"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={activePost?.description}
          disabled={!boUserRights?.canUpdate}
          onChange={e => {
            onChangePost({ ...activePost, description: e.target.value });
          }}
        />

        <PickerRow
          key={"txtPostType"}
          label="Type"
          containerProps={superInputContainerProps}
          textProps={pickerTextProps}
          pickerProps={pickerProps}
          items={[{name:"article"},{name:"firstpage"}]}
          value={{name:activePost?.postType || activeLink?.linkType}}
          disabled={!boUserRights?.canUpdate}
          onChange={val => {
            onChangePost({ ...activePost, postType: val?.name });
          }}
        />


        <View style={{ textAlign: "end" }}>
          <Button
            title="Save"
            style={{ ...styles.defaultButtonStyle, marginTop: styles.margin }}
            leftIcon={getIconElement(Icons.save)}
            onClick={() => onSavePost(activePost, activeLink)}
            disabled={!this.validatePost()}
          />
        </View>
        {this.getPublishPart()}
      </View>
    );
  }
}
