import { Component } from "react";
import styles from "../../constants/Styles";
import { LocationMatch, LocationHistory } from "../../utils/NavUtils";
import { Link } from "../../utils/PostInternals";
import { getCircularReplacer } from "../../utils/SerializationUtils";
import { Button, TextArea, View } from "../ui";
import { readLinkJSON } from "./utils/S3FileUtils";

interface LinkJSONProps {
  activeLink: Link | undefined;
  user: any;
  match: LocationMatch;
  history: LocationHistory;
}

interface LinkJSONState {
  publishedText: any;
  showLink: boolean;
}

export class LinkJSON extends Component<LinkJSONProps, LinkJSONState> {
  constructor(props) {
    super(props);

    this.state = {
      publishedText: undefined,
      showLink: false
    };
  }

  async componentDidMount() {
    const linkId = this.props.activeLink?.id;
    if (linkId) {
      const linkText = await readLinkJSON(linkId);
      if (linkText) {
        const publishedText = JSON.stringify(
          JSON.parse(linkText),
          getCircularReplacer(),
          2
        );
        this.setState({ publishedText });
      }
    }
  }

  render() {
    const { activeLink } = this.props;
    const { publishedText, showLink } = this.state;
    const jsonText = JSON.stringify(activeLink, getCircularReplacer(), 2);
    const noOfRows = jsonText?.split(/\r\n|\r|\n/)?.length;
    return (
      <View key="linkJSON" style={{display:"flex", alignItems:"center", flexDirection:"column"}}>
        <View style={{alignSelf:"end",margin:styles.margin}}>
          {publishedText?<Button
            title={`Show ${showLink ? "post" : "link"}`}
            onPress={() => this.setState({ showLink: !showLink })}
            type="clear"
          />:null}
        </View>
        {showLink ? (
          <TextArea
            key={"linkarea"}
            style={{ width: styles.drawWidth }}
            value={publishedText}
            rows={noOfRows}
            autoResize={true}
            readOnly={true}
          />
        ) : (
          <TextArea
            key={"postarea"}
            style={{ width: styles.drawWidth }}
            value={jsonText}
            rows={noOfRows}
            autoResize={true}
            readOnly={true}
          />
        )}
      </View>
    );
  }
}
