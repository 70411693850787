import { Hub } from "aws-amplify/utils";
import React,{ Component } from "react";
import Icons, {
  getIconFromProvider,
  getIconWithBadge,
} from "../../constants/Icons";
import styles from "../../constants/Styles";
import { Profile } from "../../model/models";
import AppLogger from "../../utils/AppLogger";
import { AuthUtils } from "../../utils/AuthUtils";
import { IWithDBProperties } from "../../utils/ClassUtils";
import { FileExplorer } from "../explorer/FileExplorer";
import { getProfileData } from "../links/utils/LinkGraphQLUtils";
import { Button, Spinner, SuperInput, View } from "../ui";
import { AuthPage } from "./AuthPage";


import { FileService } from "../explorer/FileService";
import { Accordion, AccordionTab } from "primereact/accordion";



export interface AccountProps {}

export interface AccountState {
  loading: boolean;
  user: any;
  customState: any;
  isAdmin: boolean;
  profile: IWithDBProperties<Profile> | undefined;
  email: string | undefined;
  activeIndex: number[];
}

export class Account extends Component<AccountProps, AccountState> {
  fileService: FileService | undefined;
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      loading: true,
      user: undefined,
      customState: null,
      isAdmin: false,
      profile: undefined,
      email: undefined,
      activeIndex: [0],
    };
  }

  componentDidMount() {
    AuthUtils.getUser().then((user) => {
      const isAdmin = AuthUtils.isUserAdmin(user);
      this.fileService = new FileService(user);
      this.setState({ user, loading: false, isAdmin });

      if (user) {
        this.initProfileData(user);
      } else {
        this.setState({ profile: undefined });
      }
    });

    Hub.listen("auth", ({ payload: { event, message } }) => {
      switch (event) {
        case "signedIn":
          this.initProfileData(message);
          this.fileService = new FileService(message);
          break;
        case "signedOut":
          this.setState({ user: null });
          break;
        case "customOAuthState":
          this.setState({ customState: message });
      }
    });
  }

  initProfileData(user) {
    getProfileData(user).then(async (profile) => {
      let email: string | undefined = undefined;
      if (profile) {
        email = profile.email || undefined;
      }

      if (!email?.length) {
        email = await AuthUtils.getUserEmail(user);
      }

      this.setState({
        user,
        email,
        profile: profile as IWithDBProperties<Profile>,
      });
    });
  }

  async onLogout() {
    try {
      this.setState({ loading: true });
      await AuthUtils.signOut();
      this.setState({ user: null, isAdmin: false });
    } catch (err) {
      AppLogger.error("Error when logging out", err);
    } finally {
      this.setState({ loading: false });
    }
  }

  toggleIndex(activeIndex) {
    this.setState({ activeIndex });
  }

  render() {
    const { user, loading, isAdmin, email, activeIndex } = this.state;
    const provider = AuthUtils.getProviderName(user);
    const providerIcon = getIconFromProvider(provider);
    const oauthError = AuthUtils.oauthError;

    if (loading) {
      return <Spinner />;
    }

    const emailString = email;
    AppLogger.debug(`Email string ${emailString}`);
    let authContent: any[] = [];
    if (!user) {
      authContent.push(
        <AuthPage key="authpage"
          hostUISignIn={AuthUtils.federatedSignIn}
          googleSignIn={AuthUtils.googleWebSignIn}
          appleSignIn={AuthUtils.appleWebSignIn}
          oAuthUser={user}
          oAuthError={oauthError}
        />
      );
    } else {
      authContent.push(
        <View key="emailaddress">
          {emailString ? (
            <SuperInput
              key="emailstring"
              title="Email"
              value={emailString}
              disabled={true}
              leftIcon={providerIcon}
            />
          ) : (
            <SuperInput
              key="emailhidden"
              title="Email"
              value={'Your "email" is hidden'}
              disabled={true}
              leftIcon={providerIcon}
            />
          )}
          <View style={{ width: styles.drawWidth, margin: styles.margin }}>
            <Button
              style={{}}
              onPress={this.onLogout.bind(this)}
              title={"Sign out"}
            />
          </View>
        </View>
      );
    }

    let accordionTabs: any[] = [];
    // 1. Sign in or user information
    accordionTabs.push( // @ts-ignore
      <AccordionTab
        key="authTab"
        header={
          <React.Fragment>
            {" "}
            <span>{getIconWithBadge(Icons.profiles)}</span>
            <span>User</span>{" "}
          </React.Fragment>
        }
      >
        {user ? (
          <h2 style={{ marginTop: 10 }}>{`${
            isAdmin ? "Admin " : ""
          }Account`}</h2>
        ) : (
          <h2>{`Sign in`}</h2>
        )}
        {authContent}
      </AccordionTab>
    );

    // 2. File part
    if (user && this.fileService) {

      accordionTabs.push(// @ts-ignore
        <AccordionTab
        key="fileTab"
          header={
            <React.Fragment>
              {" "}
              <span>{getIconWithBadge(Icons.files)}</span>
              <span>File explorer</span>{" "}
            </React.Fragment>
          }
        >
          <FileExplorer fileService={this.fileService} />
        </AccordionTab>
      );
    }

    return (
      <View
        key="Account"
        style={{
          position: "relative",
          width: styles.fullWidth,
          justifyContent: "center",
          margin: "0px auto",
          textAlign: "center",
          padding: 10,
        }}
      >
        <h1>Account</h1> {/*@ts-ignore */}
        <Accordion
          multiple
          activeIndex={activeIndex}
          onTabChange={(e) => this.toggleIndex(e.index)}
        >
          {accordionTabs}
        </Accordion>
      </View>
    );
  }
}
