import {
  mdiAccount,
  mdiAccountCircle,
  mdiApple,
  mdiClose,
  mdiContentSave,
  mdiEmail,
  mdiFileDocumentMultipleOutline,
  mdiFileQuestionOutline,
  mdiFormTextboxPassword,
  mdiGoogle,
  mdiHelp,
  mdiHome,
  mdiInformation,
  mdiLogin,
  mdiLogout,
  mdiPencil,
  mdiPlus,
  mdiTrashCanOutline,
  mdiVectorLink,
  mdiWeb,
} from "@mdi/js";
import Icon from "@mdi/react";
// import { Platform, View } from 'react-native';
// import { Badge, BadgeProps, Icon } from './UIControls';
// import { styles } from './Styles';
// import images from './Images';
import { Badge } from "primereact/badge";
import React from "react";
import { View } from "../components/ui";

const iconNames = {
  about: "information",
  abg: "screen-rotation",
  devicemotion: "motion-sensor",
  account: "account-circle",
  add: "plus-circle",
  addFeature: "shape-polygon-plus",
  alien: "alien",
  android: "android",
  apple: "apple",
  audio: "microphone-variant",
  autoStart: "play-speed",
  avatar: "face-man-profile",
  archive: "archive-arrow-down",
  archiveRestore: "archive-arrow-up",
  archiveDelete: "trash-can-outline",
  baby: "baby-face-outline",
  back: "arrow-left",
  barn: "barn",
  barrel: "barrel",
  basketball: "basketball-hoop-outline",
  battery: "battery-unknown",
  bicycle: "bicycle",
  biohazard: "biohazard",
  blank: "blank",
  bridge: "bridge",
  boolean: "checkbox-intermediate",
  bus: "bus",
  cache: "cached",
  cachedItem: "file-check-outline",
  camera: "camera-outline",
  camp: "campfire",
  cancel: "cancel",
  car: "car",
  cat: "cat",
  category: "alpha-c-box-outline",
  changePassword: "key-change",
  chartBar: "chart-bar",
  check: "check-circle-outline",
  chevron: "chevron-right",
  chevronleft: "chevron-left",
  cirlce: "checkbox-blank-circle-outline",
  close: "close",
  collapseup: "chevron-up",
  color: "palette-outline",
  commands: "speaker-wireless",
  config: "cog-outline",
  confirm: "check",
  cow: "cow",
  custom: "code-greater-than-or-equal",
  description: "alpha-d-box-outline",
  deviceactivty: "lightning-bolt-outline",
  deviceinputs: "video-vintage",
  devices: "devices",
  devicetriggers: "chevron-triple-right",
  direction: "arrow-top-right-bold-outline",
  disabled: "minus-circle-outline",
  notactive: "checkbox-blank-off-outline",
  dog: "dog",
  dogs: "dog-side",
  door: "door",
  draw: "draw",
  edit: "pencil",
  editor: "account-edit-outline",
  email: "email",
  error: "alert-circle",
  expanddown: "chevron-down",
  fail: "checkbox-blank-off-outline",
  familys: "account-group-outline",
  familyusers: "account-box",
  featureList: "format-list-bulleted-type",
  featureSubTypes: "map-marker-question-outline",
  file: "file-outline",

  files: "file-multiple-outline",
  filter: "filter-outline",
  forgotPassword: "account-question",
  google: "google",
  help: "help",
  hide: "eye-off",
  hidefilter: "filter-minus-outline",
  home: "home",
  horse: "horseshoe",
  huntarea_border: "border-style",
  image: "image-outline",
  import: "import",
  info: "information",
  joinSession: "human-greeting",
  joinFamily: "account-plus",
  license: "license",
  link: "vector-link",
  list: "view-list",
  location: "directions",
  log: "developer-board",
  signIn: "login",
  signOut: "logout",
  mapareaborder: "border-style",
  maps: "map",
  mapType: "satellite",
  mapViewSettings: "layers",
  marker: "map-marker",
  measure: "ruler-square",
  member: "account-outline",
  microphone: "microphone-outline",
  movie: "filmstrip-box",
  muted: "volume-mute",
  name: "alpha-n-box-outline",
  network: "cellphone-nfc",
  networkWifi: "wifi",
  networkCell: "signal-cellular-1",
  notmember: "account-off-outline",
  other: "comment-question",
  owner: "account-circle-outline",
  parking: "parking",
  pass: "tower-beach",
  password: "form-textbox-password",
  pause: "pause",
  pendingActions: "file-check-outline",
  percentage: "percent",
  play: "play",
  polygon: "vector-polygon",
  polyline: "vector-polyline",
  profiles: "card-account-details",
  props: "format-list-text",
  readonly: "pencil-lock-outline",
  record: "record",
  rectangle: "rectangle-outline",
  reset: "rotate-left",
  resetCenter: "image-filter-center-focus-weak",
  reviews: "head-lightbulb-outline",
  road: "road",
  salt_lick: "cube",
  save: "content-save",
  selectSearch: "select-search",
  sensors: "compass",
  sessions: "av-timer",
  sessionsConfig: "forwardburger",
  setCenter: "set-center",
  settings: "cog",
  show: "eye",
  showfilter: "filter-plus-outline",
  sign: "sign-direction",
  signup: "account-plus-outline",
  speedometer: "speedometer",
  start: "play",
  startMeasure: "ray-start",
  state: "playlist-play",
  staticValue: "alpha-s-box-outline",
  stop: "stop",
  stopMeasure: "ray-end",
  subTrigger: "hand-pointing-right",
  submit: "arrow-right-bold-circle-outline",
  success: "check-box-outline",
  test: "ab-testing",
  thumbDown: "thumb-down-outline",
  thumbUp: "thumb-up-outline",
  timer: "timer-outline",
  training: "select-compare",
  trash: "trash-can-outline",
  unknownmap: "file-question-outline",
  unknownuser: "face-man-profile",
  unmuted: "volume-low",
  unreviewed: "checkbox-blank-outline",
  uniqueid: "alpha-u-box-outline",
  userCode: "identifier",
  numeric: "numeric",
  value: "alpha-v-box-outline",
  valueType: "alpha-t-box-outline",
  warning: "alert",
  web: "web",
  verify: "eye-check-outline",
  xyz: "axis-arrow",
  zoomIn: "magnify-plus",
  zoomOut: "magnify-minus",
};

export default iconNames;

export interface BadgeProps {
  value: string | number;
}

function getIconPath(name) {
  switch (name) {
    case iconNames.about:
      return mdiInformation;
    case iconNames.account:
      return mdiAccountCircle;
    case iconNames.add:
      return mdiPlus;
    case iconNames.close:
      return mdiClose;
    case iconNames.edit:
      return mdiPencil;

    case iconNames.home:
      return mdiHome;
    case iconNames.link:
      return mdiVectorLink;
    case iconNames.save:
      return mdiContentSave;

    case iconNames.signIn:
      return mdiLogin;
    case iconNames.signOut:
      return mdiLogout;
    case iconNames.web:
      return mdiWeb;

    case iconNames.help:
      return mdiHelp;
    case iconNames.apple:
      return mdiApple;
    case iconNames.google:
      return mdiGoogle;
    case iconNames.email:
      return mdiEmail;
    case iconNames.password:
      return mdiFormTextboxPassword;
    case iconNames.profiles:
      return mdiAccount;
    case iconNames.files:
      return mdiFileDocumentMultipleOutline;
    case iconNames.trash:
      return mdiTrashCanOutline;

    default:
      console.log(`Missing icon ${name}`);
      return mdiFileQuestionOutline;
  }
}

export function getIconElement(name, iconStyle?: any, iconSize?) {
  if (!iconSize) {
    iconSize = 0.75;
  }

  return <Icon path={getIconPath(name)} style={iconStyle} size={iconSize} />;
}

export function getIconWithBadge(
  name: string,
  iconStyle?: any,
  badgeProps?: BadgeProps,
  iconSize?: number,
  key?: string
) {
  if (!iconSize) {
    iconSize = 0.75;
  }

  if (badgeProps?.value) {
    return (
      <View style={{ width: undefined }}>
        <Icon path={getIconPath(name)} style={iconStyle} size={iconSize} />
        <Badge
          key="mybadge"
          style={{ position: "relative", right: 3, bottom: -5 }}
          value={badgeProps.value}
        />
      </View>
    );
  }

  return (
    // @ts-ignore
    <Icon path={getIconPath(name)} style={iconStyle} size={iconSize}>
      {badgeProps?.value ? (
        <Badge
          key="mybadge"
          style={{ position: "relative", right: 1 }}
          value={badgeProps.value}
        />
      ) : null}
    </Icon>
  );
}

export function getIconFromProvider(provider: string | undefined) {
  let iconName = iconNames.email;
  if (provider === "Google") {
    iconName = iconNames.google;
  } else if (provider === "SignInWithApple") {
    iconName = iconNames.apple;
  }
  return getIconElement(iconName);
}

// export function getDisabledEnabledStyle(disabled: boolean) {
//   if (disabled) {
//     return styles.disabledIcon;
//   } else {
//     return {};
//   }
// }

// export function getIconTypeFromName(name: string): string | undefined {
//   return 'material-community'
//   //}

//   return undefined;

// }

// export function getCharacterImage(char: string): any {
//   var lc = char?.toLowerCase();
//   if (!lc.match(/[a-z]/)) {
//     return getIconImage(iconNames.unknownmap)
//   } else {
//     return images[lc]
//   }

// }

// export function getIconImage(name: string): any {

//   switch (name) {
//     case iconNames.about: return images.about
//     case iconNames.devicemotion: return images.devicemotion
//     case iconNames.account: return images.account
//     case iconNames.add: return images.add
//     case iconNames.addFeature: return images.addFeature
//     case iconNames.alien: return images.alien
//     case iconNames.audio: return images.audio
//     case iconNames.avatar: return images.avatar
//     case iconNames.baby: return images.baby
//     case iconNames.barn: return images.barn
//     case iconNames.barrel: return images.barrel
//     case iconNames.basketball: return images.basketball
//     case iconNames.bicycle: return images.bicycle
//     case iconNames.biohazard: return images.biohazard
//     case iconNames.bridge: return images.bridge
//     case iconNames.bus: return images.bus
//     case iconNames.cache: return images.cache
//     case iconNames.camera: return images.camera
//     case iconNames.camp: return images.camp
//     case iconNames.cancel: return images.cancel
//     case iconNames.car: return images.car
//     case iconNames.cat: return images.cat
//     case iconNames.check: return images.check
//     case iconNames.chevron: return images.chevron
//     case iconNames.chevronleft: return images.chevronleft
//     case iconNames.cirlce: return images.cirlce
//     case iconNames.close: return images.close
//     case iconNames.collapseup: return images.collapseup
//     case iconNames.color: return images.color
//     case iconNames.commands: return images.commands
//     case iconNames.config: return images.config
//     case iconNames.confirm: return images.confirm
//     case iconNames.cow: return images.cow
//     case iconNames.deviceinputs: return images.deviceinputs
//     case iconNames.devices: return images.devices
//     case iconNames.devicetriggers: return images.devicetriggers
//     case iconNames.disabled: return images.disabled
//     case iconNames.dog: return images.dog
//     case iconNames.dogs: return images.dogs
//     case iconNames.door: return images.door
//     case iconNames.draw: return images.draw
//     case iconNames.edit: return images.edit
//     case iconNames.error: return images.error
//     case iconNames.expanddown: return images.expanddown
//     case iconNames.fail: return images.fail
//     case iconNames.familys: return images.familys
//     case iconNames.familyusers: return images.familyusers
//     case iconNames.featureSubTypes: return images.featureSubTypes
//     case iconNames.featureList: return images.featureList
//     case iconNames.filter: return images.filter
//     case iconNames.hide: return images.hide
//     case iconNames.hidefilter: return images.hidefilter
//     case iconNames.home: return images.home
//     case iconNames.horse: return images.horse
//     case iconNames.huntarea_border: return images.huntarea_border
//     case iconNames.image: return images.image
//     case iconNames.import: return images.import
//     case iconNames.info: return images.info
//     case iconNames.joinSession: return images.join
//     case iconNames.license: return images.license
//     case iconNames.location: return images.location
//     case iconNames.log: return images.log
//     case iconNames.signOut: return images.signOut
//     case iconNames.maps: return images.maps
//     case iconNames.mapareaborder: return images.mapareaborder
//     case iconNames.mapType: return images.mapType
//     case iconNames.mapViewSettings: return images.mapViewSettings
//     case iconNames.marker: return images.marker
//     case iconNames.movie: return images.movie
//     case iconNames.other: return images.other
//     case iconNames.parking: return images.parking
//     case iconNames.pass: return images.pass
//     case iconNames.pause: return images.pause
//     case iconNames.play: return images.play
//     case iconNames.polygon: return images.polygon
//     case iconNames.polyline: return images.polyline
//     case iconNames.profiles: return images.profiles
//     case iconNames.props: return images.props
//     case iconNames.record: return images.record
//     case iconNames.rectangle: return images.rectangle
//     case iconNames.reset: return images.reset
//     case iconNames.resetCenter: return images.resetCenter
//     case iconNames.road: return images.road
//     case iconNames.salt_lick: return images.salt_lick
//     case iconNames.save: return images.save
//     case iconNames.sensors: return images.sensors
//     case iconNames.sessions: return images.sessions
//     case iconNames.setCenter: return images.setCenter
//     case iconNames.settings: return images.settings
//     case iconNames.show: return images.show
//     case iconNames.showfilter: return images.showfilter
//     case iconNames.sign: return images.sign
//     case iconNames.state: return images.state
//     case iconNames.start: return images.start
//     case iconNames.stop: return images.stop
//     case iconNames.subTrigger: return images.subTrigger
//     case iconNames.success: return images.success
//     case iconNames.training: return images.training
//     case iconNames.trash: return images.trash
//     case iconNames.unknownmap: return images.unknownmap
//     case iconNames.unknownuser: return images.unknownuser
//     case iconNames.unreviewed: return images.unreviewed
//     case iconNames.warning: return images.warning
//     case iconNames.zoomIn: return images.zoomIn
//     case iconNames.zoomOut: return images.zoomOut
//     default:
//       return images.default

//   }

// }
