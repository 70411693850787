/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listArchivedSessions = /* GraphQL */ `query ListArchivedSessions(
  $filter: ArchiveSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listArchivedSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    id
    archived
    key
    path
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArchivedSessionsQueryVariables,
  APITypes.ListArchivedSessionsQuery
>;
export const listArchivedInputs = /* GraphQL */ `query ListArchivedInputs(
  $filter: ArchiveInputFilterInput
  $limit: Int
  $nextToken: String
) {
  listArchivedInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    id
    name
    description
    deviceID
    userID
    sessionID
    triggerID
    triggerTemplateID
    commandID
    dogID
    members
    editors
    startedAt
    stoppedAt
    internals
    dataType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArchivedInputsQueryVariables,
  APITypes.ListArchivedInputsQuery
>;
export const isValidCode = /* GraphQL */ `query IsValidCode($input: ValidCodeInput!) {
  isValidCode(input: $input) {
    id
    code
    codeType
    isValid
    suggestions
    reason
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IsValidCodeQueryVariables,
  APITypes.IsValidCodeQuery
>;
export const listPublicLinks = /* GraphQL */ `query ListPublicLinks(
  $filter: PublicLinkInput
  $limit: Int
  $nextToken: String
) {
  listPublicLinks(filter: $filter, limit: $limit, nextToken: $nextToken)
}
` as GeneratedQuery<
  APITypes.ListPublicLinksQueryVariables,
  APITypes.ListPublicLinksQuery
>;
export const getPublicLink = /* GraphQL */ `query GetPublicLink($id: String!) {
  getPublicLink(id: $id)
}
` as GeneratedQuery<
  APITypes.GetPublicLinkQueryVariables,
  APITypes.GetPublicLinkQuery
>;
export const getLoggedInEmail = /* GraphQL */ `query GetLoggedInEmail {
  getLoggedInEmail
}
` as GeneratedQuery<
  APITypes.GetLoggedInEmailQueryVariables,
  APITypes.GetLoggedInEmailQuery
>;
export const getFamily = /* GraphQL */ `query GetFamily($id: ID!) {
  getFamily(id: $id) {
    id
    name
    description
    owner
    internals
    members
    editors
    editorCode
    memberCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFamilyQueryVariables, APITypes.GetFamilyQuery>;
export const listFamilys = /* GraphQL */ `query ListFamilys(
  $filter: ModelFamilyFilterInput
  $limit: Int
  $nextToken: String
) {
  listFamilys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      owner
      internals
      members
      editors
      editorCode
      memberCode
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFamilysQueryVariables,
  APITypes.ListFamilysQuery
>;
export const getFamilyUser = /* GraphQL */ `query GetFamilyUser($id: ID!) {
  getFamilyUser(id: $id) {
    id
    name
    description
    owner
    internals
    members
    editors
    familyUserFamilyId
    memberUpdates
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFamilyUserQueryVariables,
  APITypes.GetFamilyUserQuery
>;
export const listFamilyUsers = /* GraphQL */ `query ListFamilyUsers(
  $filter: ModelFamilyUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listFamilyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      owner
      internals
      members
      editors
      familyUserFamilyId
      memberUpdates
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFamilyUsersQueryVariables,
  APITypes.ListFamilyUsersQuery
>;
export const getProfile = /* GraphQL */ `query GetProfile($id: ID!) {
  getProfile(id: $id) {
    id
    name
    description
    owner
    email
    userCode
    sub
    internals
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQueryVariables,
  APITypes.GetProfileQuery
>;
export const listProfiles = /* GraphQL */ `query ListProfiles(
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      owner
      email
      userCode
      sub
      internals
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProfilesQueryVariables,
  APITypes.ListProfilesQuery
>;
export const getUserAccount = /* GraphQL */ `query GetUserAccount($id: ID!) {
  getUserAccount(id: $id) {
    id
    members
    purchases
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAccountQueryVariables,
  APITypes.GetUserAccountQuery
>;
export const listUserAccounts = /* GraphQL */ `query ListUserAccounts(
  $filter: ModelUserAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      members
      purchases
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserAccountsQueryVariables,
  APITypes.ListUserAccountsQuery
>;
export const getSession = /* GraphQL */ `query GetSession($id: ID!) {
  getSession(id: $id) {
    id
    name
    description
    startedAt
    stoppedAt
    members
    editors
    internals
    sessionFamilyID
    mapID
    archive
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSessionQueryVariables,
  APITypes.GetSessionQuery
>;
export const listSessions = /* GraphQL */ `query ListSessions(
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      startedAt
      stoppedAt
      members
      editors
      internals
      sessionFamilyID
      mapID
      archive
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSessionsQueryVariables,
  APITypes.ListSessionsQuery
>;
export const getDeviceTrigger = /* GraphQL */ `query GetDeviceTrigger($id: ID!) {
  getDeviceTrigger(id: $id) {
    id
    name
    description
    deviceID
    sessionID
    commandID
    dogID
    members
    editors
    internals
    templateID
    dataType
    triggerState
    triggeringDevice
    startedAt
    pausedAt
    stoppedAt
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceTriggerQueryVariables,
  APITypes.GetDeviceTriggerQuery
>;
export const listDeviceTriggers = /* GraphQL */ `query ListDeviceTriggers(
  $filter: ModelDeviceTriggerFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeviceTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deviceID
      sessionID
      commandID
      dogID
      members
      editors
      internals
      templateID
      dataType
      triggerState
      triggeringDevice
      startedAt
      pausedAt
      stoppedAt
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceTriggersQueryVariables,
  APITypes.ListDeviceTriggersQuery
>;
export const getDeviceInput = /* GraphQL */ `query GetDeviceInput($id: ID!) {
  getDeviceInput(id: $id) {
    id
    name
    description
    deviceID
    userID
    sessionID
    triggerID
    triggerTemplateID
    commandID
    dogID
    members
    editors
    startedAt
    stoppedAt
    internals
    dataType
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceInputQueryVariables,
  APITypes.GetDeviceInputQuery
>;
export const listDeviceInputs = /* GraphQL */ `query ListDeviceInputs(
  $filter: ModelDeviceInputFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeviceInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deviceID
      userID
      sessionID
      triggerID
      triggerTemplateID
      commandID
      dogID
      members
      editors
      startedAt
      stoppedAt
      internals
      dataType
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceInputsQueryVariables,
  APITypes.ListDeviceInputsQuery
>;
export const getDog = /* GraphQL */ `query GetDog($id: ID!) {
  getDog(id: $id) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDogQueryVariables, APITypes.GetDogQuery>;
export const listDogs = /* GraphQL */ `query ListDogs($filter: ModelDogFilterInput, $limit: Int, $nextToken: String) {
  listDogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      internals
      members
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDogsQueryVariables, APITypes.ListDogsQuery>;
export const getMap = /* GraphQL */ `query GetMap($id: ID!) {
  getMap(id: $id) {
    id
    name
    description
    internals
    members
    editors
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMapQueryVariables, APITypes.GetMapQuery>;
export const listMaps = /* GraphQL */ `query ListMaps($filter: ModelMapFilterInput, $limit: Int, $nextToken: String) {
  listMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      internals
      members
      editors
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMapsQueryVariables, APITypes.ListMapsQuery>;
export const getCommand = /* GraphQL */ `query GetCommand($id: ID!) {
  getCommand(id: $id) {
    id
    name
    description
    internals
    members
    editors
    dogID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommandQueryVariables,
  APITypes.GetCommandQuery
>;
export const listCommands = /* GraphQL */ `query ListCommands(
  $filter: ModelCommandFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      internals
      members
      editors
      dogID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommandsQueryVariables,
  APITypes.ListCommandsQuery
>;
export const getDevice = /* GraphQL */ `query GetDevice($id: ID!) {
  getDevice(id: $id) {
    id
    name
    description
    uniqueId
    deviceType
    autoJoinSessions
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDeviceQueryVariables, APITypes.GetDeviceQuery>;
export const listDevices = /* GraphQL */ `query ListDevices(
  $filter: ModelDeviceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      uniqueId
      deviceType
      autoJoinSessions
      members
      editors
      internals
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDevicesQueryVariables,
  APITypes.ListDevicesQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    name
    description
    members
    editors
    internals
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      members
      editors
      internals
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const getPost = /* GraphQL */ `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    name
    description
    owner
    postType
    members
    editors
    internals
    issues
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPostQueryVariables, APITypes.GetPostQuery>;
export const listPosts = /* GraphQL */ `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      owner
      postType
      members
      editors
      internals
      issues
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPostsQueryVariables, APITypes.ListPostsQuery>;
export const getIssue = /* GraphQL */ `query GetIssue($id: ID!) {
  getIssue(id: $id) {
    id
    name
    description
    issueType
    members
    editors
    internals
    posts
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetIssueQueryVariables, APITypes.GetIssueQuery>;
export const listIssues = /* GraphQL */ `query ListIssues(
  $filter: ModelIssueFilterInput
  $limit: Int
  $nextToken: String
) {
  listIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      issueType
      members
      editors
      internals
      posts
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIssuesQueryVariables,
  APITypes.ListIssuesQuery
>;
export const profileBySub = /* GraphQL */ `query ProfileBySub(
  $sub: String
  $sortDirection: ModelSortDirection
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  profileBySub(
    sub: $sub
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      owner
      email
      userCode
      sub
      internals
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProfileBySubQueryVariables,
  APITypes.ProfileBySubQuery
>;
export const profileByOwner = /* GraphQL */ `query ProfileByOwner(
  $owner: String
  $sortDirection: ModelSortDirection
  $filter: ModelProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  profileByOwner(
    owner: $owner
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      owner
      email
      userCode
      sub
      internals
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProfileByOwnerQueryVariables,
  APITypes.ProfileByOwnerQuery
>;
