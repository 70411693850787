export class LocationMatch {
  public get path() {
    return window.location.pathname;
  }

  public get id(){
    const splits = window.location.pathname?.split("?");
    const pathAndNoArgs = splits?.length ?splits[0]:undefined;
    const segments = pathAndNoArgs?.split("/")
    if(segments && segments?.length > 2){ // starts with /
      return segments?.slice(-1)[0]
    }
    return undefined;
  }

}

export class LocationHistory{
  constructor(private navigate){

  }

  public push(newPath:string){
    // window.history.pushState({},"",newPath)
    this.navigate(newPath);
  }

  public replace(newPath:string){
    return this.navigate(newPath, {replace:true})
  }


}
