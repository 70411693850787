export function getExtensionFromContentType(
  contentType: string | undefined,
  defaultContentType: string,
  defaultExtension: string
) {
  contentType = contentType || defaultContentType;
  let extension = defaultExtension;
  const slashIndex = contentType.indexOf("/");
  if (slashIndex > 0 && slashIndex < contentType.length - 1) {
    extension = contentType.substring(slashIndex + 1);
  }
  extension = extension?.replace(/[^a-z0-9]/gi, "");
  return extension;
}

export function getContentTypeAndExtensionFromUri(
  uri: string,
  defaultContentType: string,
  defaultExtension: string
) {
  let extension = defaultExtension;
  let contentType = defaultContentType;

  if (!uri?.length) {
    return { contentType, extension };
  }

  const dataIndex = uri.indexOf("data:");
  const semiIndex = uri.indexOf(";");
  const fileIndex = uri.indexOf("file://");
  // AppLogger.debug(`getContentTypeAndExtensionFromUri ${uri.substring(0,200)}`)

  if (fileIndex >= 0 && fileIndex < 10) {
    const dotIndex = uri.lastIndexOf(".");
    if (dotIndex > 0) {
      extension = uri.substring(dotIndex + 1);
    }
    if (extension === "json") {
      contentType = "application/json";
    } else if (extension === "m4a") {
      contentType = "audio";
    } else if(extension === "png" || extension === "jpg" || extension === "jpeg" ){
      contentType = "image"
    } else {
      contentType = "video";
    }
    return { contentType, extension };
  }
  if (dataIndex < 0 || semiIndex < dataIndex || semiIndex > dataIndex + 40) {
    return { contentType, extension };
  }

  contentType = uri.substring(dataIndex + 5, semiIndex);
  extension = getExtensionFromContentType(
    contentType,
    defaultContentType,
    defaultExtension
  );

  return { contentType, extension };
}
