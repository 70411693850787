import { DistanceAndBearing, LatLonDelta } from "./garmin/PosToPoint";

export function round(n, precision: number = 2) {
  if (!n) {
    return 0;
  }
  const mult = Math.pow(10, precision);
  return Math.round((n+Number.EPSILON) * mult) / mult;
}

export function div1000(n, precision: number = 1) {
  if (!n) {
    return 0;
  }
  const mult = Math.pow(10, precision - 3);
  return Math.round(n * mult) / (mult * 1000);
}

export function round3(n) {
  return round(n, 3);
}

export function plotVal(n, zero = 100) {
  if (!n) {
    return zero;
  }
  return Math.floor(n * 100) + zero;
}

export function toInt(s: string) {
  return parseInt(s, 10);
}

export function isInteger(s: string | undefined) {
  if (s == null) {
    return false;
  }
  return Number.isInteger(toInt(s));
}

export function haversine(
  p1: number[],
  p2: number[]
): DistanceAndBearing {
  let distance = null;
  let bearing:number|null = null;
  let db = new DistanceAndBearing();
  if (p1.length !== 2 || p2.length !== 2) {
    db.distance = distance;
    db.bearing = bearing;
    return db;
  }

  let lat1 = p1[1];
  let lat2 = p2[1];
  let lon1 = p1[0];
  let lon2 = p2[0];
  let latDelta = lat2 - lat1;
  let lonDelta = lon2 - lon1;

  let R = 6371000;                       // metres
  let lat1Rad = (lat1 * Math.PI) / 180;  // φ, λ in radians
  let lat2Rad = (lat2 * Math.PI) / 180;
  let lon1Rad = (lon1 * Math.PI) / 180;  // φ, λ in radians
  let lon2Rad = (lon2 * Math.PI) / 180;
  let latDeltaRad = (latDelta * Math.PI) / 180;
  let lonDeltaRad = (lonDelta * Math.PI) / 180;

  // var a = Math.sin(latDeltaRad / 2) * Math.sin(latDeltaRad / 2) +
  //         Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDeltaRad / 2) *
  //             Math.sin(lonDeltaRad / 2);
  // var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // distance = R * c;  // in metres

  // Simplified distance
  let lonDist = lonDeltaRad*R;
  let latDist = Math.cos((lat1Rad+lat2Rad)/2)*R*latDeltaRad;
  let simpleDistance = Math.sqrt((lonDist*lonDist)+(latDist*latDist));



  let y = Math.sin(lon2Rad - lon1Rad) * Math.cos(lat2Rad);
  let x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
          Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lon2Rad - lon1Rad);
  let bearingRad = Math.atan2(y, x);

  bearing =
      Math.round((bearingRad * 180) / Math.PI + 360);  // in degrees
  bearing = bearing % 360;

  db.distance = simpleDistance;
  db.bearing = bearing;
  return db;
}

export function toRadians(deg:number){
  return deg*Math.PI/180;
}

export function toDegrees(rad:number){
  return rad*180/Math.PI;
}


export function distanceToLonLatDelta(
  p1: number[],
  distance: number
): LatLonDelta {
  let lat1 = p1[1];
  let lat1Rad = toRadians(lat1);
//    var lon1Rad = (lon1 * Math.PI) / 180;


  let R = 6371000;  // metres

  // Half lap = PI rad
  // Half lap distance = Pi * R meters
  // lat delta = PI*distance/PI*R = distance/R
  let latDeltaRad = distance/R;


  // Radius at lat
  let rLat = Math.cos(lat1Rad)*R;
  let lonDeltaRad = distance/rLat;



  let minDistance  = 20.0;
  let minLatDeltaRad = minDistance / R; // 20 m
  let minLonDeltaRad = minDistance / rLat; // 20 m


  let llDelta:LatLonDelta = {latDelta:0.0005, lonDelta:0.0005};
  // Checking min lat delta

  if(latDeltaRad < minLatDeltaRad){
    llDelta.latDelta = toDegrees(minLatDeltaRad);
  } else {
    llDelta.latDelta = toDegrees(latDeltaRad);
  }

  // Checking min lon delta
  if(lonDeltaRad < minLonDeltaRad){
    llDelta.lonDelta = toDegrees(minLonDeltaRad);
  } else {
    llDelta.lonDelta = toDegrees(lonDeltaRad);
  }

  return llDelta;
}


