import awsconfig from "../../../amplifyconfiguration.json";
import { Post, Profile } from "../../../API";
import { IWithDBProperties } from "../../../utils/ClassUtils";
import {
  AssetType,
  Author,
  BlockSettings,
  BlockType,
  ContentBlock,
  ImageAsset,
  Link,
  VideoAsset
} from "../../../utils/PostInternals";
import { strEqualObj } from "../../../utils/SerializationUtils";
import { getContentTypeAndExtensionFromUri } from "../../../utils/StorageUtils";
import StringUtils from "../../../utils/StringUtils";
import { ImageInfoItem } from "./LinkReactUtils";
import { storeItem } from "./S3FileUtils";

export const S3Storage =
  "https://" +
  awsconfig.aws_user_files_s3_bucket +
  ".s3." +
  awsconfig.aws_user_files_s3_bucket_region +
  ".amazonaws.com/";

export function getFeatureBlocks(
  link: Link | undefined
): ContentBlock[] | undefined {
  return link?.content?.blocks?.filter(x => x.settings?.partOfCard);
}

export function getMediaBlocks(
  blocks: ContentBlock[]
): ContentBlock[] | undefined {
  const mediaBlockTypes: BlockType[] = ["image", "video"];
  return blocks?.filter(x => mediaBlockTypes.indexOf(x.blockType) >= 0);
}

export function getMediaType(block: ContentBlock) {
  if ((block?.asset as VideoAsset | ImageAsset)?.mediaType) {
    return (block?.asset as VideoAsset | ImageAsset)?.mediaType;
  }

  return undefined;
}

export function getAssetUrl(block: ContentBlock) {
  const assetKey = block?.asset?.key;
  const assetUrl = block?.asset?.url;

  if (!assetKey && !assetUrl) {
    return undefined;
  }
  if (assetKey) {
    return getUri(assetKey);
  }
  return assetUrl;
}

export function getAssetAlt(block: ContentBlock) {
  const imageAsset = block?.asset as ImageAsset;
  return imageAsset?.alt;
}

export function getAssetTitle(block: ContentBlock) {
  const imageAsset = block?.asset as ImageAsset;
  return imageAsset?.title;
}

export function getUri(key: string): string | undefined {
  if (key == null) {
    return undefined;
  }
  let uri =  S3Storage + key;
  const charactersToReplace = [":"]
  for(let c of charactersToReplace){
    uri = uri?.substring(0,6) + uri?.substring(6)?.split(c)?.join(encodeURIComponent(c));
  }
  return uri;
}

export function generateNewBlockId(prefix: string = "") {
  const lengthPrefix = prefix?.length || 0;
  return (
    prefix +
    Math.random()
      .toString(16)
      .substr(2, 9 - lengthPrefix) +
    Math.random()
      .toString(16)
      .substr(2, 9) +
    Math.random()
      .toString(16)
      .substr(2, 6)
  );
}

export function createSlug(title: string) {
  const slug = title
    .toLowerCase()
    .replace(/[\s]/g, "-")
    .replace(/å/g, "a")
    .replace(/ä/g, "a")
    .replace(/ö/g, "o")
    .replace(/[^a-z-]/g, "");
  return slug;
}

export function createAuthor(profile: IWithDBProperties<Profile>): Author {
  const author: Author = {
    name: profile.name || "",
    shortId: StringUtils.getFolderPartId(profile.owner)
  };
  return author;
}

export function createContentBlock(
  link: Link,
  blockId: string,
  author: Author
): ContentBlock {
  const blockType: BlockType = "paragraph";
  const settings: BlockSettings = { size: "medium" };
  const id = blockId || generateNewBlockId("aaaa");
  const linkId = link.id;
  const contentType = "article";

  const b: ContentBlock = {
    contentType,
    id,
    linkId,
    blockType,
    settings,
    element: undefined,
    content: undefined,
    author,
    created: Date.now(),
    updated: Date.now()
  };

  return b;
}

export function contentBlocks2Hmtl(
  contentBlocks: ContentBlock[]
): string | null {
  if (!contentBlocks) {
    return null;
  }

  let htmlText = "";
  const blockBreak = "<p><br></p>";

  for (const b of contentBlocks) {
    let para: HTMLElement | null = null;
    let node: Text | null = null;
    if (b.blockType === "paragraph") {
      if (b.content === "<br>") {
        continue;
      }
      para = document.createElement("p");
      para.innerHTML = b.content || "";
    } else if (b.blockType === "blockquote") {
      para = document.createElement("blockquote");
      para.innerHTML = b.content || "";
    } else if (b.blockType === "heading") {
      para = document.createElement("h1");
      para.innerHTML = b.content || "";
    } else if (b.blockType === "image") {
      para = document.createElement("p");
      const img = document.createElement("img");
      img.src = b.asset?.url || "";
      img.setAttribute("width", "200px");
      img.setAttribute("height", "200px");
      para.appendChild(img);
    } else if (b.blockType === "video") {
      para = document.createElement("p");
      const iframe = document.createElement("iframe");
      iframe.src = b.asset?.url || "";
      iframe.setAttribute("class", "ql-video");
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", "true");
      iframe.setAttribute("style", "background:green");
      para.setAttribute("style", "border:solid");

      // img.setAttribute("width", "200px");
      // img.setAttribute("height", "200px");

      para.appendChild(iframe);
    } else {
      para = document.createElement("p");
      node = document.createTextNode(b.blockType);
      para.appendChild(node);
    }

    para.id = b.id;
    htmlText += para.outerHTML;
    htmlText += blockBreak;
  }
  return htmlText;
}

export function html2ContentBlocks(
  link: Link,
  htmlString: string,
  author: Author
): ContentBlock[] {
  const root = document.createElement("div");
  const contentBlocks: ContentBlock[] = [];
  root.innerHTML = htmlString;

  const oldBlocks = link.content?.blocks;

  // tslint:disable-next-line
  for (let iChild = 0; iChild < root.childNodes.length; iChild++) {
    const c: HTMLElement = root.childNodes[iChild] as HTMLElement;
    const old =
      oldBlocks?.length > contentBlocks?.length
        ? oldBlocks[contentBlocks?.length]
        : null;
    if (c.innerHTML === "<br>") {
      continue;
    }

    let b = createContentBlock(link, c.id, author);
    const localName = c.localName;
    b.content = c.innerHTML;
    if (localName === "p") {
      const fc = c.firstChild as HTMLElement;
      if (fc && fc.localName === "img") {
        b = createImageBlock(fc, link, "", c.id, author);
      } else if (fc && fc.localName === "video") {
        b = createVideoBlock(fc, link, "", c.id, author);
      }
    } else if (localName === "blockquote") {
      b.blockType = "blockquote";
    } else if (localName === "iframe") {
      b = createVideoBlock(c, link, "", c.id, author);
    } else if (localName === "h1") {
      b.blockType = "heading";
    }  else if (localName === "h2") {
      b.blockType = "heading";
      b.settings = {...b.settings ,...{size:"small"} }
    } else {
      b.content = c.outerHTML;
    }
    if (!old || !compareBlock(b, old)) {
      contentBlocks.push(b);
    } else {
      contentBlocks.push(old);
    }
  }
  return contentBlocks;
}

export function compareBlocks(
  blocks: ContentBlock[],
  oldBlocks: ContentBlock[]
) {
  if (blocks?.length !== oldBlocks?.length) {
    return false;
  } else if (!blocks?.length && !oldBlocks?.length) {
    return true;
  }

  for (let i = 0; i < blocks.length; i++) {
    if (!compareBlock(blocks[i], oldBlocks[i])) {
      return false;
    }
  }
  return true;
}

function removeStyleFromContent(comp: Partial<ContentBlock>) {
  const styleIndex = comp?.content?.indexOf('style="');
  if (!comp?.content || !styleIndex || styleIndex < 0) {
    return comp;
  }
  const endIndex = comp?.content?.slice(styleIndex + 7).indexOf('"');
  if (!endIndex || endIndex < 0) {
    return comp;
  }
  const content =
    comp.content.slice(0, styleIndex) +
    comp.content.slice(endIndex + styleIndex + 7);
  return { ...comp, content };
}

// returns true if same
export function compareBlock(b: ContentBlock, old: ContentBlock) {
  const bComp: Partial<ContentBlock> = {
    ...b,
    created: undefined,
    element: undefined,
    updated: undefined,
    id: undefined
  };
  const oldComp: Partial<ContentBlock> = {
    ...old,
    created: undefined,
    element: undefined,
    updated: undefined,
    id: undefined
  };

  return strEqualObj(
    removeStyleFromContent(bComp),
    removeStyleFromContent(oldComp)
  );
}

export function createImageBlock(
  el: HTMLElement,
  link: Link,
  caption: string,
  id,
  author: Author
): ContentBlock {
  const size = "small";
  const alignment = "right";
  const linkId = link?.id;
  const imgUrl = el.getAttribute("src") || undefined;
  id = id || generateNewBlockId();
  const contentType = "article";

  const block = {
    contentType,
    settings: {
      size,
      alignment
    },
    blockType: "image" as BlockType,
    __t: "ImageContentBlock",
    id,
    asset: {
      assetType: "image" as AssetType,
      url: imgUrl
    },
    caption,
    linkId,
    element: undefined,
    content: undefined,
    created: Date.now(),
    updated: Date.now(),

    author
  };

  return block;
}

export function createVideoBlock(
  el: HTMLElement,
  link: Link,
  caption: string,
  id,
  author: Author
): ContentBlock {
  const size = "small";
  const alignment = "right";
  const linkId = link?.id;
  const url = el.getAttribute("src") || undefined;
  id = id || generateNewBlockId();
  const contentType = "article";

  const block = {
    contentType,
    settings: {
      size,
      alignment
    },
    blockType: "video" as BlockType,
    __t: "VideoContentBlock",
    id,
    asset: {
      assetType: "video" as AssetType,
      url
    },
    caption,
    linkId,
    element: undefined,
    content: undefined,
    created: Date.now(),
    updated: Date.now(),

    author
  };

  return block;
}

function getThumbUrl(asset: ImageAsset): string | undefined {
  if (!asset?.scaledImages) {
    return asset?.url;
  }

  const url = asset.scaledImages["40x40"]?.url
  if(url){
    return url;
  }

  const key = asset.scaledImages["40x40"]?.key
  if(key){
    return getUri(key);
  }
  return  asset?.url;;
}

function cardImageUrl(asset: ImageAsset): string | undefined {
  if (!asset?.scaledImages) {
    return asset?.url;
  }

  const key = asset.scaledImages["800x600"]?.key ||
  asset.scaledImages["800x"]?.key ||
  asset.scaledImages["1000x"]?.key ;
  if(key){
    return getUri(key);
  }

  return asset?.url;

}

export function getGalleriaItemFromBlock(block: ContentBlock): ImageInfoItem {
  // tslint:disable-next-line
  let alt, itemImageSrc, thumbnailImageSrc, cardImageSrc, title;

  if (block?.asset?.url) {
    itemImageSrc = block?.asset?.url;
    thumbnailImageSrc = getThumbUrl(block?.asset);
    cardImageSrc = cardImageUrl(block?.asset);
  }

  const gi: ImageInfoItem = {
    alt,
    itemImageSrc,
    thumbnailImageSrc,
    cardImageSrc: cardImageSrc,
    title
  };

  return gi;
}

export async function uploadPostMedia(
  link: Link,
  post: Post,
  ownerSub: string,
  owner:string
) {
  if (!post?.id?.length) {
    return;
  }

  const mediaBlocks = getMediaBlocks(link?.content?.blocks);
  if (!mediaBlocks?.length) {
    return;
  }

  for (const mediaBlock of mediaBlocks) {
    if (
      mediaBlock?.asset?.url?.length &&
      !mediaBlock.asset.url.startsWith("https")
    ) {
      let key = mediaBlock?.asset?.key;

      const { extension } = getContentTypeAndExtensionFromUri(
        mediaBlock.asset.url,
        "image",
        "png"
      );
      if (!key?.length) {
        key = StringUtils.generateBOKey(ownerSub,owner, "Post", post.id, extension);
      }
      await storeItem(key, mediaBlock?.asset?.url);
      mediaBlock.asset.key = key;
      mediaBlock.asset.url = getUri(key);
    }
  }
}
