import { Button as PRButton } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Badge } from "primereact/badge";
import { Dropdown } from "primereact/dropdown";

import styles from "../../constants/Styles";
import React from "react";
import Icons, { getIconElement } from "../../constants/Icons";
import { Colors, FontSize } from "../../constants/Colors";

type UIControlType =
  | "button"
  | "spinner"
  | "view"
  | "superinput"
  | "textarea"
  | "safeareaview"
  | "text"
  | "card"
  | "image"
  | "video"
  | "switch"
  | "badge"
  | "span"
  | "pickerrow";

function mapPRProps(type: UIControlType, props: any) {
  let style = props.style;
  if (Array.isArray(style)) {
    const styleArray = style;
    style = {};
    if (styleArray?.length) {
      for (const s of styleArray) {
        style = { ...style, ...s };
      }
    }
  } else if (typeof style === "string") {
    style = JSON.parse(style);
  }

  const mappedProps = {
    ...props,
    style,
    onClick: props.onClick || props.onPress,
    onPress: undefined,
    label: props.label || props.title,
    title: undefined,
  };

  if (type === "button") {
    mappedProps.type = undefined;
  }
  if (type === "text" && mappedProps.children && !mappedProps.value) {
    mappedProps.value = mappedProps.children;
    mappedProps.children = undefined;
  }
  if (type === "superinput" && mappedProps.disabled != null) {
    mappedProps.readOnly = mappedProps.disabled;
  }

  if (type === "video" && mappedProps.controls == null) {
    mappedProps.controls = true;
  }

  return mappedProps;
}

export function Button(props: any) {
  const mappedProps = mapPRProps("button", props);

  const { label, onClick, className, style, disabled, leftIcon } = mappedProps;

  return (
    <PRButton
      style={style}
      className={className}
      label={label}
      onClick={onClick}
      icon={leftIcon}
      disabled={disabled}
    />
  );
}

export function ButtonRow(props: any) {
  const { key, textProps, buttonProps, containerProps } = props;
  //  className: (textProps?.className || '') + ' p-d-inline'

  // Space betwen
  return (
    <View
      key={key}
      style={{ ...styles.defaultRowStyle, ...containerProps?.style }}
      className="p-d-flex p-jc-between"
    >
      {Text({ ...textProps })}
      {Button({ ...buttonProps })}
    </View>
  );
}

export function Spinner(props: any) {
  const mappedProps = mapPRProps("spinner", props);
  return <ProgressSpinner style={mappedProps.style} />;
}

export function View(props: any) {
  const mappedProps = mapPRProps("view", props);
  const { style, className, onClick, children } = mappedProps;
  return (
    <div
      style={{ ...styles.defaultViewStyle, ...style }}
      className={className}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export const SuperInput = React.forwardRef((props: any, ref) => {
  const mappedProps = mapPRProps("superinput", props);
  const {
    label,
    value,
    onClick,
    onChange,
    readOnly,
    leftIcon,
    textProps,
    containerProps,
    className,
  } = mappedProps;

  const textStyle = { ...styles.defaultTextBoxStyle, ...textProps?.style };
  let containerClassName = "p-float-label";
  if (props.leftIcon) {
    containerClassName += " p-input-icon-left";
  }

  return (
    <View style={containerProps?.style}>
      <span className={containerClassName}>
        {leftIcon ? leftIcon : null}
        {/* <i className="pi pi-search" /> */}
        {!mappedProps.secureTextEntry ? (
          <InputText // @ts-ignore
            ref={ref}
            id={label}
            className={className}
            style={textStyle}
            defaultValue={value}
            onClick={onClick}
            onChange={onChange}
            readOnly={readOnly}
          />
        ) : (
          <Password // @ts-ignore
            ref={ref}
            id={label}
            className={className}
            style={textStyle}
            defaultValue={value}
            onClick={onClick}
            onChange={onChange}
            readOnly={readOnly}
            feedback={false}
            toggleMask={true}
          />
        )}
        <label htmlFor={label}>{label}</label>
      </span>
    </View>
  );
});

export function EditTextProp(props: any) {
  const ref = React.createRef();
  return <SuperInput {...props} ref={ref} />;
}

// export function EditTextProp(props: any) {
//   var mappedProps = mapPRProps("superinput", props);
//   const {
//     label,
//     value,
//     onClick,
//     onChange,
//     readOnly,
//     leftIcon,
//     textProps,
//     containerProps
//   } = mappedProps;
//   var [newVal, setNewVal] = React.useState(value);

//   var textStyle = { ...styles.defaultTextBoxStyle, ...textProps?.style };
//   var className = "p-float-label";
//   if (props.leftIcon) {
//     className += " p-input-icon-left";
//   }
//   const localOnChange = e => {
//     const val = e.target.value;
//     setNewVal(val);
//     onChange && onChange(e);
//   };

//   return (
//     <View style={containerProps?.style}>
//       <span className={className}>
//         {leftIcon ? leftIcon : null}

//         <InputText
//           id={label}
//           className={mappedProps.className}
//           style={textStyle}
//           value={newVal}
//           onClick={onClick}
//           onChange={localOnChange}
//           readOnly={readOnly}
//         />

//         <label htmlFor={label}>{label}</label>
//       </span>
//     </View>
//   );
// }

export function Text(props: any) {
  const mappedProps = mapPRProps("text", props);
  return (
    <div
      key={mappedProps.key}
      style={mappedProps.style}
      className={mappedProps.className}
    >
      {mappedProps.value}
    </div>
  );
}

export function TextArea(props: any) {
  const mappedProps = mapPRProps("textarea", props);
  const {
    key,
    style,
    className,
    value,
    rows,
    cols,
    autoResize,
    onChange,
    readOnly,
  } = mappedProps;

  return (
    <InputTextarea
      key={key}
      style={style}
      rows={rows}
      cols={cols}
      autoResize={autoResize}
      className={className}
      onChange={onChange}
      readOnly={readOnly}
      defaultValue={value}
    ></InputTextarea>
  );
}

export function PickerRow(props: any) {
  const mappedProps = mapPRProps("pickerrow", props);
  const {
    key,
    className,
    items,
    value,
    label,
    onChange,
    disabled,
    textProps,
    containerProps,
    pickerProps,
  } = mappedProps;

  const [stateVal, setNewVal] = React.useState(value);

  const localOnChange = (e) => {
    const val = e.target.value;
    if (val !== stateVal) {
      setNewVal(val);
      if (onChange) {
        onChange(e);
      }
    }
  };

  return (
    <View
      key={"stringarra" + label}
      style={{
        ...styles.defaultRowStyle,
        marginLeft: styles.margin * 2,
        ...containerProps?.style,
      }}
    >
      <View>
        {Label({
          textProps: { ...textProps, style: { ...textProps?.style } },
          label,
        })}
      </View>

      <View>
        <Dropdown
          key={key}
          style={{ ...styles.defaultPickerStyle, ...pickerProps?.style }}
          options={items}
          className={className}
          onChange={localOnChange}
          optionLabel="name"
          disabled={disabled}
          placeholder={label}
          value={stateVal}
        />
      </View>
    </View>
  );
}

export function Span(props: any) {
  const mappedProps = mapPRProps("text", props);
  return (
    <span
      key={mappedProps.key}
      style={mappedProps.style}
      className={mappedProps.className}
    >
      {mappedProps.value}
    </span>
  );
}

export function Image(props: any) {
  const mappedProps = mapPRProps("image", props);
  const { key, style, src, alt, title, height, width } = mappedProps;
  const image = (
    <img
      key={key}
      style={style}
      src={src}
      alt={alt}
      title={title}
      height={height}
      width={width}
    />
  );
  if (width && height) {
    return (
      <div
        key={"div" + key}
        style={{
          height,
          width,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundImage: `url("./webapp.png")`,
        }}
      >
        {image}
      </div>
    );
  } else {
    return image;
  }
}

export function Video(props: any) {
  const mappedProps = mapPRProps("video", props);
  return (
    <video
      key={mappedProps.key}
      style={mappedProps.style}
      height={mappedProps.height}
      width={mappedProps.width}
      controls={mappedProps.controls}
      autoPlay={mappedProps.autoPlay}
    >
      <source src={mappedProps.src} type={mappedProps.mediaType} />
    </video>
  );
}

export function Switch(props: any) {
  const mappedProps = mapPRProps("switch", props);

  const style = { ...styles.defaultTextBoxStyle, ...mappedProps.style };
  const className = "p-d-flex p-jc-between";

  const { label, value, onChange, readOnly, leftIcon } = mappedProps;

  return (
    <View style={style} className={className}>
      {leftIcon ? leftIcon : null}
      <Text
        style={{
          ...styles.defaultLabelTextStyle,
          margin: styles.margin,
          marginBotton: 0,
        }}
      >
        {label}
      </Text>
      <InputSwitch
        style={{}}
        checked={value}
        onChange={onChange}
        disabled={readOnly}
      />
    </View>
  );
}

export function SafeAreaView(props: any) {
  const mappedProps = mapPRProps("safeareaview", props);
  const { style, children } = mappedProps;

  return <div style={style}>{children}</div>;
}

export function Card(props: any) {
  const mappedProps = mapPRProps("card", props);
  const { style, children } = mappedProps;
  return (
    <View className="p-shadow-8" style={style}>
      {children}
    </View>
  );
}

export function BadgeText(props: any) {
  const mappedProps = mapPRProps("badge", props);
  const { value, style } = mappedProps;
  return <Badge key={"badge" + value} value={value} style={style} />;
}

export function BadgeCloseButton(props: any) {
  const mappedProps = mapPRProps("badge", props);
  const { value, onClick } = mappedProps;
  return (
    <PRButton
      key={"badgeC" + value}
      onClick={() => onClick(value)}
      label={value}
      iconPos={"right"}
      className="p-button-rounded"
    >
      {getIconElement(Icons.close)}
    </PRButton>
  );
}

export function Label(props) {
  const { textProps, label } = props;
  return Text({
    ...textProps,
    value: label,
    style: {
      fontSize: FontSize.label,
      color: Colors.label,
      marginLeft: 5,
      ...textProps.style,
    },
  });
}

export function StringArray(props: any) {
  const [newVal, setNewVal] = React.useState("");
  const {
    textProps,
    buttonProps,
    containerProps,
    values,
    onChange,
    disabled,
    label,
  } = props;
  //  className: (textProps?.className || '') + ' p-d-inline'

  const removeValue = (val) => {
    onChange(values?.filter((x) => x !== val));
  };

  const badgeButtons = values?.map((x) => {
    return disabled
      ? BadgeText({ ...buttonProps, value: x })
      : BadgeCloseButton({ ...buttonProps, value: x, onClick: removeValue });
  });

  const onAddValue = () => {
    const vals = values?.filter((x) => x !== newVal) || [];
    vals.push(newVal);
    onChange(vals);
    setNewVal("");
  };

  // Space betwen
  return (
    <View
      key={"stringarra" + label}
      style={{ ...styles.defaultRowStyle, ...containerProps?.style }}
    >
      <View>{Label({ textProps, label })}</View>
      <View
        style={{
          marginTop: 0,
          width: containerProps?.style?.width || styles.drawWidth - 70,
          display: "flex",
        }}
      >
        <InputText value={newVal} onChange={(e) => setNewVal(e.target.value)} />
        <PRButton onClick={onAddValue} disabled={disabled || !newVal?.length}>
          {getIconElement(Icons.add)}
        </PRButton>
      </View>
      {badgeButtons}
    </View>
  );
}
