import { Component } from "react";
import { Link } from "react-router-dom";
import { Colors } from "../../constants/Colors";
import Icons, { getIconWithBadge } from "../../constants/Icons";
import styles from "../../constants/Styles";
import { Post } from "../../API";
import AppLogger from "../../utils/AppLogger";
import { AuthUtils } from "../../utils/AuthUtils";
import { IWithDBProperties } from "../../utils/ClassUtils";
import { LocationHistory, LocationMatch } from "../../utils/NavUtils";
import { Link as PostLink, PostInternals } from "../../utils/PostInternals";
import { Serializer } from "../../utils/SerializationUtils";
import { Spinner, View } from "../ui";
import { LinkArticle } from "./LinkArticle";
import { LinkCard } from "./LinkCard";
import {
  listPrivatePosts,
  listPublicLinksData
} from "./utils/LinkGraphQLUtils";

export interface LinksProps {
  match: LocationMatch;
  history: LocationHistory;
  firstPageMode?: boolean;
}

export interface LinksState {
  loading: boolean;
  posts: IWithDBProperties<Post>[];
  links: PostLink[] | undefined;
  lastError: any;
  user: any;
  selectedPostId: string | undefined;
  selectedLinkId: string | undefined;
}

export class Links extends Component<LinksProps, LinksState> {
  constructor(props) {
    super(props);

    const id = props.match?.id;
    let selectedLinkId: string | undefined;
    let selectedPostId: string | undefined;
    if (this.isLink()) {
      selectedLinkId = id;
    } else {
      selectedPostId = id;
    }

    this.state = {
      loading: true,
      posts: [],
      links: [],
      lastError: null,
      user: null,
      selectedLinkId,
      selectedPostId,
    };
  }

  async componentDidMount() {
    try {
      const { firstPageMode } = this.props;
      let user: any;
      let posts;
      try {
        if (!firstPageMode) {
          user = await AuthUtils.getUser();
          posts = await listPrivatePosts();
        }
      } catch (err) {
        AppLogger.error("Failed to get user or private posts", err);
      }
      const links = await listPublicLinksData(user);
      const updatedState: any = { posts, links, user };
      if (firstPageMode === true && links?.length) {
        updatedState.selectedLinkId = links[0].id;
      }

      this.setState(updatedState);
    } catch (err) {
      AppLogger.error(`Error in links ${err}`);
    } finally {
      this.setState({ loading: false });
    }
  }

  isLink() {
    return this.props.match?.path?.indexOf("links") >= 0;
  }

  onSelectLink(link, post) {
    const { history } = this.props;
    let selectedLinkId = link?.id;
    let selectedPostId = post?.id;
    if (selectedLinkId) {
      history.push(`/links/${selectedLinkId}`);
    } else if (selectedPostId) {
      history.push(`/posts/${selectedPostId}`);
    } else {
      history.push(`/links`);
    }
    this.setState({ selectedLinkId, selectedPostId });
    AppLogger.debug("OnSelectLink Scroll to 0");
    window.scrollTo(0, 0);
  }

  getCardMargins(noOfElements, noOfColumns){
    const lastColumn = (noOfElements+1)%noOfColumns === 0;
    return {marginRight:lastColumn?0:styles.margin};
  }

  getCards(posts: IWithDBProperties<Post>[], links: PostLink[] | undefined) {
    const {  selectedLinkId } = this.state;
    if (!posts?.length && !links?.length) {
      return null;
    }
    const elements: any[] = [];
    const cardSize = 400;
    const containerWidth = styles.drawWidth;
    const noOfColumns = Math.max(Math.floor(containerWidth / cardSize), 1);
    const width =
      (containerWidth - (noOfColumns-1)*styles.margin) / noOfColumns;

    const rowStyle = {
      display: "block",
      background: Colors.background,
      marginBottom: styles.margin,
    };
    const height = (width * 600) / 800;
    const columnStyle = {
      display: "inline-block",
      width,
      height,

      marginBottom: 0,
    } as React.CSSProperties;

    const linkIds: string[] = [];
    if (posts?.length) {
      for (const post of posts) {
        const link = Serializer.deserialize<PostInternals>(
          post?.internals
        )?.link;
        if (!link || !post || link?.id === selectedLinkId) {
          continue;
        }
        linkIds.push(link?.id);
        const cardMargins = this.getCardMargins(elements.length,noOfColumns)
        elements.push(
          <LinkCard
            key={"card" + post.id + link?.id}
            containerProps={{
              style: { ...columnStyle, ...cardMargins },
            }}
            activeLink={link}
            activePost={post}
            onClick={this.onSelectLink.bind(this, link, post)}
          />
        );
      }
    }

    if (links?.length) {
      for (const link of links) {
        if (
          !link ||
          link?.id === selectedLinkId ||
          linkIds.indexOf(link?.id) >= 0  ||
          link.hideInList
        ) {
          continue;
        }
        linkIds.push(link.id);
        const cardMargins = this.getCardMargins(elements.length,noOfColumns)
        elements.push(
          <LinkCard
            key={"card" + link?.id}
            containerProps={{
              style: { ...columnStyle, ...cardMargins },
            }}
            activeLink={link}
            activePost={undefined}
            onClick={this.onSelectLink.bind(this, link, undefined)}
          />
        );
      }
    }

    return (
      <View key={"cardcolumns"} style={rowStyle}>
        {elements}
      </View>
    );
  }

  getArticle() {
    const { posts, links, user, selectedLinkId, selectedPostId } = this.state;
    const { history, match, firstPageMode } = this.props;

    if (!selectedLinkId && !selectedPostId) {
      return null;
    }

    const post = posts?.find((x) => x?.id === selectedPostId);
    let link = links?.find((x) => x?.id === selectedLinkId);

    if (!link && post) {
      const postInternals = Serializer.deserialize<PostInternals>(
        post?.internals
      );
      link = postInternals?.link;
    }

    if (!link && selectedLinkId) {
      const linkPost = posts?.find(
        (x) =>
          Serializer.deserialize<PostInternals>(x.internals)?.link?.id ===
          selectedLinkId
      );
      if (linkPost != null) {
        link = Serializer.deserialize<PostInternals>(linkPost.internals)?.link;
      }
    }

    return (
      <LinkArticle
        key={"article" + link?.id}
        containerStyle={{ marginBottom: styles.margin }}
        activeLink={link}
        activePost={post}
        user={user}
        history={history}
        match={match}
        firstPageMode={firstPageMode}
      />
    );
  }

  render() {
    const { loading, posts, user, links } = this.state;

    if (loading) {
      return <Spinner style={styles.containerStyle} />;
    }

    const elements: any = [];
    if (user) {
      elements.push(
        <Link
          key={"linktonewlink"}
          to="/postedit/newlink"
          style={{
            zIndex: 4,
            position: "absolute",
            width: "3rem",
            height: "3rem",
            opacity: 0.7,
            right: "1rem",
            top: "1rem",
            backgroundColor: "darkorange",
            borderRadius: 24,
          }}
        >
          <span aria-hidden="true">
            {getIconWithBadge(Icons.add, undefined, undefined, 2)}
          </span>
        </Link>
      );
    }

    elements.push(
      <View
        key="Links"
        style={{
          position: "relative",
          width: styles.fullWidth,
          justifyContent: "center",
          margin: "0px auto",
          textAlign: "center",
        }}
      >
        {this.getArticle()}
        {this.getCards(posts, links)}
      </View>
    );

    return elements;
  }
}
