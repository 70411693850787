import { ScaledImage } from "./ScaledImage";

export class PostInternals {
  link?: Link;

  postType?:PostType
  subPost?:SupportPostInternals|ReviewPostInternals;
}



export class SupportPostInternals{
supportType?:SupportType;
supportStatus?:SupportStatus;
}

export class ReviewPostInternals{

}


export type PostType = "Support"|"Review"


export type SupportType = "Bug"|"FeatureRequest"|"InfoRequest"|"Other"
export type SupportStatus = "Open"|"InProgress"|"Closed";



export interface BlockSettings {
  size?: string;
  alignment?: string;
  partOfCard?: boolean;
}


export type ImageSizesType = "124x124" | "800x" | "800x600"  | "1000x";

export const defaultThumbnailFormats:ScaledImagesType = {
  "124x124": {
    width: 124,
    height: 124
  }
}


export const defaultExportFormats: ScaledImagesType = {
  "124x124": {
    width: 124,
    height: 124
  },
  "800x600":{
    width: 800,
    height: 600
  },
  "800x": {
    width: 800
  },
  "1000x": {
    width: 1000
  }
};

export type ScaledImagesType = {
  [key in ImageSizesType]?: ScaledImage;
};

export type AssetType = "image" | "video";

export abstract class BlockAsset {
  public key?: string | undefined;
  public url: string | undefined;

  public abstract get assetType(): AssetType;
}

export class ImageAsset extends BlockAsset {
  public get assetType(): AssetType {
    return "image";
  }
  scaledImages?: ScaledImagesType;
  mediaType?: string;
  alt?: string;
  title?: string;
}

export class VideoAsset extends BlockAsset {
  public get assetType(): AssetType {
    return "video";
  }
  autoplay?: boolean;
  mediaType?: string;
  alt?: string;
  title?: string;
}

export type BlockType =
  | "image"
  | "video"
  | "paragraph"
  | "heading"
  | "blockquote";

export interface ContentBlock {
  blockType: BlockType;
  contentType: string | undefined;
  element: string | undefined;
  content: string | undefined;
  settings: BlockSettings;
  id: string;
  linkId: string;
  asset?: BlockAsset;
  author?: Author;
  created: number | string;
  updated: number | string;
}

export interface Author {
  name: string;
  shortId?: string;
  email?: string;
  organization?: string;
  tags?: string[];
}

export interface Content {
  excerpt?: string;
  blocks: ContentBlock[];
}

export type LinkType = "article" | "support" | "legal";

export interface Link {
  id: string;
  linkType: LinkType;
  authors: Author[];
  created: number | string;
  published: number | string;
  parentId?: string;
  content: Content;
  title: string;
  slug: string;
  format?: string;
  version?: string;
  relatedIds?: number[];
  tags?: string[];
  meta?: any;
  hideInList?:boolean;
  hideTitle?:boolean;

}
