export interface IDBProperties{
  updatedAt?:string
  createdAt?:string
  owner:string
}


export const undefinedDBProperties = {updatedAt:undefined, createdAt:undefined, owner:undefined}

export type IWithDBProperties<T extends object> = T & IDBProperties


