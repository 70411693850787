import { Component } from "react";
import styles from "../../constants/Styles";
import { LocationMatch, LocationHistory } from "../../utils/NavUtils";
import { Link } from "../../utils/PostInternals";
import { Button, Text, View } from "../ui";
import { LinkArticle } from "./LinkArticle";
import { LinkCard } from "./LinkCard";

import { ShowMode } from "./utils/LinkReactUtils";

interface LinkPreviewProps {
  activePost: any;
  activeLink: Link | undefined;
  user: any;
  match: LocationMatch;
  history: LocationHistory;
}

interface LinkPreviewState {
  showMode: ShowMode;
}

export class LinkPreview extends Component<LinkPreviewProps, LinkPreviewState> {
  constructor(props) {
    super(props);

    this.state = {
      showMode: ShowMode.Card,
    };
  }

  onClickPreview() {
    const { showMode: oldMode } = this.state;
    let showMode = ShowMode.Card;
    if (oldMode === ShowMode.Card) {
      showMode = ShowMode.Article;
    } else if (oldMode === ShowMode.Article) {
      showMode = ShowMode.FirstPage;
    }
    this.setState({ showMode });
  }

  render() {
    const { showMode } = this.state;
    const { activeLink, activePost, user, history, match } = this.props;

    if (!activeLink) {
      return (
        <View>
          <Text>Link is not set</Text>
        </View>
      );
    }

    let content: any = null;
    if (showMode === ShowMode.Article || showMode === ShowMode.FirstPage) {
      content = (
        <LinkArticle
        key={`article`+showMode}
          activePost={activePost}
          activeLink={activeLink}
          user={user}
          history={history}
          match={match}
          firstPageMode={showMode=== ShowMode.FirstPage}
        />
      );
    } else if (showMode === ShowMode.Card) {
      content = (
        <View style={{ margin: styles.margin }}>
          <LinkCard
            containerProps={{
              style: {
                width: styles.maxCardWidth,
                height: styles.maxCardHeight,
              },
            }}
            activePost={activePost}
            activeLink={activeLink}
            onClick={this.onClickPreview.bind(this)}
          />
        </View>
      );
    }
    const rowStyle = {
      display: "block",
      marginBottom: 0,
    };

    return (
      <View
        key="linkpreview"
        style={{
          position: "relative",
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: styles.maxViewWidth,
        }}
      >
        <Button
          title={showMode}
          style={{
            zIndex: 4,
            position: "absolute",
            width: "6rem",
            height: "2rem",
            opacity: 0.7,
            right: "1rem",
            top: "1rem",
            backgroundColor: "darkorange",
            borderRadius: 24,
          }}
          onClick={this.onClickPreview.bind(this)}
        />
        <View key={"cardcolumns"} style={{...rowStyle,padding:styles.margin}}>
          {content}
        </View>
      </View>
    );
  }
}
