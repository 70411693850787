import { Component, ReactNode } from "react";

import styles from "../constants/Styles";
import AppLogger from "../utils/AppLogger";
import { LocationHistory, LocationMatch } from "../utils/NavUtils";
import { Links } from "./links/Links";
import { Spinner, View } from "./ui";

export interface HomeProps {
  match: LocationMatch;
  history: LocationHistory;
  children?: ReactNode;
}

export interface HomeState {
  loading: boolean;

  lastError: any;
}

export class Home extends Component<HomeProps, HomeState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      lastError: null,
    };
  }

  async componentDidMount() {
    try {
      this.setState({ loading: false });
    } catch (err) {
      AppLogger.error(`Error in links ${err}`, null);
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const { match, history } = this.props;
    if (loading) {
      return <Spinner style={styles.containerStyle} />;
    }

    return (
      <View
        key="Home"
        style={{
          position: "relative",
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
          maxWidth: styles.maxViewWidth,
          paddingTop: styles.margin,
        }}
      >
        <Links match={match} history={history} firstPageMode={true} />
      </View>
    );
  }
}
