import {Amplify } from "aws-amplify";
import awsconfig from "../amplifyconfiguration.json";

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

let redirectSignIn = "https://dogcontroller.com";
let redirectSignOut = "https://dogcontroller.com";
let responseType = awsconfig.oauth.responseType;
if (isLocalhost) {
  redirectSignIn = window.location.origin;
  redirectSignOut = window.location.origin;
}

let awsMobile = {...awsconfig};

// @ts-ignore
awsMobile.Analytics = { disabled: true };
awsMobile.oauth.redirectSignIn = redirectSignIn;
awsMobile.oauth.redirectSignOut = redirectSignOut;
awsMobile.oauth.responseType = responseType;
//@ts-ignore
awsMobile.oauth.scope = undefined;


Amplify.configure(awsMobile);

// Storage.configure({ level: "public" });
// PubSub.configure(awsMobile);
