import React, { Component } from "react";
import AppLogger from "../utils/AppLogger";

export interface ErrorBoundaryProps{
}

export interface ErrorBoundaryState{
  hasError:boolean;

}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    AppLogger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>Something went wrong.</div>;
    }

    //@ts-ignore
    return this.props.children;
  }
}
