import { getContentTypeAndExtensionFromUri } from "../../../utils/StorageUtils";
import { uploadData } from "aws-amplify/storage";
import { S3Storage } from "./LinkContentUtils";
import AppLogger from "../../../utils/AppLogger";
import { StorageLevel } from "../../../constants/GlobalSettings";

// Storage.configure({ level: StorageLevel, track:true });

export async function storeItem(
  key: string,
  uriOrObject: any,
  isNotUri?: boolean,
): Promise<string> {
  let content: any;
  let { contentType } = getContentTypeAndExtensionFromUri(
    uriOrObject,
    "image",
    "png",
  );
  if (isNotUri) {
    content = JSON.stringify(uriOrObject);
    contentType = "application/json";
  } else {
    const fetchedRecording = await fetch(uriOrObject);
    const blobbedRecording = await fetchedRecording.blob();
    content = blobbedRecording;
  }

  const keyItems = key.split("/");
  const level = keyItems.shift();
  let fileName = keyItems.join("/");
  const putConfig = {
    level,
    contentType,
  };

  const returnObj = await uploadData({
    key: fileName,
    data: content,
    options: putConfig,
  });
  // AppLogger.debug(`In storeItem ${dumpObj(returnObj,'storagePut')}`)
  return JSON.stringify(returnObj);
}

export function getLinkJSONUri(linkId: string) {
  return `${S3Storage}public/links/${linkId}.json`;
}

export async function readLinkJSON(linkId: string) {
  try {
    if (!linkId?.length) {
      return;
    }

    const fileUri = getLinkJSONUri(linkId);
    const fetchResult = await fetch(fileUri);
    const text = await fetchResult.text();
    return text;
  } catch (ex) {
    AppLogger.debug(`Failed to read link ${linkId}`);
  }
}
