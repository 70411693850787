import React, { Component } from "react";
import { Colors } from "../../constants/Colors";
import styles from "../../constants/Styles";
import { AuthUtils } from "../../utils/AuthUtils";
import { getBOUserRightsFromBO } from "../../utils/BOUtils";
import { ContentBlock, ImageAsset, Link } from "../../utils/PostInternals";
import { ConfirmInfo } from "../ConfirmDialog";
import {
  EditTextProp,
  PickerRow,
  StringArray,
  SuperInput,
  Switch,
  Text,
  View,
} from "../ui";
import { createSlug } from "./utils/LinkContentUtils";

interface LinkOverviewProps {
  activePost: any;
  activeLink: Link | undefined;
  user: any;
  onChangeLink: (Link) => void;
}

interface LinkOverviewState {
  creators: any; // Author[];
  publishPos: number;
  confirm: ConfirmInfo | null;
}

export class LinkOverview extends Component<
  LinkOverviewProps,
  LinkOverviewState
> {
  refId: any;
  refTitle: any;
  refExcerpt: any;
  refSlug: any;
  refAuthors: any;
  refCreated: any;
  refPublished: any;
  refLinkType: any;

  constructor(props) {
    super(props);

    this.refId = React.createRef();
    this.refTitle = React.createRef();
    this.refExcerpt = React.createRef();
    this.refSlug = React.createRef();
    this.refAuthors = React.createRef();
    this.refCreated = React.createRef();
    this.refPublished = React.createRef();
    this.refLinkType = React.createRef();
  }

  resetConfirm() {
    this.setState({ confirm: null });
  }

  validateField(fieldName: string): boolean {
    // let formatCheck = true;
    // //let formatString = "Field ok"
    // if (fieldName === 'title') {
    //   const title = this.props?.selectedLink?.title;
    //   formatCheck = /[a-zåäöA-ZÅÄÖ]{2,100}/g.test(title)
    // }
    // return formatCheck;
    return false;
  }

  onUpdateTitle(newValue) {
    // let slug = newValue.toLowerCase()
    //   .replace(/[\s]/g, "-")
    //   .replace(/å/g, "a")
    //   .replace(/ä/g, "a")
    //   .replace(/ö/g, "o")
    //   .replace(/[^a-z-]/g, '');
    // onEditLinkProp('platformSpecific.kitse.slug', slug);
    // onEditLinkProp('title', newValue);
  }

  onChangeBlock(newBlock, id) {
    const { activeLink, onChangeLink } = this.props;
    let content = activeLink?.content;
    const blocks = activeLink?.content?.blocks;
    const block = blocks?.find((x) => x.id === id);
    let i = id;
    if (block) {
      i = blocks?.indexOf(block);
    } else if (!blocks?.length || blocks?.length < i) {
      return;
    }
    if (!blocks) {
      return;
    }

    blocks[i] = newBlock;
    content = { ...content, blocks };
    onChangeLink({ ...activeLink, content });
  }

  getBlock(block: ContentBlock, i, boUserRights) {
    const blockType = block.blockType;
    const blockString = `${blockType} ${i}`;
    const ignoreBlockTypes = ["paragraph", "heading", "blockquote"];
    const controlStyle = { width: styles.drawWidth * 0.9 };

    if (ignoreBlockTypes.indexOf(blockType) >= 0) {
      return null;
    }
    const id = block?.id || i;
    let alt: any = null;
    let title: any = null;
    if (blockType === "image" || blockType === "video") {
      const asset: ImageAsset = block.asset || new ImageAsset();
      alt = (
        <EditTextProp
          key={"alt" + i}
          containerProps={{
            style: { ...controlStyle, marginTop: styles.margin },
          }}
          textProps={{ style: { ...controlStyle } }}
          label="Alt"
          value={asset.alt}
          onChange={(e) => {
            asset.alt = e.target.value;
          }}
          readOnly={!boUserRights.canUpdate}
        />
      );
      title = (
        <EditTextProp
          key={"title" + i}
          containerProps={{
            style: { ...controlStyle, marginTop: styles.margin * 2 },
          }}
          textProps={{ style: { ...controlStyle } }}
          readOnly={!boUserRights.canUpdate}
          label="Title"
          value={asset.title}
          onChange={(e) => {
            asset.title = e.target.value;
          }}
        />
      );
    }

    return (
      <View
        key={"block" + id}
        style={{
          background: Colors.background,
          width: styles.drawWidth * 0.9,
          marginTop: styles.margin,
        }}
      >
        <Text
          style={{
            color: Colors.label,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {blockString}
        </Text>
        {alt}
        {title}
      </View>
    );
  }

  getBlocks() {
    const { activeLink, activePost, user } = this.props;
    const userId = AuthUtils.getUserName(user);
    const boUserRights = getBOUserRightsFromBO(activePost, userId);
    const content = activeLink?.content;
    const blocks = content?.blocks;
    const renderedBlocks: any[] = [];
    if (!blocks?.length) {
      return (
        <Text
          style={{ textAlign: "start" }}
        >{`No blocks, create blocks in content tab`}</Text>
      );
    }

    for (let i = 0; i < blocks.length; i++) {
      const renderedBlock = this.getBlock(blocks[i], i, boUserRights);
      if (renderedBlock) {
        renderedBlocks.push(renderedBlock);
      }
    }
    return renderedBlocks;
  }

  render() {
    const { activeLink, activePost, user, onChangeLink } = this.props;
    const userId = AuthUtils.getUserName(user);
    const boUserRights = getBOUserRightsFromBO(activePost, userId);
    const content = activeLink?.content;
    const controlStyle = { width: styles.drawWidth * 0.9 };
    const stringArrayContainerProps = {
      style: { ...controlStyle, marginTop: styles.margin, textAlign: "start" },
    };
    const stringArrayTextProps = {
      style: { ...controlStyle, textAlign: "start" },
    };
    const superInputContainerProps = {
      style: {
        ...controlStyle,
        marginTop: styles.margin * 2.5,
        width: undefined,
      },
    };
    const superInputTextProps = { style: { ...controlStyle } };
    const pickerContainerProps = {
      style: { ...controlStyle, margin: undefined, marginLeft: 0 },
    };
    const pickerProps = { style: { ...controlStyle } };
    const pickerTextProps = { style: { ...controlStyle } };

    return (
      <View
        key="LinkOverview"
        style={{
          margin: styles.margin,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SuperInput
          key={"txtId"}
          ref={this.refId}
          label="Id"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={activeLink?.id}
          disabled={!boUserRights?.canUpdate}
          onChange={(e) => {
            onChangeLink({ ...activeLink, id: e.target.value });
          }}
        />

        <SuperInput
          key={"txtTitle"}
          ref={this.refTitle}
          label="Title"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={activeLink?.title}
          disabled={!boUserRights?.canUpdate}
          onChange={(e) => {
            onChangeLink({
              ...activeLink,
              title: e.target.value,
              slug: createSlug(e.target.value),
            });
          }}
        />
        <View style={{...controlStyle,flexDirection:"row", display:"flex"}}>
        <Switch
          key={"hideTitle"}

          label="Hide title"
          value={activeLink?.hideTitle}
          readOnly={!boUserRights?.canUpdate}
          onChange={(e) => {
            onChangeLink({
              ...activeLink,
              hideTitle: e.target.value,
            });
          }}
        />
        <Switch
          key={"showInList"}
          label="Hide in list"
          style={{marginLeft:styles.margin}}
          value={activeLink?.hideInList}
          readOnly={!boUserRights?.canUpdate}
          onChange={(e) => {
            onChangeLink({
              ...activeLink,
              hideInList: e.target.value,
            });
          }}
        />
        </View>

        <SuperInput
          key={"txtExcerpt"}
          ref={this.refExcerpt}
          label="Excerpt"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={content?.excerpt}
          disabled={!boUserRights?.canUpdate}
          onChange={(e) => {
            onChangeLink({
              ...activeLink,
              content: { ...content, excerpt: e.target.value },
            });
          }}
        />
        <SuperInput
          key={"txtAuthor"}
          ref={this.refAuthors}
          label="Authors"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={activeLink?.authors?.map((x) => x.name)?.join(", ")}
          disabled={true}
        />
        <SuperInput
          key={"txtSlug"}
          ref={this.refSlug}
          label="Slug"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={activeLink?.slug}
          disabled={true}
        />
        <SuperInput
          key={"txtCreated"}
          ref={this.refCreated}
          label="Created"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={
            activeLink?.created
              ? new Date(activeLink?.created).toLocaleString()
              : "Not created"
          }
          disabled={true}
        />

        <SuperInput
          key={"txtPublished"}
          ref={this.refPublished}
          label="Published"
          containerProps={superInputContainerProps}
          textProps={superInputTextProps}
          value={
            activeLink?.published
              ? new Date(activeLink?.published).toLocaleString()
              : "Not published"
          }
          disabled={true}
        />

        {/* <SuperInput key={'txtLinkType'} ref={this.refLinkType} label="Link type" containerProps={superInputContainerProps} textProps={superInputTextProps}
        value={activeLink?.linkType}
        disabled={!boUserRights?.canUpdate}
        onChange={(e) => { onChangeLink({ ...activeLink, linkType: e.target.value }) }}
      /> */}

        <PickerRow
          key={"txtPostType"}
          label="Type"
          containerProps={pickerContainerProps}
          textProps={pickerTextProps}
          pickerProps={pickerProps}
          items={[
            { name: "article" },
            { name: "firstpage" },
            { name: "legal" },
          ]}
          value={{ name: activePost?.postType || activeLink?.linkType }}
          disabled={!boUserRights?.canUpdate}
          onChange={(e) => {
            onChangeLink({ ...activeLink, linkType: e?.target?.value?.name });
          }}
        />

        <StringArray
          key={"txtTags"}
          label="Tags"
          containerProps={stringArrayContainerProps}
          textProps={stringArrayTextProps}
          values={activeLink?.tags}
          onChange={(values) => {
            onChangeLink({ ...activeLink, tags: values });
          }}
          disabled={!boUserRights?.canUpdate}
        />

        <StringArray
          key={"txtRelatedIds"}
          label="Related ids"
          containerProps={stringArrayContainerProps}
          textProps={stringArrayTextProps}
          values={activeLink?.relatedIds}
          onChange={(values) => {
            onChangeLink({ ...activeLink, relatedIds: values });
          }}
          disabled={!boUserRights?.canUpdate}
        />

        {this.getBlocks()}

        <View style={{ margin: styles.margin }} />
      </View>
    );
  }
}
