class styles {
  public static margin = 10;
  public static scrollMargin = 50;
  private static maxWidthControl = 2000;
  public static maxEditorWidth = 1000;
  private static percentViewLimit = 0.9
  private static percentLimit = 0.8

  public static maxCardWidth = Math.min(800,styles.percentViewLimit*window.innerWidth);
  public static maxCardHeight =    Math.min(600,styles.percentViewLimit*window.innerWidth*600/800);


  public static maxViewWidth = window.innerWidth < 2000?window.innerWidth:styles.percentViewLimit*window.innerWidth;


  private static _innerWidth = window.innerWidth;

  public static updateInnerWidth(){
    styles._innerWidth = window.innerWidth;
  }

  public static get isPhone() {
    return styles.drawWidth < 800;
  }

  public static get isDesktop() {
    return !styles.isPhone;
  }

  public static get drawWidth(){
    return Math.min(styles._innerWidth-20,800);
  }

  public static get fullWidth(){
    return styles._innerWidth;
  }


  public static get drawHeight(){
    return window.innerHeight;
  }


  private static get defaultWidth() {
    return Math.floor(Math.min(styles.drawWidth-2*styles.margin, styles.maxWidthControl));
  }

  private static get defaultViewWidth() {
    return Math.round(Math.min(styles.drawWidth-2*styles.margin, styles.maxWidthControl));
  }


  public static get defaultTextBoxStyle() {
    return { width: styles.defaultWidth }
  }

  public static get defaultPickerStyle() {
    return { width: styles.defaultWidth }
  }

  public static get defaultRowStyle() {
    return { width: styles.defaultWidth }

  }

  public static get defaultLabelTextStyle(){
   return {fontSize:10, color:"lightgrey"}
  }

  public static get defaultButtonStyle() {
    return { width: 110 };
  }

  public static get defaultViewStyle() {
    return {
    }

  }

  public static get containerStyle(){
    return {
      position: "relative",
      display:"flex",
      width: "90%",
      justifyContent: "center",
      textAlign: "center"
    };

  }

  public static get cardBlockquote() {
    return {
      marginLeft: (styles.margin * 2),
      textAlign: "start",
      fontWeight: "bold"
    }
  }

  public static get cardText() {
    return {
      textAlign: "start"
    }
  }

  public static get cardHeading() {
    return {
      textAlign: "start",
      fontSize: 25,
      fontWeight: "bold"
    }
  }

  public static get cardImage() {
    return {

    }
  }

  public static get cardVideo() {
    return {

    }
  }



}



export default styles;
