import { Hub } from "aws-amplify/utils";
import React from "react";
import { AuthUtils } from "../utils/AuthUtils";

export default function useUser() {
  const [user, setUser] = React.useState<any>(undefined);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadUser() {
      Hub.listen("auth", ({ payload: { event, message } }) => {
        switch (event) {
          case "signedIn":
          case "signInWithRedirect":
            AuthUtils.getUser().then((userData) => setUser(userData));
            break;
          case "signedOut":
            setUser(null);
            break;
          case "signInWithRedirect_failure":
          case "tokenRefresh_failure":
            console.log("Sign in failure", message);
            break;
        }
      });
      const userData = await AuthUtils.getUser();
      setUser(userData);
    }

    loadUser();
  }, []);

  return { user, setUser };
}
