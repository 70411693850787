



const strings = {
  'en': {

  },
  'sv': {

  }
}

export default strings;
