import { Link } from "react-router-dom";
import styles from "../../../constants/Styles";
import { ContentBlock, Link as PostLink } from "../../../utils/PostInternals";
import { BadgeText, Image, Span, Text, Video, View } from "../../ui";
import {
  getAssetAlt,
  getAssetTitle,
  getAssetUrl,
  getGalleriaItemFromBlock,
  getMediaBlocks,
  getMediaType,
} from "./LinkContentUtils";

export enum ShowMode {
  Card = "Card",
  Article = "Article",
  FirstPage = "FirstPage",
}

export interface ImageInfoItem {
  thumbnailImageSrc: string;
  itemImageSrc: string;
  cardImageSrc: string;
  alt: string;
  title: string;
}

function replaceLink(content: string, subKey) {
  // const test = `<a href=\"https://dogcontroller.com/links/1111\" rel=\"noopener noreferrer\" target=\"_blank\">https://dogcontroller.com/links/1111</a>`;

  // eslint-disable-next-line
  const splitArr = content?.split(`\"`);
  const href = splitArr[1];
  const text = splitArr?.find((x) => x.startsWith(">"))?.slice(1, -4);
  if (href?.indexOf("http") >= 0) {
    return (
      <a key={subKey} href={href} rel={"noopener noreferrer"} target={"_blank"}>
        {text}
      </a>
    );
  } else {
    return (
      <Link to={href} key={subKey}>
        {text}
      </Link>
    );
  }
}

function replaceList(content: string, subKey) {
  // const test = "<ol><li>asdf</li><li>basdf</li></ol>"

  let ordered = false;
  if (content?.startsWith("<ol>")) {
    ordered = true;
  }

  const splitArr = content?.split("<li>");
  const liArr = splitArr
    .slice(1)
    ?.map((x) => x.split("</li>")[0])
    ?.map((y, i) => (
      <li key={subKey + "_" + i}>
        {replaceHtmlElements({ content: y }, subKey + "_" + i)}
      </li>
    ));

  return ordered ? (
    <ol key={subKey}>{liArr}</ol>
  ) : (
    <ul key={subKey}>{liArr}</ul>
  );
}

function replaceVideo(content: string, subKey) {
  // const test = <iframe class="ql-video" frameborder="0" allowfullscreen="true" src="https://dogcontrollerc77eab00f3c64a25b28526109d7b11bc121714-dogctrl.s3.eu-west-1.amazonaws.com/public/links/2107021011/IMG_2005.mov"></iframe>
  //  eslint-disable-line
  if (!content?.length || content.indexOf(`src="`) < 0) {
    return content;
  }

  const splitArr = content?.split(`src="`);
  const src = splitArr[1]?.split(`"`)[0];

  return (
    <video
      src={src}
      style={{ width: styles.drawWidth }}
      key={subKey}
      controls
    />
  );
}

function getInnerText(ci: string) {
  let tag: string | undefined;
  if(!ci?.length){
    return "";
  }

  if(ci?.indexOf("<") < 0){
    return ci;
  }
  const gtIndex = ci.indexOf(">");
  const wIndex = ci.indexOf(" ");
  if (gtIndex < wIndex || wIndex < 0) {
    tag = ci.substring(1, gtIndex);
  } else {
    tag = ci.substring(1, wIndex);
  }
  if (!tag?.length) {
    return ci;
  }

  let endTagIndex = ci.indexOf(`</${tag}>`);
  if (endTagIndex < 0) {
    return "";
  } else if (endTagIndex > gtIndex + 1) {
    let cie = ci.substring(gtIndex + 1, endTagIndex);
    return getInnerText(cie);
  }
  return "";
}

function replaceHtmlElements(b: { content?: string }, key: string): any[] {
  const contentChildren: any[] = [];
  if (!b?.content?.length) {
    return [];
  }
  let c = b.content;
  if (c?.replaceAll) {
    c = c?.replaceAll(/&nbsp;/gi, "");
  }

  if (c.indexOf("<") < 0) {
    return [c];
  }

  let el = 0;

  for (let i = 0; i < c?.length; i += el) {
    const subKey = key + "_" + i;
    el = 0;
    let ci = c.substring(i);
    const l = ci.indexOf("<");
    if (l > 0) {
      contentChildren.push(ci.substring(0, l));
      el += l;
      ci = ci.substring(l);
    } else if (l < 0) {
      contentChildren.push(ci);
      break;
    }
    let tag: string | undefined;
    const gtIndex = ci.indexOf(">");
    const wIndex = ci.indexOf(" ");
    if (gtIndex < wIndex || wIndex < 0) {
      tag = ci.substring(1, gtIndex);
    } else {
      tag = ci.substring(1, wIndex);
    }
    if (!tag?.length) {
      break;
    }
    let cie = "";
    let endTagIndex = ci.indexOf(`</${tag}>`);
    if (endTagIndex < 0) {
      endTagIndex = ci.indexOf("/>");
      if (endTagIndex < 0) {
        break;
      }
      cie = ci.substring(0, endTagIndex + 2);
    } else {
      cie = ci.substring(0, endTagIndex + `</${tag}>`.length);
    }
    el += cie?.length;
    if (tag === "a") {
      contentChildren.push(replaceLink(cie, subKey));
    } else if (tag === "ol" || tag === "ul") {
      contentChildren.push(replaceList(cie, subKey));
    } else if (tag === "iframe") {
      contentChildren.push(replaceVideo(cie, subKey));
    } else if (tag === "span" || tag === "strong" || tag === "em" || tag === "u") {
      const innerText = getInnerText(cie);
      if (contentChildren?.length) {
        contentChildren[contentChildren?.length - 1] += innerText;
      } else {
        contentChildren.push(innerText);
      }
    } else {
      contentChildren.push(cie);
    }
  }
  return contentChildren;
}

export function block2React(link: PostLink, b: ContentBlock, blockStyle, key) {
  const contentChildren = replaceHtmlElements(b, key);

  if (b.blockType === "paragraph") {
    if (b.content === "<br>") {
      return null;
    }
    return (
      <Text key={key} style={{ ...styles.cardText, ...blockStyle }}>
        {contentChildren}
      </Text>
    );
  } else if (b.blockType === "blockquote") {
    return (
      <Text key={key} style={{ ...styles.cardBlockquote, ...blockStyle }}>
        {contentChildren}
      </Text>
    );
  } else if (b.blockType === "heading") {
    return (
      <Text key={key} style={{ ...styles.cardHeading, ...blockStyle }}>
        {contentChildren}
      </Text>
    );
  } else if (b.blockType === "image") {
    return (
      <Image
        key={key}
        style={{ ...styles.cardImage, ...blockStyle }}
        src={getAssetUrl(b)}
        alt={getAssetAlt(b)}
        title={getAssetTitle(b)}
      />
    );
  } else if (b.blockType === "video") {
    return (
      <Video
        key={key}
        style={{ ...styles.cardVideo, ...blockStyle }}
        src={getAssetUrl(b)}
        mediaType={getMediaType(b)}
        alt={getAssetAlt(b)}
        title={getAssetTitle(b)}
      />
    );
  } else {
    return null;
  }
}

export function getType(activeLink: PostLink) {
  const linkType = activeLink.linkType;

  return <BadgeText key={"badgeLinkType" + activeLink?.id} value={linkType} />;
}

function distinct(arr1: string[]): string[] {
  return arr1.filter((x, i, a) => a.indexOf(x) === i);
}

export function getTags(activeLink: PostLink, infoblockProps?, post?) {
  if (!activeLink || activeLink?.hideTitle) {
    return null;
  }

  let list = activeLink?.tags ? [...activeLink?.tags] : [];
  if (post) {
    list.push("post");
  }
  list = distinct(list);

  const badges = list.map((x) => {
    return (
      <BadgeText
        key={"badgeTags" + activeLink?.id + x}
        value={x}
        style={{
          display: "inline-block",
          opacity: 0.7,
          justifyContent: "start",
          marginRight: styles.margin / 2,
        }}
      />
    );
  });
  return (
    <View
      key={"tags" + activeLink?.id}
      style={{
        display: "block",
        marginTop: styles.margin / 2,
        marginLeft: -styles.margin / 2,
      }}
    >
      {badges}
    </View>
  );
}

export function getLinkType(activeLink: PostLink, infoblockProps?) {
  if (!activeLink?.linkType?.length) {
    return null;
  }

  return (
    <BadgeText
      key={"badgeLinkType" + activeLink?.id}
      value={activeLink?.linkType}
      style={{ marginLeft: -5, opacity: 0.7, ...infoblockProps }}
    />
  );
}

export function getTitle(activeLink: PostLink, infoblockProps?) {
  return (
    <Text
      style={{
        boxShadow: "-5px 0 0 white",
        paddingRight: 5,
        textAlign: "start",
        background: "white",
        display: "inline",
        textTransform: "uppercase",
        fontSize: 10,
        fontWeight: "bold",
        color: "purple",
        paddingBottom: 14,
        ...infoblockProps,
      }}
    >
      {activeLink?.title || "No title"}
    </Text>
  );
}

export function getExcerpt(activeLink: PostLink, infoblockProps?) {
  return (
    <View
      style={{
        background: "white",
        display: "inline",
        paddingBottom: 14,
        ...infoblockProps,
      }}
    >
      <Span
        style={{
          textAlign: "start",
          fontSize: 33,
          position: "relative",
          lineHeight: "33px",
          verticalAlign: "middle",
          boxShadow: "-5px 0 0 white",
          marginRight:15
        }}
      >
        {`${activeLink?.content?.excerpt} ➨`}
      </Span>
    </View>
  );
}

export function getAuthor(activeLink: PostLink, infoblockProps?) {
  if(activeLink?.hideTitle){
    return null;
  }


  const authors =
    activeLink?.authors?.map((x) => x.name)?.join(", ") || "No title";
  const date = activeLink?.published
    ? new Date(activeLink?.published).toLocaleString()
    : "";

  return (
    <Text
      style={{
        boxShadow: "-5px 0 0 white",
        paddingRight: 5,
        textAlign: "start",
        background: "white",
        display: "inline",
        fontSize: 10,
        fontWeight: "bold",
        color: "grey",
        paddingTop: 14,
        ...infoblockProps,
      }}
    >{`${authors} ${date}`}</Text>
  );
}

export async function getImages(
  activeLink: PostLink
): Promise<ImageInfoItem[]> {
  // var featureBlocks = getFeatureBlocks(activeLink);
  const featureBlocks = activeLink?.content?.blocks;
  const mediaBlocks = getMediaBlocks(featureBlocks || []);

  const gis: ImageInfoItem[] = [];
  if (!mediaBlocks?.length) {
    return gis;
  }
  for (const mb of mediaBlocks) {
    gis.push(getGalleriaItemFromBlock(mb));
  }

  return gis;
}

export function getCardImage(activeLink: PostLink, width, height) {
  const featureBlocks = activeLink?.content?.blocks;
  const mediaBlocks = getMediaBlocks(featureBlocks || []);

  if (!mediaBlocks?.length) {
    return null;
  }

  const mediaBlock = mediaBlocks[0];

  if (mediaBlock.blockType === "image") {
    const item = getGalleriaItemFromBlock(mediaBlock);

    return (
      <img
        key={"image" + activeLink?.id}
        src={item.cardImageSrc}
        alt={item.alt}
        style={{ width, height, display: "block" }}
      />
    );
  } else if (mediaBlock.blockType === "video" && mediaBlock?.asset?.url) {
    return (
      <video
        key={"image" + activeLink?.id}
        style={{ width: "100%", display: "block", padding: 0, borderRadius: 5 }}
        width={width}
        autoPlay={true}
        controls={false}
        loop={true}
      >
        <source src={mediaBlock?.asset?.url} />
      </video>
    );
  }
}

export function getFirstPageImage(activeLink: PostLink, newIndex, oldIndex) {
  if (newIndex - oldIndex === 1) {
    //Same as before do not update image
    return null;
  }

  const featureBlocks = activeLink?.content?.blocks.slice(0, newIndex);
  const mediaBlocks = getMediaBlocks(featureBlocks || []);
  if (!mediaBlocks?.length) {
    return null;
  }

  const mediaBlock = mediaBlocks[mediaBlocks.length - 1];
  const width = styles.drawWidth;

  if (mediaBlock.blockType === "image") {
    const item = getGalleriaItemFromBlock(mediaBlock);

    return (
      <img
        className={mediaBlocks.length > 1 ? "fade-in-opacity" : ""}
        key={"image" + activeLink?.id}
        src={item.cardImageSrc}
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  } else if (mediaBlock.blockType === "video" && mediaBlock?.asset?.url) {
    return (
      <video
        key={"image" + activeLink?.id}
        style={{ width: "100%", display: "block", padding: 0, borderRadius: 5 }}
        width={width}
        autoPlay={true}
        controls={false}
        loop={true}
      >
        <source src={mediaBlock?.asset?.url} />
      </video>
    );
  }
}

export function getArticleImage(activeLink: PostLink) {
  const featureBlocks = activeLink?.content?.blocks;
  const mediaBlocks = getMediaBlocks(featureBlocks || []);

  if (!mediaBlocks?.length) {
    return null;
  }

  const mediaBlock = mediaBlocks[0];
  const width = styles.drawWidth;

  if (mediaBlock.blockType === "image") {
    const item = getGalleriaItemFromBlock(mediaBlock);

    return (
      <img
        key={"image" + activeLink?.id}
        src={item.itemImageSrc}
        alt={item.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  } else if (mediaBlock.blockType === "video" && mediaBlock?.asset?.url) {
    return (
      <video
        key={"image" + activeLink?.id}
        style={{ width: "100%", display: "block", padding: 0, borderRadius: 5 }}
        width={width}
        autoPlay={true}
        controls={false}
        loop={true}
      >
        <source src={mediaBlock?.asset?.url} />
      </video>
    );
  }
}
