import { Component } from "react";
import { Link } from "../../utils/PostInternals";
import { Text, View } from "../ui";
import { getMediaBlocks } from "./utils/LinkContentUtils";
import {
  getCardImage, getExcerpt, getTitle
} from "./utils/LinkReactUtils";

interface LinkCardProps {
  activePost: any;
  activeLink: Link | undefined;
  height?: number;
  onClick: () => void;
  containerProps?: any;
  infoblockProps?: any;
}

interface LinkCardState {}

export class LinkCard extends Component<LinkCardProps, LinkCardState> {
  getInfoBlock(activeLink: Link, infoblockProps, activePost?) {
    if (!activeLink || activeLink?.hideTitle) {
      return null;
    }

    return (
      <View key={"infoblock" + activeLink?.id} style={infoblockProps?.style}>
        {/* {getLinkType(activeLink, infoblockProps)} */}
        <View style={{ margin: 0 }} />
        {getTitle(activeLink, infoblockProps)}
        <View style={{ margin: 0 }} />
        {getExcerpt(activeLink, infoblockProps)}
        <View style={{ margin: 0 }} />
        {/* {getAuthor(activeLink, infoblockProps)} */}
        <View style={{ margin: 0 }} />
        {/* {getTags(activeLink, infoblockProps, activePost)} */}
      </View>
    );
  }

  render() {
    const { activeLink,activePost, onClick } = this.props;
    const { containerProps, infoblockProps } = this.props;

    if (!activeLink) {
      return (
        <View>
          <Text>No link is selected</Text>
        </View>
      );
    }

    // var featureBlocks = getFeatureBlocks(activeLink);
    const featureBlocks = activeLink?.content?.blocks;
    const mediaBlocks = getMediaBlocks(featureBlocks || []);

    const defaultInfoBlockProps = {
      style: {
        padding: "0 20px",
        display: "inline",
        position: "absolute",
        bottom: "7%",
        zIndex: 3,
        textAlign: "start",
        width: containerProps?.style?.width
      }
    };

    const defaultContainerProps = {
      style: {
        position: "relative",
        borderRadius: 5,
        width: "100%",
        height: mediaBlocks?.length ? undefined : 200,
        background: "lightgreen",
        display: containerProps?.style?.width ? "block" : undefined,
        textAlign:"start",
        minHeight:200
      }
    };

    const containerStyle = {
      ...defaultContainerProps?.style,
      ...containerProps?.style
    };

    return (
      <View
        key="LinkCard"
        style={containerStyle}
        className={"p-shadow-2"}
        onClick={onClick}
      >
        {/* {mediaBlocks?.length?<ImageGalleria style={blockStyle} getImages={this.getImages.bind(this)}/>:null} */}
        {mediaBlocks?.length ? getCardImage(activeLink, containerStyle.width,containerStyle.height) : null}
        {this.getInfoBlock(activeLink, {
          ...defaultInfoBlockProps,
          ...infoblockProps,

        },activePost)}
      </View>
    );
  }
}
