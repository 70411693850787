import { Sidebar } from "primereact/sidebar";
import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { About } from "./components/About";
import { Account } from "./components/acount/Account";
import { Burger } from "./components/Burger";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Eula } from "./components/Eula";
import { Home } from "./components/Home";
import { LinkEdit } from "./components/links/LinkEdit";
import { Links } from "./components/links/Links";
import { Menu } from "./components/Menu";
// import { Sidebar } from "./components/PrimeReact";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsOfService } from "./components/TermsOfService";
import { View } from "./components/ui";
import styles from "./constants/Styles";
import useUser from "./hooks/UseUser";
import "./utils/InitAmplify";
import { LocationHistory, LocationMatch } from "./utils/NavUtils";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const RedirectToWebApp = ({
  match,
  history,
}: {
  match: LocationMatch;
  history: LocationHistory;
}) => {
  const matchPath = match?.path;
  const pathParts = matchPath?.split(`/`);
  const webAppIndex = pathParts?.indexOf(`webapp`);
  let path = "";
  if (webAppIndex > 0 && webAppIndex < pathParts?.length - 1) {
    path = pathParts?.slice(webAppIndex + 1)?.join("/");
  }

  console.log(`Match ${matchPath} and path ${path}`);
  const redirectUrl = isLocalhost
    ? "http://localhost:19006"
    : "https://dogcontroller.com/webapp/index.html";

  window.location.href = `${redirectUrl}`;
  return <div>Starting app</div>;
};

const AppRoutes = () => {
  const navigate = useNavigate();
  const match = new LocationMatch();
  const history = new LocationHistory(navigate);

  return (
    <Routes>
      <Route path="/" element={<Home match={match} history={history} />} />

      <Route
        path="/links/*"
        element={<Links match={match} history={history} />}
      />

      <Route
        path="/linkedit/*"
        element={<LinkEdit match={match} history={history} />}
      />
      <Route
        path="/postedit/*"
        element={<LinkEdit match={match} history={history} />}
      />
      <Route path="/account" element={<Account />} />
      <Route path="/eula" element={<Eula match={match} history={history} />} />
      <Route
        path="/privacypolicy"
        element={<PrivacyPolicy match={match} history={history} />}
      />
      <Route
        path="/terms"
        element={<TermsOfService match={match} history={history} />}
      />
      <Route
        path={"/webapp/*"}
        element={<RedirectToWebApp match={match} history={history} />}
      />

      <Route path="/about" element={<About />} />
      <Route element={<Home match={match} history={history} />} />
    </Routes>
  );
};

function App() {
  const [open, setOpen] = useState(false);
  const [drawWidth, setDrawWidth] = useState(window.innerWidth);

  const { user } = useUser();
  const menuId = "main-menu";

  const handleResize = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth !== drawWidth) {
      styles.updateInnerWidth();
      setDrawWidth(innerWidth);
    }
  };

  window.addEventListener("resize", handleResize);

  return (
    <Router>
      <ErrorBoundary>
        <View
          className="App"
          style={{
            width: styles.fullWidth,
            height: "100%",
            padding: 0,
            margin: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Burger open={open} setOpen={setOpen} />
          {/*  @ts-ignore */}
          <Sidebar visible={open} onHide={() => setOpen(false)}>
            <Menu open={open} setOpen={setOpen} id={menuId} user={user} />
          </Sidebar>

          {/*@ts-ignore */}
          <View style={{ width: styles.fullWidth }}>
            <AppRoutes />
          </View>
        </View>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
