import {
  list, ListAllInput, ListAllOutput, remove, RemoveInput
} from "aws-amplify/storage";
import { FilterMatchMode } from "primereact/api";


import awsconfig from "../../amplifyconfiguration.json";
import { AuthUtils } from "../../utils/AuthUtils";
import { FileSystemWeb as FileSystem } from "../../utils/FileSystemWeb";
import { getContentTypeAndExtensionFromUri } from "../../utils/StorageUtils";
import StringUtils from "../../utils/StringUtils";

export const cacheDir = FileSystem.getCacheDirectory();
export const S3Storage =
  "https://" +
  awsconfig.aws_user_files_s3_bucket +
  ".s3." +
  awsconfig.aws_user_files_s3_bucket_region +
  ".amazonaws.com/";

export interface FilterType {
  value: string;
  matchMode: string; /*FilterMatchMode*/

}

export enum FitlerColumns {
  name = "name",
  lastModified = "lastModified",
  size = "size",
}

export type FiltersConfig = {
  [key in FitlerColumns]?: FilterType;
};

export interface LazyParams {
  first: number;
  rows: number;
  page: number;
  sortOrder?: any;
  sortField?: string;

  filters: FiltersConfig;
}

export interface FileListing {
  name: string;
  mediaType?: string;
  key: string;
  size: number | undefined;
  parent?: string;
  parentType?: string;
  lastModified: Date | undefined;
  date?: string;
  time?: string;
}

export interface FolderListing {
  files: FileListing[];
  totalRecords: number;
  totalSize: number;
}

// This should be exported from aws
export interface ListOutputItem {
  /**
   * Key of the object
   */
  key: string;
  /**
   * Creation date of the object.
   */
  lastModified?: Date;
  /**
   * Size of the body in bytes.
   */
  size?: number;
  /**
   * An entity tag (ETag) is an opaque identifier assigned by a web server to a specific version of a resource found at
   * a URL.
   */
  eTag?: string;
}

export class FileService {
  _folderListing: FolderListing | undefined;

  constructor(private user: any) {}

  // processStorageList(results) {
  //   const filesystem = {}
  //   // https://stackoverflow.com/questions/44759750/how-can-i-create-a-nested-object-representation-of-a-folder-structure
  //   const add = (source, target, item) => {
  //     const elements = source.split("/");
  //     const element = elements.shift();
  //     if (!element) return // blank
  //     target[element] = target[element] || {__data: item}// element;
  //     if (elements.length) {
  //       target[element] = typeof target[element] === "object" ? target[element] : {};
  //       add(elements.join("/"), target[element], item);
  //     }
  //   };
  //   results.forEach(item => add(item.key, filesystem, item));
  //   return filesystem
  // }

  public get folderListing(): FolderListing | undefined {
    return this._folderListing;
  }

  processStorageList(storageList: ListAllOutput): FolderListing {
    const totalSize = storageList?.items.reduce(
      (acc, item) => acc + (item?.size ? item?.size : 0),
      0,
    );
    let result: FolderListing = {
      totalSize,
      totalRecords: storageList?.items?.length,
      files: [],
    };

    const add = (item: ListOutputItem) => {
      const elements = item.key?.split("/");
      if (!elements?.length) {
        return;
      }
      const name = elements.slice(-1)[0];
      const parent = elements?.length > 1 ? elements.slice(-2)[0] : undefined;
      const parentType =
        elements?.length > 2 ? elements.slice(-3)[0] : undefined;

      if (!item?.key) {
        return;
      }

      const { contentType } = getContentTypeAndExtensionFromUri(
        item.key,
        "data",
        "",
      );
      const key = item.key;
      const size = item.size;
      const lastModified = item.lastModified;
      const fullDate = item.lastModified;
      const date = fullDate?.toLocaleDateString();
      const time = fullDate?.toLocaleTimeString();

      let file: FileListing = {
        name,
        mediaType: contentType,
        key,
        lastModified,
        size,
        date,
        time,
        parent,
        parentType,
      };

      result.files.push(file);
    };

    storageList.items?.forEach((item) => add(item));
    return result;
  }

  async loadFiles() {
    const sub = await AuthUtils.getSub(this.user);
    const listConfig: ListAllInput = { prefix: `${sub}/` };
    const listResult = await list(listConfig);
    this._folderListing = this.processStorageList(listResult);
    return this._folderListing;
  }

  async deleteFile(fileListing: FileListing) {
    if (!this._folderListing) {
      return null;
    }

    const removeInput: RemoveInput = { key: fileListing.key };
    await remove(removeInput);

    this._folderListing.files = this._folderListing?.files?.filter(
      (x) => x.key !== fileListing.key,
    );
    this._folderListing.totalRecords -= 1;
    this._folderListing.totalSize -= fileListing.size || 0;

    return this._folderListing;
  }

  public static getDisplayValue(field: string, file: FileListing): string {
    if (field === "size") {
      return StringUtils.convertBytesOfSize(file.size || 0);
    } else if (field === "lastModified") {
      if (file.lastModified) {
        return new Date(file.lastModified).toLocaleDateString();
      } else {
        return "";
      }
    } else {
      return file[field] || "";
    }
  }

  getFiles(params: LazyParams): FolderListing | undefined {
    if (
      !this._folderListing?.files?.length ||
      !this._folderListing?.totalRecords ||
      !this._folderListing?.totalSize
    ) {
      return this._folderListing;
    }

    let folderListing = { ...this._folderListing };
    let files = folderListing?.files;

    if (params.filters) {
      for (const field in params?.filters) {
        const filter: FilterType = params?.filters[field];
        if (filter?.value?.length) {
          files = files?.filter(
            (x) =>
              FileService.getDisplayValue(field, x)?.indexOf(filter?.value) >=
              0,
          );
        }
      }
      const totalSize = files?.reduce(
        (acc, item) => acc + (item?.size ? item?.size : 0),
        0,
      );
      folderListing.totalSize = totalSize;
      folderListing.totalRecords = files?.length;
    }
    if (params.sortField?.length && params.sortOrder != null) {
      const sortByField = (a: FileListing, b: FileListing) => {
        //@ts-ignore
        const value = a[params.sortField] > b[params.sortField] ? 1 : -1;
        return (params?.sortOrder || 1) * value;
      };

      files.sort(sortByField);
    }
    if (params?.first) {
      files = files.slice(params?.first);
    }

    if (params.rows) {
      files = files.slice(0, params?.rows);
    }
    return { ...folderListing, files };
  }
}
