import React from "react";
import { Link } from "react-router-dom";
import Icons, { getIconWithBadge } from "../constants/Icons";
import { View } from "./ui";

export const Menu = ({ open, setOpen, user, ...props }) => {
  const isHidden = open ? true : false;
  return (
    <View open={open} aria-hidden={!isHidden} {...props}>
      <Link
        className="Link"
        to="/"
        onClick={() => {
          setOpen(false);
        }}
      >
        <View className="mainmenusel">
          {/* <img src="./webhome.png" alt="logo" style={{ width: '3rem' }} /> */}
          <span aria-hidden={isHidden}>
            {getIconWithBadge(Icons.home, undefined, undefined, 2)}
          </span>
          Home
        </View>
      </Link>

      <Link
        className="Link"
        to="/account"
        onClick={() => {
          setOpen(false);
        }}
      >
        <View className="mainmenusel">
          <span aria-hidden={isHidden}>
            {getIconWithBadge(Icons.account, undefined, undefined, 2)}
          </span>
          Account
        </View>
      </Link>

      <Link
        className="Link"
        to="/links"
        onClick={() => {
          // React rpiter 6

          setOpen(false);
        }}
      >
        <View className="mainmenusel">
          <span aria-hidden={isHidden}>
            {getIconWithBadge(Icons.link, undefined, undefined, 2)}
          </span>
          Links
        </View>
      </Link>

      {user ? (
        <Link
          className="Link"
          to="/webapp"
          onClick={() => {
            setOpen(false);
          }}
        >
          <View className="mainmenusel">
            <span aria-hidden={isHidden}>
              {getIconWithBadge(Icons.web, undefined, undefined, 2)}
            </span>
            Web app
          </View>
        </Link>
      ) : null}
    </View>
  );
};
