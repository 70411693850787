// No imports being imported by serverside code
// tslint:disable-next-line
const Buffer = require("buffer").Buffer;

/// Same as in filtersort utils
function distinct(arr1: string[]): string[] {
  return Array.from(new Set(arr1));
}

export function getCircularReplacer() {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
}

export function fixMonkeyJSON(ds: string) {
  ds = ds.replace(/(\w+):/gi, " '$1':");
  ds = ds.replace(/'/gi, '"');
  ds = ds.replace(/, \}/gi, "}");
  return ds;
}

export class Serializer {
  public static serialize<T>(obj: T | null | undefined): string | undefined {
    if (obj == null) {
      return undefined;
    }

    const ser = JSON.stringify(obj);
    return Buffer.from(ser).toString("base64");
  }

  public static deserialize<T>(s: string | undefined | null): T | undefined {
    if (s == null || s.length === 0) {
      return undefined;
    }

    const des = Buffer.from(s, "base64").toString("utf8");
    return JSON.parse(des);
  }
}

// Will only copy first level of objects (rest will be same references)
export function copyObj<T>(obj: T): T {
  if (obj == null) {
    // @ts-ignore
    return null;
  }

  if (Array.isArray(obj)) {
    // @ts-ignore
    return [...obj];
  } else if (typeof obj === "object") {
    return { ...obj };
  } else {
    return obj;
  }

  // return JSON.parse(JSON.stringify(obj)) as T;
  // Please note issues with getCircularReplacer
}

// Copies everything, but issues with getCircularReplacer
export function jsonCopyObj<T>(obj: T): T {
  if (obj == null) {
    // @ts-ignore
    return null;
  }

  return JSON.parse(JSON.stringify(obj)) as T;
  // Please note issues with getCircularReplacer
}

// Need to copy the object before removing titles? Obj will be changed
export function removeTitles(obj: any, skipTitles: string[]) {
  for (const skipTitle of skipTitles) {
    const subTitles = skipTitle?.split(".");
    if (subTitles?.length > 1) {
      const subobj = obj[subTitles[0]];
      if (subobj) {
        removeTitles(subobj, [subTitles.slice(1).join(".")]);
      }
    } else if (subTitles?.length === 1 && subTitles[0]?.length) {
      obj[subTitles[0]] = undefined;
    }
  }
}

export function strEqualObj(obj1, obj2): boolean {
  try {
    return (
      JSON.stringify(obj1, getCircularReplacer()) ===
      JSON.stringify(obj2, getCircularReplacer())
    );
  } catch {
    return false;
  }
}

export function strObjDiff(obj1, obj2): string | null {
  try {
    const allAttribs = distinct([...Object.keys(obj1), ...Object.keys(obj2)]);
    let retVal: string = "";
    if (allAttribs?.length) {
      for (const prop of allAttribs) {
        let p1, p2;
        if (obj1) {
          p1 = obj1[prop];
        }
        if (obj2) {
          p2 = obj2[prop];
        }
        if (p1 !== p2) {
          retVal = retVal + `o1.${prop}=${p1} !== o2.${prop}=${p2}`;
        }
      }
    }
    return retVal.length ? retVal : null;
  } catch (ex) {
    return null;
  }
}

export function existingSameUpdate(update, existing): boolean {
  try {
    const allAttribs = Object.keys(update);
    if (allAttribs?.length) {
      for (const prop of allAttribs) {
        let p1, p2;
        if (update) {
          p1 = update[prop];
        }
        if (existing) {
          p2 = existing[prop];
        }
        if (p1 !== p2) {
          return false;
        }
      }
    }
    return true;
  } catch (ex) {
    return false;
  }
}

export function importantBOUpdate(mergedItem, foundItem): boolean {
  if (foundItem == null && mergedItem) {
    return true;
  }
  if (mergedItem == null && foundItem) {
    return true;
  }
  if (foundItem == null && mergedItem == null) {
    return false;
  }

  const importantUpdate: boolean = false;
  try {
    for (const prop in mergedItem) {
      if (prop === "updatedAt") {
        continue;
      }
      if (mergedItem[prop] !== foundItem[prop]) {
        if (Array.isArray(mergedItem[prop])) {
          if (!isArrayEqual(mergedItem[prop], foundItem[prop])) {
            // If array differs
            return true;
          }
        } else {
          // if other object differs
          return true;
        }
      }
    }
  } catch {
    return true;
  }
  return importantUpdate;
}

export function isArrayEqual(a1, a2) {
  if (a1?.length !== a2?.length) {
    return false;
  }
  let i = a1.length;
  while (i--) {
    if (a1[i] !== a2[i]) return false;
  }
  return true;
}
