import React from "react";
import { Span, View } from "./ui";

export const Burger = ({ open, setOpen, ...props }) => {
  const burgersliceStyle = {
    width: "2rem",
    height: "0.25rem",
    borderRadius: 10,
    transition: "all 0.3s linear",
    position: "relative",
    transformOrigin: 1,
    background: "darkorange"
  };

  const burgerStyle = {
    position: "fixed",
    top: "1rem",
    left: "1rem",
    width: "2rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "2rem",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    zIndex: 10
  };

  const isExpanded = open ? true : false;

  return (
    <View
      style={burgerStyle}
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      onClick={() => setOpen(true)}
    >
      <Span style={burgersliceStyle} />
      <Span style={burgersliceStyle} />
      <Span style={burgersliceStyle} />
    </View>
  );
};
