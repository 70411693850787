import React from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './App';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/rhea/theme.css'
import 'primeflex/primeflex.css';
import './theme.scss'
import './index.scss';
import './App.scss';


const container = document.getElementById('root') ;

// @ts-ignore
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

