import {
  fetchAuthSession,
  getCurrentUser,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";
import AppLogger from "./AppLogger";

export type IdpProviders = "Cognito" | "SignInWithApple" | "Google";

export enum CognitoHostedUIIdentityProvider {
  Cognito = "COGNITO",
  Google = "Google",
  Facebook = "Facebook",
  Amazon = "LoginWithAmazon",
  Apple = "SignInWithApple",
}

export class AuthUtils {
  static _oauthError: any = null;

  static get oauthError() {
    return AuthUtils._oauthError;
  }

  public static getUserName(userData) {
    return userData?.username;
  }

  public static getProvider(userData): IdpProviders {
    const userName = userData?.username;
    const aPrefix = "SignInWithApple_";
    const gPrefix = "Google_";
    if (userName?.indexOf(aPrefix) >= 0) {
      return "SignInWithApple";
    } else if (userName?.indexOf(gPrefix) >= 0) {
      return "Google";
    }
    return "Cognito";
  }

  public static async getSub(userData) {
    try {
      let userName = userData?.username as string;

      const { username: currentUsername, signInDetails } =
        await getCurrentUser();
      if (currentUsername) {
        userName = currentUsername;
      }
      return userName;
    } catch (ex) {
      AppLogger.info(`Error in getUserEmail ${ex}`);
      return null;
    }
  }

  public static getUserPoolId(userData) {
    return userData?.pool?.userPoolId;
  }

  public static isUserAdmin(user) {
    if (!user?.signInUserSession?.accessToken?.payload) {
      return false;
    }
    const userGroups =
      user?.signInUserSession?.accessToken?.payload["cognito:groups"];
    return userGroups?.find((x) => x === "Admin") != null;
  }

  public static async getUser(): Promise<any> {
    try {
      const userData = await getCurrentUser();
      return userData;
    } catch {
      AppLogger.debug("Not signed in");
      return null;
    }
  }

  public static async getUserEmail(userData) {
    // if (userData?.attributes?.sub?.length) {
    //   // Signing in with Apple gives SignInWithApp name
    //   return userData?.attributes?.sub;
    // }
    try {
      let email = this.emailFromUser(userData);
      if (email?.length) {
        return email;
      }

      const session = await fetchAuthSession();
      const idToken = session?.tokens?.idToken;
      email = idToken?.payload?.email;
      return email;
    } catch (ex) {
      AppLogger.info(`Error in getUserEmail ${ex}`);
      return null;
    }
  }

  private static getIdentities(oAuthUser) {
    if (
      oAuthUser?.attributes?.identities &&
      typeof oAuthUser?.attributes?.identities === "string"
    ) {
      return JSON.parse(oAuthUser?.attributes?.identities);
    }
    return oAuthUser?.attributes?.identities;
  }

  public static getProviderName(oAuthUser) {
    const identities = this.getIdentities(oAuthUser);
    let providerName = identities?.length ? identities[0].providerName : null;
    if (!providerName) {
      providerName = oAuthUser?.attributes?.provider;
    }

    return providerName;
  }

  public static emailFromUser(user) {
    if (!user) {
      return undefined;
    }

    return user?.attributes?.email;
  }

  public static async signOut(): Promise<any> {
    try {
      await signOut();
    } catch {
      AppLogger.debug("Failed to sign out");
      return null;
    }
  }

  public static async federatedSignIn(): Promise<any> {
    try {
      return signInWithRedirect();
    } catch {
      AppLogger.debug("Failed to sign out");
      return null;
    }
  }

  public static async appleWebSignIn(): Promise<any> {
    try {
      return signInWithRedirect({
        provider:
          CognitoHostedUIIdentityProvider[
            CognitoHostedUIIdentityProvider.Apple
          ],
      });
    } catch {
      AppLogger.debug("Failed to sign out");
      return null;
    }
  }

  public static async googleWebSignIn(): Promise<any> {
    try {
      return signInWithRedirect({
        provider:
          CognitoHostedUIIdentityProvider[
            CognitoHostedUIIdentityProvider.Google
          ],
      });
    } catch {
      AppLogger.debug("Failed to sign out");
      return null;
    }
  }
}
