import { Component } from "react";
import styles from "../constants/Styles";
import AppLogger from "../utils/AppLogger";
import { LocationMatch, LocationHistory } from "../utils/NavUtils";
import { Link as PostLink } from "../utils/PostInternals";
import { LinkArticle } from "./links/LinkArticle";
import { Spinner, View } from "./ui";

export interface TermsOfServiceProps {
  match: LocationMatch;
  history: LocationHistory;
}

export interface TermsOfServiceState {
  loading: boolean;

  links: PostLink[];
  lastError: any;
}

export class TermsOfService extends Component<TermsOfServiceProps, TermsOfServiceState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      links: [],
      lastError: null
    };
  }

  async componentDidMount() {
    try {
      const links = (await this.listTermsOfServiceLinks()) || [];

      this.setState({ links, loading: false });
    } catch (err) {
      AppLogger.error(`Error in links ${err}`, null);
      this.setState({ loading: false });
    }
  }
  async listTermsOfServiceLinks() {
    try {
      const termsofservice = await fetch(`firstpage/terms.json`).then(r => r.json());

      return [termsofservice] as PostLink[];
    } catch (err) {
      AppLogger.error(`Error in listFirstPageLinks ${err}`);
    }
  }

  render() {
    const { loading, links } = this.state;
    const { history, match } = this.props;


    const containerStyle = {
      display:"flex",
      position: "relative",
      justifyContent: "center",
      textAlign: "center"
    };


    if (loading) {
      return <Spinner style={containerStyle} />;
    }


    return (
      <View key="termsofservice" style={{ margin: styles.margin, paddingTop:50 }}>
        <LinkArticle
          containerStyle={{height:50}}
          activeLink={links[0]}
          history={history}
          match={match}
        />
      </View>
    );
  }
}
