import { TabMenu } from "primereact/tabmenu";
import { Component } from "react";
import styles from "../../constants/Styles";
import { CreatePostInput, UpdatePostInput } from "../../API";
import { Post } from "../../model/models";
import AppLogger from "../../utils/AppLogger";
import { AuthUtils } from "../../utils/AuthUtils";
import { IWithDBProperties } from "../../utils/ClassUtils";
import { isLocalhost } from "../../utils/InitAmplify";
import { LocationHistory, LocationMatch } from "../../utils/NavUtils";
import { Author, Link, PostInternals } from "../../utils/PostInternals";
import { Serializer } from "../../utils/SerializationUtils";
import StringUtils from "../../utils/StringUtils";
import { View } from "../ui";
import { LinkContent } from "./LinkContent";
import { LinkJSON } from "./LinkJSON";
import { LinkOverview } from "./LinkOverview";
import { LinkPreview } from "./LinkPreview";
import { LinkSave } from "./LinkSave";
import { createAuthor, uploadPostMedia } from "./utils/LinkContentUtils";
import {
  createDefaultPost,
  createPrivatePost,
  getPrivatePost,
  getProfileData,
  updatePrivatePost,
  updatePublicLinkData
} from "./utils/LinkGraphQLUtils";

export interface LinkEditProps {

  match: LocationMatch;
  history: LocationHistory;
}
export interface LinkEditState {
  activeIndex: number; // MenuItem,
  linkId: string | undefined;
  postId: string | undefined;
  loading: boolean;
  activePost:
    | IWithDBProperties<Post>
    | CreatePostInput
    | UpdatePostInput
    | undefined;
  activeLink: Link | undefined;
  readonly: boolean;
  tabs: any[];
  dirty: boolean;
  user: any;
  author: Author | undefined;
}

export class LinkEdit extends Component<LinkEditProps, LinkEditState> {
  constructor(props) {
    super(props);

    const id = props?.match?.id;
    let linkId: string | undefined;
    let postId: string | undefined;
    if (props.match?.path?.indexOf("linkedit") >= 0) {
      linkId = id;
    } else {
      postId = id;
    }

    const tabs: any[] = [
      {
        label: styles.isDesktop ? "Overview" : "",
        icon: "pi pi-fw pi-directions"
      }
    ];

    this.state = {
      activeIndex: 0,
      linkId,
      postId,
      loading: true,
      activePost: undefined,
      readonly: true,
      tabs,
      dirty: false,
      user: null,
      activeLink: undefined,
      author: undefined
    };
  }

  async componentDidMount() {
    try {
      const { postId } = this.state;
      const user = await AuthUtils.getUser();
      if (!user) {
        return;
      }

      let post: any = null;
      const profile = await getProfileData(user);
      const author = profile ? createAuthor(profile) : undefined;

      if (!postId || postId === "newlink") {
        post = await createDefaultPost(author);
      } else {
        post = await getPrivatePost(postId);
      }
      const activeLink = Serializer.deserialize<PostInternals>(post?.internals)
        ?.link;
      this.setState({ activePost: post, user, activeLink, author });
    } catch (err) {
      AppLogger.error("LinksEditor", err);
    } finally {
      this.setState({ loading: false });
    }
  }

  onUpdateActivePost(post: any) {
    this.setState({ activePost: post, dirty: true });
  }

  onUpdateActiveLink(link: Link) {
    const { activePost } = this.state;
    let storedInternals = Serializer.deserialize<PostInternals>(
      activePost?.internals
    );
    let postType = activePost?.postType;

    if (storedInternals) {
      if(storedInternals.link?.linkType !== link.linkType){
        postType = link.linkType;
      }
      storedInternals.link = link;
    }
    const internals = Serializer.serialize(storedInternals)

    //@ts-ignore
    this.setState({ activeLink: link, dirty: true, activePost:{...activePost,internals, postType} });
  }

  updateUrlToSaved(post) {
    const { match, history } = this.props;
    const location = match?.path;
    if (!location?.startsWith("/postedit/newlink" || !post?.id?.length)) {
      return;
    }

    history.replace(`/postedit/${post.id}`);
  }

  async onSaveActivePost(post: any, link: Link) {
    try {
      const { user } = this.state;
      const userName = AuthUtils.getUserName(user);
      const userSub = await AuthUtils.getSub(user);

      if (!post || !userSub || !userName) {
        return;
      }

      let createPost = false;
      if (!post?.id?.length) {
        post.id = StringUtils.generateID(userName);
        createPost = true;
      }

      await uploadPostMedia(link, post,  userSub, userName);

      const postInternals =
        Serializer.deserialize<PostInternals>(post.internals) ||
        new PostInternals();
      postInternals.link = link;
      post.internals = Serializer.serialize(postInternals);
      this.setState({ activePost: post, dirty: false, loading: true });
      if (createPost) {
        await createPrivatePost(post);
        this.updateUrlToSaved(post);
      } else {
        await updatePrivatePost(post);
      }
      this.setState({ activeLink: link, activePost: post });
    } catch (err) {
      AppLogger.error(`Error onSavePost ${JSON.stringify(err)}`);
    } finally {
      this.setState({ loading: false });
    }
  }

  async onPublishLink(link: Link) {
    try {
      const activeLink = await updatePublicLinkData(link);

      this.setState({ activeLink, dirty: false, loading: true });
    } catch (ex) {
      AppLogger.error("onPublishLink", ex);
    } finally {
      this.setState({ loading: false });
    }
  }

  getTabs(user, dirty): any[] {
    const tabs = [
      { label: "Overview", icon: "pi pi-fw pi-list" },
      { label: "Content", icon: "pi pi-fw pi-pencil" },
      { label: "Preview", icon: "pi pi-fw pi-id-card" },
      {
        label: "Save",
        icon: "pi pi-fw pi-save",
        disabled: !dirty && !AuthUtils.isUserAdmin(user)
      }
    ];

    if (AuthUtils.isUserAdmin(user) || isLocalhost) {
      tabs.push({ label: "Json", icon: "pi pi-fw pi-sort-amount-down-alt" });
    }
    return tabs;
  }

  render() {
    const { history, match } = this.props;
    const {
      activeIndex,
      dirty,
      activePost,
      activeLink,
      user,
      author
    } = this.state;

    const tabs = this.getTabs(user, dirty);
    let displayTabs = [...tabs];
    if (styles.isPhone) {
      displayTabs = displayTabs?.map(x => {
        return { ...x, label: "" };
      });
    }

    const menu = (
      <TabMenu
        model={displayTabs}
        style={{
          display: "flex",
          overflow: "hidden",
          justifyContent: "flex-end",
          marginTop: styles.margin,
          maxWidth:styles.maxEditorWidth
        }}
        activeIndex={this.state.activeIndex}
        onTabChange={e =>
          this.setState({ activeIndex: displayTabs.indexOf(e.value) })
        }
      />
    );
    let content: any = null;
    if (activeIndex === 0) {
      content = (
        <LinkOverview
          activePost={activePost}
          activeLink={activeLink}
          user={user}
          onChangeLink={this.onUpdateActiveLink.bind(this)}
        />
      );
    } else if (activeIndex === 1) {
      content = (
        <LinkContent
          activePost={activePost}
          activeLink={activeLink}
          user={user}
          onChangeLink={this.onUpdateActiveLink.bind(this)}
          author={author}
        />
      );
    } else if (activeIndex === 2) {
      content = (
        <LinkPreview
          activePost={activePost}
          activeLink={activeLink}
          user={user}
          history={history}
          match={match}
        />
      );
    } else if (activeIndex === 3) {
      content = (
        <LinkSave
          activePost={activePost}
          activeLink={activeLink}
          user={user}
          onChangePost={this.onUpdateActivePost.bind(this)}
          onChangeLink={this.onUpdateActiveLink.bind(this)}
          onSavePost={this.onSaveActivePost.bind(this)}
          onPublishLink={this.onPublishLink.bind(this)}
          dirty={dirty}
        />
      );
    } else if (activeIndex === 4) {
      content = (
        <LinkJSON
          activeLink={activeLink}
          user={user}
          history={history}
          match={match}
        />
      );
    }

    return (
      <View key="LinkEdit" style={{  justifyContent: "center", maxWidth:styles.maxEditorWidth }}>
        {menu}
        <View style={{}}>
          {styles?.isPhone ? (
            <h3 style={{ margin: 0 }}>{tabs[activeIndex]?.label}</h3>
          ) : (
            <View />
          )}
          {content}
        </View>
      </View>
    );
  }
}
