export const ZeroDate = "2020-01-01T00:00:00.000Z";

export function NowDate(nowDate?: Date) {
  if (!nowDate) {
    nowDate = new Date(Date.now());
  }
  return nowDate.toISOString();
}

export const MILLISECONDS_IN_ONE_YEAR = 31536000000;
export const MILLISECONDS_IN_ONE_MONTH = 86400000;
export const MILLISECONDS_IN_ONE_WEEK = 7* 24 * 60 * 60 * 1000;
export const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;
export const MILLISECONDS_IN_ONE_HOUR =  60 * 60 * 1000;
export const MILLISECONDS_IN_ONE_MINUTE =  60 * 1000;
export const MILLISECONDS_IN_ONE_SECOND =   1000;


export const DAYS_IN_WEEK = 7;

export const MONTH_LABELS = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec"
};
