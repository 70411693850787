import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React from "react";
import Icons, { getIconElement } from "../../constants/Icons";
import styles from "../../constants/Styles";
import AppLogger from "../../utils/AppLogger";
import StringUtils from "../../utils/StringUtils";
import { getUri } from "../links/utils/LinkContentUtils";
import { DataTable } from "../PrimeReact";
import { Button } from "../ui";
import {
  FileListing,
  FileService,
  FiltersConfig,
  LazyParams
} from "./FileService";


export interface FileExplorerProps {
  fileService: FileService;
}

export interface FileExplorerState {
  loading: boolean;
  totalRecords: number;
  totalSize: number;
  files: FileListing[] | undefined;
  lazyParams: LazyParams;
  overlay: any;
}

export class FileExplorer extends React.Component<
  FileExplorerProps,
  FileExplorerState
> {
  toast: Toast | null = null;

  constructor(props) {
    super(props);

    const filters: FiltersConfig = {
      name: { value: "", matchMode: "contains" },
      lastModified: { value: "", matchMode: "contains" },

      size: { value: "", matchMode: "contains" },
    };

    const lazyParams: LazyParams = {
      first: 0,
      rows: 20,
      page: 1,

      filters,
    };

    this.state = {
      loading: true,
      totalRecords: 0,
      totalSize: 0,
      files: undefined,
      lazyParams,
      overlay: undefined,
    };
  }

  async componentDidMount() {
    const { fileService } = this.props;
    let folderListing = fileService?._folderListing;
    if (!folderListing) {
      folderListing = await fileService.loadFiles();
    }
    this.setState({ loading: false, ...folderListing });
  }

  loadLazyData(lazyParams) {
    const { fileService } = this.props;
    try {
      const data = fileService.getFiles(lazyParams);

      const { totalRecords, totalSize, files } = data || {
        totalRecords: 0,
        totalSize: 0,
        files: [],
      };
      this.setState({ totalRecords, totalSize, files, lazyParams });
    } catch (ex) {
      AppLogger.error(`Error loading files ${ex}`);
    }
  }

  onPage(event) {
    let { lazyParams } = this.state;
    lazyParams = { ...lazyParams, ...event };
    this.loadLazyData(lazyParams);
  }
  onSort(event) {
    let { lazyParams } = this.state;
    lazyParams = { ...lazyParams, ...event };
    this.loadLazyData(lazyParams);
  }

  onFilter(event) {
    let { lazyParams } = this.state;
    lazyParams["first"] = 0;
    lazyParams = { ...lazyParams, ...event };
    this.loadLazyData(lazyParams);
  }

  async acceptDelete(rowData: FileListing) {
    const { fileService } = this.props;
    const fileResult = await fileService.deleteFile(rowData);
    if (fileResult) {
      this.loadLazyData(this.state.lazyParams);
      this.toast?.show({
        severity: "info",
        summary: "Confirmed",
        detail: "File deleted",
        life: 3000,
      });
    } else {
      this.toast?.show({
        severity: "warn",
        summary: "Operation failed",
        detail: `Failed to delete ${rowData.name}`,
        life: 3000,
      });
    }
  }

  rejectDelete() {
    this.toast?.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You did not delete!",
      life: 3000,
    });
  }

  onDeleteFile(rowData: FileListing) {
    confirmDialog({
      message: `Are you sure you want to delete ${rowData.name}?`,
      header: "Delete file",
      icon: "pi pi-exclamation-triangle",
      accept: this.acceptDelete.bind(this, rowData),
      reject: this.rejectDelete.bind(this),
    });
  }

  onInfoFile(rowData: FileListing) {
    confirmDialog({
      style: { whiteSpace: "pre-line" },
      message: `Name: ${rowData.name}
      Size: ${rowData.size}
      Date: ${rowData.date}
      Time: ${rowData.time}
      Parent: ${rowData.parentType}
      ParentId: ${rowData.parent}
      Type: ${rowData.mediaType} `,
      header: "File info",
      icon: "pi pi-info-circle",
      footer: <div></div>,
    });
  }

  render() {
    const { totalRecords, files, loading, lazyParams, totalSize } = this.state;

    const sizeAsBody = (rowData) => {
      return (
        <React.Fragment>
          <span>{FileService.getDisplayValue("size", rowData)} </span>
        </React.Fragment>
      );
    };

    const dateAsBody = (rowData) => {
      return (
        <React.Fragment>
          <span>{FileService.getDisplayValue("lastModified", rowData)} </span>
        </React.Fragment>
      );
    };

    const deleteAsBody = (rowData) => {
      return (
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              style={{ flex: 1, marginRight: styles.margin }}
              onPress={this.onInfoFile.bind(this, rowData)}
              leftIcon={getIconElement(Icons.info)}
            />

            <Button
              style={{ flex: 1 }}
              onPress={this.onDeleteFile.bind(this, rowData)}
              leftIcon={getIconElement(Icons.trash)}
            />
          </div>
        </React.Fragment>
      );
    };

    const nameAsBody = (rowData: FileListing) => {
      const level = "public";
      const uri = getUri(`${level}/${rowData?.key}`);
      return (
        <React.Fragment>
          <a
            key={rowData?.key}
            href={uri}
            rel={"noopener noreferrer"}
            target={"_blank"}
          >
            {FileService.getDisplayValue("name", rowData)}
          </a>
        </React.Fragment>
      );
    };

    return (
      <div>
        <Toast ref={(ref) => (this.toast = ref)} />
        <div className="card">
          {`Number of files: ${totalRecords} Size: ${StringUtils.convertBytesOfSize(
            totalSize
          )}`}
          {/* @ts-ignore */}
          <DataTable
            value={files}
            lazy
            filterDelay={100}
            filterDisplay="row"
            responsiveLayout="scroll"
            dataKey="id"
            paginator
            first={lazyParams.first}
            rows={20}
            totalRecords={totalRecords}
            onPage={this.onPage.bind(this)}
            onSort={this.onSort.bind(this)}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onFilter={this.onFilter.bind(this)}
            filters={lazyParams.filters}
            loading={loading}
          >
            <Column
              field="name"
              header="Name"
              sortable
              filter
              filterPlaceholder="Search by name"
              body={nameAsBody}
            />
            <Column
              field="lastModified"
              header="Last modified"
              sortable
              filter
              filterPlaceholder="Search by date"
              body={dateAsBody}
            />

            <Column
              field="size"
              header="Size"
              body={sizeAsBody}
              sortable
              filter
              filterPlaceholder="Search by size"
            />
            <Column body={deleteAsBody} />
          </DataTable>
        </div>
      </div>
    );
  }
}
