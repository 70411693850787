import React, { PropsWithChildren } from "react";
import {
  Sidebar as PRSidebar,
  SidebarProps as PRSidebarProps,
} from "primereact/sidebar";
import {
  Accordion as PRAccordion,
  AccordionProps as PRAccordionProps,
  AccordionTab as PRAccordionTab,
  AccordionTabProps as PRAccordionTabProps,
} from "primereact/accordion";
import {
  DataTable as PRDataTable,
  DataTableProps as PRDataTableProps,
} from "primereact/datatable";
import {
  Dialog as PRDialog,
  DialogProps as PRDialogProps,
} from "primereact/dialog";
import {
  Column as PRColumn,
  ColumnProps as PRColumnProps,
} from "primereact/column";

export type SidebarProps = PropsWithChildren<PRSidebarProps>;
export const Sidebar = (props: SidebarProps): JSX.Element => {
  return <PRSidebar {...props} />;
};

export type AccordionProps = PropsWithChildren<PRAccordionProps>;
export const Accordion = (props: AccordionProps): JSX.Element => {
  return <PRAccordion {...props} />;
};

export type AccordionTabProps = PropsWithChildren<PRAccordionTabProps>;
export const AccordionTab = (props: AccordionTabProps): JSX.Element => {
  return <PRAccordionTab {...props} />;
};

export type DataTableProps = PropsWithChildren<any>;
export const DataTable = (props: DataTableProps): JSX.Element => {
  // @ts-ignore
  return <PRDataTable {...props} />;
};

export type ColumnProps = PropsWithChildren<PRColumnProps>;
export const Column = (props: ColumnProps): JSX.Element => {
  // @ts-ignore
  return <PRColumn {...props} />;
};

export type DialogProps = PropsWithChildren<PRDialogProps>;
export const Dialog = (props: DialogProps): JSX.Element => {
  return <PRDialog {...props} />;
};
