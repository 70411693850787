import { Editor } from "primereact/editor";
import * as Quill from "quill";
import { Component } from "react";
import styles from "../../constants/Styles";
import { AuthUtils } from "../../utils/AuthUtils";
import { getBOUserRightsFromBO } from "../../utils/BOUtils";
import { Author, Link } from "../../utils/PostInternals";
import { View } from "../ui";
import {
  compareBlocks,
  contentBlocks2Hmtl,
  html2ContentBlocks
} from "./utils/LinkContentUtils";

interface LinkContentProps {
  activePost: any;
  activeLink: Link | undefined;
  user: any;
  author: Author | undefined;
  onChangeLink: (Link) => void;
}

interface LinkContentState {
  // activeIndex: number[];
  // creators: Author[];
}

export class LinkContent extends Component<LinkContentProps, LinkContentState> {
  editor: any;

  constructor(props) {
    super(props);

    // @ts-ignore
    this.state = {
      activeIndex: [0]
    };
  }
  imageHandler() {
    const range = this.editor.quill.getSelection();
    const value = prompt("What is the image URL");
    if (value) {
      this.editor.quill.insertEmbed(
        range.index,
        "image",
        value,
        Quill.sources.User
      );
    }
  }

  onUpdateContentText(val) {
    const { activeLink, author, onChangeLink } = this.props;
    if (!activeLink || !author) {
      return;
    }
    const content = activeLink?.content;
    const newBlocks = html2ContentBlocks(activeLink, val, author);
    if (compareBlocks(content?.blocks, newBlocks)) {
      return;
    }

    onChangeLink({ ...activeLink, content: { ...content, blocks: newBlocks } });
  }

  render() {
    const { activeLink, activePost, user } = this.props;
    const userId = AuthUtils.getUserName(user);
    const boUserRights = getBOUserRightsFromBO(activePost, userId);
    const content = activeLink?.content;
    const contentElement = contentBlocks2Hmtl(content?.blocks || []) || "";

    const header = (
      <span className="ql-formats">
        <select className="ql-font">
          <option value="">Medium</option>
          <option value="serif">Small</option>
          <option value="monospace">Large</option>
        </select>

        {/* <button className="ql-underline" aria-label="Underline"></button> */}
        <button className="ql-link" aria-label="link"></button>
        <button className="ql-header" aria-label="header">
          H
        </button>
        <button className="ql-blockquote" aria-label="blockquote"></button>
        <button
          type="button"
          className="ql-list"
          value="ordered"
          aria-label="Ordered List"
        ></button>
        <button
          type="button"
          className="ql-list"
          value="bullet"
          aria-label="Unordered List"
        ></button>

        <button className="ql-image" aria-label="image"></button>
        <button className="ql-video" aria-label="video"></button>
      </span>
    );

    const editor = (
      <div>
        <Editor
          ref={el => {
            if (this.editor == null) {
              // @ts-ignore
              const quill = el?.getQuill();
              const toolbar = quill?.getModule("toolbar");
              if (toolbar) {
                toolbar.handlers = this.imageHandler.bind(this);
              }
              this.editor = el;
            }
          }}
          style={{ height: window.innerHeight - 250 }}
          value={contentElement}
          onTextChange={e => this.onUpdateContentText(e.htmlValue)}
          headerTemplate={header}
          readOnly={!boUserRights.canUpdate}
        />
      </div>
    );

    return (
      <View key="LinkOverview" style={{ margin: styles.margin }}>
        {editor}
      </View>
    );
  }
}
