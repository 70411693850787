import { Subject } from 'rxjs';


const defaultConfig = {
  severity: "info",
  transport: "console",
  transportOptions: {
    color: "ansi", // custom option that color consoleTransport logs
  },
  levels: {
    verbose: 0,
    debug: 1,
    info: 2,
    warn: 3,
    error:4,
    fatal:5
  },
  async: true,
  dateFormat: "time",
  printLevel: true,
  printDate: true,
  enabled: true,
};


export enum LogLevels {
  FATAL = "FATAL",
  ERROR = "ERROR",
  WARN = "WARN",
  INFO = "INFO",
  DEBUG = "DEBUG",
  VERBOSE = "VERBOSE"
}

export function logLevelToBadgeStatus(level: LogLevels) {
  switch (level) {
    case LogLevels.VERBOSE: return "primary"
    case LogLevels.DEBUG: return "primary"
    case LogLevels.INFO: return "success"
    case LogLevels.WARN: return "warning"
    case LogLevels.ERROR: return "error"
    case LogLevels.FATAL: return "error"
    default: return "primary"
  }
}

export class LogEntry {
  public loggedAt: number;

  constructor(
    public level: LogLevels,
    public message: string,
    public error?: any
  ) {
    this.loggedAt = Date.now();
  }
}

export function reverseSortLoggedAt(a: LogEntry, b: LogEntry) {
  return a == null || b == null ? 0 : a.loggedAt < b.loggedAt ? 1 : -1
}

const MAX_LOG_IN_MEMORY = 500;

export class Logger {
  public static createLogger(config:any){
    return new Logger(config);
  }
  private constructor(private config:any){
    if(!config){
      this.config = defaultConfig;
    }
  }

  private writeString(logLevel:LogLevels,message:string){
    if(this?.config?.logLevel && logLevel < this?.config?.logLevel ){
      return;
    }
    // tslint:disable-next-line
    console.log(message)

  }

  public verbose(message){
    this.writeString(LogLevels.VERBOSE,message)
  }

  public debug(message){
    this.writeString(LogLevels.DEBUG,message)
  }

  public warn(message){
    this.writeString(LogLevels.WARN,message)
  }

  public info(message, err?){
    if(err){
      message = `${message} error:${err}`
    }
    this.writeString(LogLevels.INFO,message)
  }

  public error(message){
    this.writeString(LogLevels.ERROR,message)
  }


}



export default class AppLogger {

  // @ts-ignore
  private static _logger = Logger.createLogger(defaultConfig);
  private static _logEntries: LogEntry[] = [];
  private static _logSubject = new Subject<LogEntry|undefined>()

  public static get logEntries() {
    return this._logEntries;
  }

  public static get logSubject() {
    return this._logSubject;
  }

  public static verbose(m: string) {
    this._logger.verbose(m)
    this.addMessage(m, LogLevels.VERBOSE)
  }


  public static debug(m: string) {
    this._logger.debug(m)
    this.addMessage(m, LogLevels.DEBUG)
  }

  public static info(m: string, err?) {
    this._logger.info(m, err)
    this.addMessage(m, LogLevels.INFO)
  }

  public static warn(m: string) {
    this._logger.warn(m)
    this.addMessage(m, LogLevels.WARN)
  }

  public static error(m: string, e?: any) {
    this._logger.error(m +` error: ${JSON.stringify(e)}` )
    this.addMessage(m, LogLevels.ERROR, e)
  }

  private static addMessage(m: string, level: LogLevels, e?: any) {
    if (this._logEntries.length >= MAX_LOG_IN_MEMORY) {
      this._logEntries.pop();
    }
    const l = new LogEntry(level, m, e);
    this._logEntries.unshift(l)
    this._logSubject.next(l)
  }

  public static clear() {
    this._logEntries = [];
    this._logSubject.next(undefined)
  }

}


