import { generateClient } from "aws-amplify/api";

import {
  CreatePostInput,
  Post,
  Profile,
  PublicLink,
  UpdatePostInput,
} from "../../../API";
import {
  createPost,
  updatePost,
  updatePublicLink,
} from "../../../model/mutations";
import {
  getLoggedInEmail,
  getPost,
  getPublicLink,
  listPosts,
  listProfiles,
  listPublicLinks,
} from "../../../model/queries";
import AppLogger from "../../../utils/AppLogger";
import { AuthUtils } from "../../../utils/AuthUtils";
import {
  IWithDBProperties,
  undefinedDBProperties,
} from "../../../utils/ClassUtils";
import { Link, PostInternals } from "../../../utils/PostInternals";
import { Serializer } from "../../../utils/SerializationUtils";
import { readLinkJSON } from "./S3FileUtils";

export async function getLoggedInEmailFromAPI() {
  const graphqlClient = generateClient();
  const result = await graphqlClient.graphql({
    query: getLoggedInEmail,
    variables: {},
  });
  if (!result?.data?.getLoggedInEmail) {
    return undefined;
  }

  return result?.data?.getLoggedInEmail;
}

export async function getProfileData(
  user,
): Promise<IWithDBProperties<Profile> | undefined> {
  const graphqlClient = generateClient();
  const result = await graphqlClient.graphql({
    query: listProfiles,
    variables: {},
  });

  if (!result?.data?.listProfiles?.items?.length) {
    return undefined;
  }
  const userId = AuthUtils.getUserName(user);
  let profile = result?.data?.listProfiles?.items?.find(
    (x) => x.owner === userId,
  );
  if (profile && !profile?.email) {
    const email = await getLoggedInEmailFromAPI();
    if (email) {
      profile.email = email;
    }
  }
  // @ts-ignore
  return profile;
}

export async function getPrivatePost(id: string): Promise<Post | undefined> {
  let result: any;
  try {
    const graphqlClient = generateClient();
    result = await graphqlClient.graphql({ query: getPost, variables: { id } });
    return result?.data?.getPost;
  } catch (ex) {
    // AppLogger.error("Error when retrieving private posts", ex);
    // @ts-ignore
    return ex?.data?.getPost;
  }
}

export async function createPrivatePost(newPost: CreatePostInput) {
  const graphqlClient = generateClient();
  const result = await graphqlClient.graphql({
    query: createPost,
    variables: { input: newPost },
  });

  return result?.data?.createPost;
}

export async function updatePrivatePost(post: UpdatePostInput) {
  const graphqlClient = generateClient();
  const result = await graphqlClient.graphql({
    query: updatePost,
    variables: {
      input: { ...post, ...undefinedDBProperties },
    },
  });
  return result?.data?.updatePost;
}

export function reverseSortUpdatedAt(
  a: { updatedAt: string },
  b: { updatedAt: string },
) {
  return a == null || b == null ? 0 : a.updatedAt < b.updatedAt ? 1 : -1;
}

export async function listPrivatePosts() {
  try {
    const graphqlClient = generateClient();
    const result = await graphqlClient.graphql({
      query: listPosts,
      variables: {},
    });
    let posts = result?.data?.listPosts?.items;
    if (posts?.length) {
      posts = posts.sort(reverseSortUpdatedAt);
    }
    return posts;
  } catch (ex) {
    // AppLogger.error("Error when retrieving private posts", ex);
    // Normal issues not part of query
    // @ts-ignore
    return ex?.data?.listPosts?.items;
  }
}

export async function getPublicLinkData(id: string): Promise<Link | undefined> {
  try {
    const graphqlClient = generateClient();
    const result = await graphqlClient.graphql({
      query: getPublicLink,
      variables: { id },
    });
    const url = result?.data?.getPublicLink;
    if (!url?.length) {
      return undefined;
    }
    const link = await fetch(url).then((res) => res.json());
    return link;
  } catch (err) {
    AppLogger.error(`getPublicLinkData`, err);
    return undefined;
  }
}

export async function updatePublicLinkData(link: Link) {
  const graphqlClient = generateClient();
  const result = await graphqlClient.graphql({
    query: updatePublicLink,
    variables: {
      // @ts-ignore
      input: { id: link?.id, data: Serializer.serialize(link) },
    },
  });

  const publicLink = result?.data?.updatePublicLink as PublicLink;
  const updatedLink = Serializer.deserialize<Link>(publicLink?.internals);
  return updatedLink;
}

export async function listFirstPageLinks() {
  try {
    const ids = (await fetch(`/firstpage/links.json`).then((r) =>
      r.json(),
    )) as string[];

    if (!ids?.length) {
      return [];
    }

    const filteredIds = ids?.filter((x) => x?.length);
    const linkTexts: string[] = [];
    if (!filteredIds?.length) {
      return [];
    }
    for (var id of filteredIds) {
      try {
        const linkText = await readLinkJSON(id);
        if (linkText) {
          linkTexts.push(linkText);
        }
      } catch (ex) {
        AppLogger.debug(`Failed to read link ${id}`);
      }
    }
    if (!linkTexts?.length) {
      return [];
    }

    let links: Link[] = [];
    for (var linkText of linkTexts) {
      if (linkText != null) {
        try {
          const link = JSON.parse(linkText as string);
          links.push(link);
        } catch (ex3) {
          AppLogger.debug(`Error reading link ${ids?.length} ${linkText} `);
        }
      }
    }
    return links;
  } catch (err) {
    AppLogger.error(`Error in listFirstPageLinks ${err}`);
  }
}

function isUrl(s: string) {
  return s?.toLowerCase()?.startsWith("http");
}

function getIdFromUrl(s: string) {
  if (!s?.length) {
    return "";
  }
  const noArgs = s?.split("?")[0];
  const lastPath = noArgs.split("/")?.slice(-1)[0];
  const noExt = lastPath?.split(".")[0];
  return noExt;
}

function getLinkValue(l1: Link): number {
  let resultval = 0;

  if (l1.linkType === "legal") {
    resultval += 30000;
  } else if (l1.linkType === "article") {
    resultval += 20000;
  }

  return resultval;
}

export function articleFirstSort(l1: Link, l2: Link): number {
  let l1Val = getLinkValue(l1);
  let l2Val = getLinkValue(l2);

  if (l1Val > l2Val) {
    return 1;
  } else if (l1Val < l2Val) {
    return -1;
  }
  return 0;
}

export async function listPublicLinksData(user): Promise<Link[] | undefined> {
  try {
    const ids = (await fetch(`/firstpage/links.json`).then((r) =>
      r.json(),
    )) as string[];

    if (user) {
      const graphqlClient = generateClient();
      const result = await graphqlClient.graphql({
        query: listPublicLinks,
        variables: {},
      });
      const pubList = result?.data?.listPublicLinks as string[];
      if (pubList?.length) {
        for (const r of pubList) {
          if (!r?.length) {
            continue;
          }
          if (isUrl(r)) {
            ids.push(getIdFromUrl(r));
          } else {
            ids.push(r);
          }
        }

        ids.push();
      }
    }

    let links: Link[] = [];
    for (const id of ids) {
      try {
        const link = await readLinkJSON(id);
        if (!link?.length) {
          continue;
        }
        const json = JSON.parse(link);
        links.push(json);
      } catch (err) {
        AppLogger.debug(
          `Failed to parse linkId = ${id} ${JSON.stringify(err)}`,
        );
      }
    }
    links = links.sort(articleFirstSort);
    return links;
  } catch (ex) {
    AppLogger.error(`Failed to get public links`, ex);
  }
  return undefined;
}

export function generateNewLinkId() {
  const d = new Date();
  let s = d.getFullYear().toString().slice(-2);
  s += (d.getMonth() + 1).toString().padStart(2, "0");
  s += d.getDate().toString().padStart(2, "0");
  s += d.getHours().toString().padStart(2, "0");
  s += d.getMinutes().toString().padStart(2, "0");
  return s;
}

export function createDefaultLink(author) {
  const d = new Date();
  const link: Link = {
    id: generateNewLinkId(),
    authors: [author],
    created: d.toISOString(),
    published: "",
    title: "",
    slug: "",
    content: {
      excerpt: "",
      blocks: [],
    },
    linkType: "article",
  };
  return link;
}

export async function createDefaultPost(author) {
  const link = createDefaultLink(author);

  const postInternals = new PostInternals();
  postInternals.link = link;

  // @ts-ignore - ignore id it will be created when storing
  const post: CreatePostInput = {
    name: "",
    description: "",
    postType: "article",
    internals: Serializer.serialize(postInternals),
  };
  return post;
}
