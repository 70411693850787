

import { IWithDBProperties } from "./ClassUtils";


import Icons, { getIconElement, getIconWithBadge } from "../constants/Icons";
import { BOTypes, IdNameDescriptionWithMembersEditor } from "../constants/BOTypes";

export class BOUserRights {
  public canCreate?: boolean;
  public canRead?: boolean;
  public canUpdate?: boolean;
  public canDelete?: boolean;
}

export interface MembersAndEditors {
  owner: string;
  members?: string[]
  editors?: string[]
}


export type MemberTypes = "not_member" | "member" | "editor" | "owner";

export function memberTypeToIcon(memberType: MemberTypes, style: any, badgeProps?:any) {
  let iconName = Icons.unknownuser;
  switch (memberType) {
    case "not_member":
      iconName = Icons.notmember;
      break;

    case "member":
      iconName = Icons.member;
      break;

    case "editor":
      iconName = Icons.editor;
      break;
    case "owner":
      iconName = Icons.owner;
      break;
  }
  if(badgeProps){
    return getIconWithBadge(iconName,style,badgeProps);
  }
  return getIconElement(iconName, style)
}


export function getMemberType(familyUser: {owner?:string|null}, bo?: IWithDBProperties<IdNameDescriptionWithMembersEditor>): MemberTypes {
  const familyUserOwner = familyUser.owner;
  if(!familyUserOwner){
    return "not_member";
  }

  if (bo?.owner === familyUserOwner) {
    return "owner"
  } else if (bo?.editors?.length && bo?.editors.indexOf(familyUserOwner) >= 0) {
    return "editor"
  } else if (bo?.members?.length && bo?.members.indexOf(familyUserOwner) >= 0) {
    return "member"
  }
  return "not_member"


}


// Note this functionlity is provided to enable/disable elements in the UI, the enforcement of rights is made on the server
export function getBOUserRightsFromBO(bo?: any, owner?): BOUserRights {


  // AppLogger.debug(`getBOUserRightsFromBO BO:${dumpObj(bo)}`)

  let boUserRights: BOUserRights = { canCreate: false, canUpdate: false, canDelete: false, canRead: true }
  if (!bo || !owner) {
    return boUserRights;
  }

  // Object under creation
  // @ts-ignore
  if (!bo.id || (bo.email != null && bo.owner == null)) {
    return { canCreate: true, canUpdate: true, canDelete: false, canRead: true }
  }

  const boo = bo as IWithDBProperties<IdNameDescriptionWithMembersEditor>
  // Generic principles

  if (boo.owner != null && boo.owner === owner) {
    boUserRights = { canCreate: true, canUpdate: true, canDelete: true, canRead: true }
  } else if (boo.editors != null && boo.editors.indexOf(owner) > -1) {
    boUserRights = { canCreate: false, canUpdate: true, canDelete: false, canRead: true }
  } else if (boo.members != null && boo.members.indexOf(owner) > -1) {
    boUserRights = { canCreate: false, canUpdate: false, canDelete: false, canRead: true }
  } else {
    boUserRights = { canCreate: false, canUpdate: false, canDelete: false, canRead: true }
  }

  // Special treatment goes here


  return boUserRights;
}

export function copyUsers(parentBO: MembersAndEditors) {
  const members: string[] = [];
  const editors: string[] = [];
  if (parentBO.owner) {
    editors.push(parentBO.owner)
  }
  if (parentBO.members) {
    members.push(...parentBO.members)
  }
  if (parentBO.editors) {
    editors.push(...parentBO.editors)
  }

  return { members, editors }

}




export function getIconFromBOType(boType:BOTypes, style:any) {
  const iconName = Icons[boType.toLowerCase() + 's'];
  return getIconElement(iconName,style)

}

export function getIconWithBadgeFromBOType(boType:BOTypes, iconStyle:any, badgeProps:any) {
  const iconName = Icons[boType.toLowerCase() + 's'];
  return getIconWithBadge(iconName,iconStyle, badgeProps)

}
