import {
  confirmSignUp,
  signIn,
  signOut,
  signUp,
  resetPassword,
  resendSignUpCode,
  updatePassword,
} from "aws-amplify/auth";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Icons, {
  getIconElement,
  getIconFromProvider,
  getIconWithBadge,
} from "../../constants/Icons";
import styles from "../../constants/Styles";
import {
  AuthUtils,
  CognitoHostedUIIdentityProvider,
} from "../../utils/AuthUtils";
import { Button, Spinner, SuperInput, Text, View } from "../ui";

// Imported from @aws-amplify/auth
type LegacyProvider = "google" | "facebook" | "amazon" | "developer" | string;
type FederatedSignInOptions = {
  provider: CognitoHostedUIIdentityProvider;
  customState?: string;
};
type FederatedSignInOptionsCustom = {
  customProvider: string;
  customState?: string;
};

export interface SocialLoginProps {
  oAuthUser: any;
  oAuthError: any;
  hostUISignIn: any;
  appleSignIn: any;
  googleSignIn: any;
}

export interface SocialLoginState {
  showEmailPassword: boolean;
  validEmail: boolean;
  validPassword: boolean;
  validNewpassword: boolean;
  validVerificationCode: boolean;
  waitingForVerificationCode: boolean;
  emailErrorMessage: string | undefined;
  passwordErrorMessage: string | undefined;
  newpasswordErrorMessage: string | undefined;
  verificationCodeErrorMessage: string | undefined;
  generalErrorMessage: string | undefined;
  dlgstate: "signin" | "signup" | "forgotpassword" | "changepassword";
  showOtherButtons: boolean;
  loading: boolean;
}

export class AuthPage extends Component<SocialLoginProps, SocialLoginState> {
  lastHubEvent: any;
  refUserName: any;
  refPassword: any;
  refVerificationCode: any;
  refNewpassword: any;

  useremail: string | undefined;
  password: string | undefined;
  newpassword: string | undefined;
  verificationCode: string | undefined;
  constructor(props) {
    super(props);
    this.state = this.initState();

    this.refUserName = React.createRef();
    this.refPassword = React.createRef();
    this.refNewpassword = React.createRef();
    this.refVerificationCode = React.createRef();
  }

  initState(): SocialLoginState {
    const initialState: SocialLoginState = {
      showEmailPassword: false,
      validEmail: false,
      validPassword: false,
      validVerificationCode: false,
      emailErrorMessage: undefined,
      passwordErrorMessage: undefined,
      newpasswordErrorMessage: undefined,
      verificationCodeErrorMessage: undefined,
      dlgstate: "signin",
      validNewpassword: false,
      waitingForVerificationCode: false,
      loading: false,
      generalErrorMessage: undefined,
      showOtherButtons: false,
    };

    return initialState;
  }

  onShowEmailPassword() {
    this.setState({ showEmailPassword: true });
    this.refUserName?.current?.focus();
  }

  onChangeEmail(event) {
    let { validEmail, emailErrorMessage } = this.state;
    this.useremail = event?.target?.value?.toLowerCase();
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.useremail) {
      validEmail = re.test(this.useremail);
      if (emailErrorMessage && validEmail) {
        emailErrorMessage = undefined;
      }
    } else {
      validEmail = false;
      emailErrorMessage = "Empty password not allowed";
    }
    this.setState({ validEmail, emailErrorMessage });
  }

  validatePassword(password: string | undefined) {
    if (!password) {
      return false;
    }
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
    return re.test(password);
  }

  onChangePassword(event) {
    let { validPassword, passwordErrorMessage } = this.state;
    this.password = event.target.value;
    validPassword = this.validatePassword(this.password);
    if (passwordErrorMessage && validPassword) {
      passwordErrorMessage = undefined;
    }
    this.setState({ validPassword, passwordErrorMessage });
  }

  onChangeNewpassword(event) {
    let { validNewpassword, passwordErrorMessage } = this.state;
    this.newpassword = event.target.value;
    validNewpassword = this.validatePassword(this.newpassword);
    if (passwordErrorMessage && validNewpassword) {
      passwordErrorMessage = undefined;
    }
    this.setState({ validNewpassword, passwordErrorMessage });
  }

  onChangeVerificationCode(event) {
    let { validVerificationCode, verificationCodeErrorMessage } = this.state;
    this.verificationCode = event.nativeEvent.text as string;
    const re = /^[0-9]{6}/;
    validVerificationCode = re.test(this.verificationCode);
    if (verificationCodeErrorMessage && validVerificationCode) {
      verificationCodeErrorMessage = undefined;
    }
    this.setState({ validVerificationCode, verificationCodeErrorMessage });
  }

  showFields() {
    const { dlgstate, waitingForVerificationCode } = this.state;
    const showVerificationCode = waitingForVerificationCode;
    const showPassword =
      !waitingForVerificationCode && dlgstate !== "forgotpassword";
    const showNewpassword = dlgstate === "changepassword";

    return { showVerificationCode, showPassword, showNewpassword };
  }

  updateEmailAndPasswordMessage() {
    const {
      validEmail,
      validPassword,
      validVerificationCode,
      validNewpassword,
    } = this.state;
    const { showVerificationCode, showPassword, showNewpassword } =
      this.showFields();
    let focusSet = false;
    if (!validEmail) {
      this?.refUserName?.current?.focus();
      focusSet = true;
      this.setState({ emailErrorMessage: "Invalid email address!" });
    }
    if (showVerificationCode && !validVerificationCode) {
      if (!focusSet) {
        this.refVerificationCode?.current?.focus();
        focusSet = true;
      }
      this.setState({
        verificationCodeErrorMessage: "Invalid verification code, 6 digits!",
      });
    }
    if (showPassword && !validPassword) {
      if (!focusSet) {
        this.refPassword?.current?.focus();
        focusSet = true;
      }
      this.setState({ passwordErrorMessage: "Invalid password!" });
    }
    if (showNewpassword && !validNewpassword) {
      if (!focusSet) {
        this.refNewpassword?.current?.focus();
        focusSet = true;
      }
      this.setState({ newpasswordErrorMessage: "New password invalid!" });
    }
  }

  async signIn() {
    try {
      const {
        showEmailPassword,
        validEmail,
        validPassword,
        validVerificationCode,
      } = this.state;
      const { showVerificationCode } = this.showFields();
      this.setState({ generalErrorMessage: undefined, loading: true });
      if (
        showEmailPassword &&
        validEmail &&
        (validPassword || validVerificationCode)
      ) {
        if (!showVerificationCode) {
          await signIn({
            username: this.useremail as string,
            password: this.password as string,
          });
        } else {
          await confirmSignUp({
            username: this.useremail as string,
            confirmationCode: this.verificationCode as string,
          });
        }
      }
      this.updateEmailAndPasswordMessage();
    } catch (err) {
      const message = this.getGeneralErrorMessage("signin", err);

      this.setState({ generalErrorMessage: message });
    } finally {
      // this.setState({ loading: false })
    }
  }

  async manuallyVerify() {
    //
    try {
      const userName = AuthUtils.getUserName(this.lastHubEvent?.data);
      const userPoolId = AuthUtils.getUserPoolId(this.lastHubEvent?.data);
      const body = {
        userName,
        userPoolId,
      };
      await fetch(
        "https://verifyemail.dogcontroller.com/dogcontrollerb1d64360PostConfirmation-dogctrl",
        {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "text/plain",
          },
          body: JSON.stringify(body),
        },
      );
    } catch (error) {
      const message = this.getGeneralErrorMessage("manuall verify", error);
      this.setState({ generalErrorMessage: message });
    }
  }

  getGeneralErrorMessage(method, err) {
    let additionalMessage = "";
    let translatedMethod = method;
    if (method === "signup") {
      translatedMethod = "sign up";
      additionalMessage =
        "Perhaps you have used a different sign in method before.";
    } else if (method === "signin") {
      translatedMethod = "sign up";
    } else if (method === "forgotpassword") {
      translatedMethod = "forgot password";
    } else if (method === "change password") {
      translatedMethod = "change password";
    }

    return err.message
      ? `Failed to ${translatedMethod}:${err.message}. ${additionalMessage}`
      : `Failed to ${translatedMethod}. ${err}.`;
  }

  async signUpFederated() {
    try {
      const unverifiedEmail = this.getUnverifiedEmail();
      await resendSignUpCode({ username: unverifiedEmail });
      this.setState({
        waitingForVerificationCode: true,
        dlgstate: "signup",
        generalErrorMessage: undefined,
      });
    } catch (err) {
      const message = this.getGeneralErrorMessage("signup", err);
      this.setState({ generalErrorMessage: message });
    }
  }

  async signOut() {
    try {
      await signOut();
      this.setState({
        dlgstate: "signin",
        showEmailPassword: false,
        generalErrorMessage: undefined,
      });

      window.localStorage.clear();
    } catch (err) {
      const message = this.getGeneralErrorMessage("signout", err);
      this.setState({ generalErrorMessage: message });
    }
  }

  async submit() {
    const { generalErrorMessage } = this.state;
    this.setState({ loading: true });
    const { dlgstate } = this.state;
    try {
      if (dlgstate === "signup") {
        await signUp({
          username: this.useremail as string,
          password: this.password as string,
        });
        this.setState({
          waitingForVerificationCode: true,
          dlgstate: "signin",
          generalErrorMessage,
        });
      } else if (dlgstate === "forgotpassword") {
        await resetPassword({ username: this.useremail as string });
        this.setState({ waitingForVerificationCode: true, dlgstate: "signin" });
      } else if (dlgstate === "changepassword") {
        await updatePassword({
          oldPassword: this.password as string,
          newPassword: this.newpassword as string,
        });
        this.setState({ waitingForVerificationCode: true, dlgstate: "signin" });
      }
    } catch (err) {
      const message = this.getGeneralErrorMessage(dlgstate, err);
      this.setState({ generalErrorMessage: message });
    } finally {
      this.setState({ loading: false });
    }
  }

  isSubmitEnabled() {
    const { dlgstate, validEmail, validPassword, validNewpassword } =
      this.state;
    if (dlgstate === "signup") {
      return validEmail && validPassword;
    } else if (dlgstate === "forgotpassword") {
      return validEmail;
    } else if (dlgstate === "changepassword") {
      return validEmail && validPassword && validNewpassword;
    }
  }

  isSigninEnabled() {
    const {
      dlgstate,
      waitingForVerificationCode,
      validEmail,
      validPassword,
      validVerificationCode,
    } = this.state;
    if (dlgstate !== "signin") {
      return false;
    }
    if (waitingForVerificationCode) {
      return validEmail && validVerificationCode;
    } else {
      return validEmail && validPassword;
    }
  }

  signUp() {
    this.setState({ dlgstate: "signup" });
  }

  forgotPassword() {
    this.setState({ dlgstate: "forgotpassword" });
  }

  changePassword() {
    this.setState({ dlgstate: "changepassword" });
  }

  getDialogState() {
    const { oAuthError } = this.props;
    const { dlgstate, waitingForVerificationCode } = this.state;
    let message = "";
    if (oAuthError === "verifyContact") {
      message =
        "Sign in by pressing Verify Email, the email below will be listed in your profile (sometimes it differs from what you expect)";
    } else if (dlgstate === "signup") {
      message = "Sign up";
    } else if (dlgstate === "signin") {
      if (!waitingForVerificationCode) {
        message = "Sign in";
      } else {
        message = "Sign in, check your email for verification code!";
      }
    } else if (dlgstate === "forgotpassword") {
      message = "Forgot password";
    } else if (dlgstate === "changepassword") {
      message = "Change password";
    }
    return <div>{message}</div>;
  }

  async loginWithProvider(
    provider: CognitoHostedUIIdentityProvider | "googleSignIn" | undefined,
  ) {
    try {
      this.setState({ loading: true });
      let signinArgs:
        | FederatedSignInOptions
        | LegacyProvider
        | FederatedSignInOptionsCustom
        | undefined; // FederatedSigninOptions|undefined
      if (provider === CognitoHostedUIIdentityProvider.Apple) {
        signinArgs = { provider };
      } else if (provider === CognitoHostedUIIdentityProvider.Google) {
        const customProvider = "Google";
        signinArgs = { customProvider } as FederatedSignInOptionsCustom;
      } else if (provider === "googleSignIn") {
        this.props.googleSignIn();
        return;
      }

      // @ts-ignore
      await Auth.federatedSignIn(signinArgs);
      // AppLogger.debug(`federated user is logged in ${dumpObj(feduser, "feduser")}`)
    } catch (err) {
      this.setState({ loading: false });
    }
  }

  getUnverifiedEmail() {
    const { oAuthUser } = this.props;
    const unverifiedEmail =
      oAuthUser?.unverified?.email || oAuthUser?.attributes?.email;
    return unverifiedEmail;
  }

  getAppleGoogleButtons(inputStyle: any) {
    return [
      <View key="applelogin">
        <Button
          style={[inputStyle]}
          title="Apple"
          leftIcon={getIconWithBadge(Icons.apple)}
          onPress={this.loginWithProvider.bind(
            this,
            CognitoHostedUIIdentityProvider.Apple,
          )}
          type="clear"
        />
      </View>,
      <View key="googlelogin">
        <Button
          style={[inputStyle]}
          title="Google"
          leftIcon={getIconElement(Icons.google)}
          onPress={this.loginWithProvider.bind(
            this,
            CognitoHostedUIIdentityProvider.Google,
          )}
          type="clear"
        />
      </View>,
    ];
  }

  onCancel() {
    this.setState({ ...this.initState(), loading: false });
  }

  getOtherButtons() {
    const { dlgstate, waitingForVerificationCode, showOtherButtons } =
      this.state;
    const buttonStyle = { width: 200, margin: styles.margin };

    if (showOtherButtons) {
      return (
        <View>
          <View>
            <Button
              key="signupbutton"
              title="Sign up"
              type="clear"
              style={{ ...buttonStyle }}
              icon={getIconElement(Icons.signup)}
              onPress={this.signUp.bind(this)}
              disabled={dlgstate === "signup"}
            />
          </View>
          <View>
            <Button
              key="forgotbutton"
              title="Forgot password"
              type="clear"
              style={{ ...buttonStyle }}
              icon={getIconElement(Icons.forgotPassword)}
              onPress={this.forgotPassword.bind(this)}
              disabled={dlgstate === "forgotpassword"}
            />
          </View>
          <View>
            <Button
              key="changePassword"
              title="Change password"
              type="clear"
              style={{ ...buttonStyle }}
              icon={getIconElement(Icons.changePassword)}
              onPress={this.changePassword.bind(this)}
              disabled={dlgstate === "changepassword"}
            />
          </View>
          {dlgstate !== "signin" || waitingForVerificationCode ? (
            <View>
              <Button
                key="switchSigninPassword"
                title="Sign in with password"
                type="clear"
                style={{ ...buttonStyle }}
                icon={getIconElement(Icons.signIn)}
                onPress={() =>
                  this.setState({
                    dlgstate: "signin",
                    waitingForVerificationCode: false,
                  })
                }
                disabled={dlgstate === "signin" && !waitingForVerificationCode}
              />
            </View>
          ) : null}
          {dlgstate !== "signin" || !waitingForVerificationCode ? (
            <View>
              <Button
                key="switchSigninCode"
                title="Sign in with verification code"
                type="clear"
                style={{ ...buttonStyle }}
                icon={getIconElement(Icons.signIn)}
                onPress={() =>
                  this.setState({
                    dlgstate: "signin",
                    waitingForVerificationCode: true,
                  })
                }
                disabled={dlgstate === "signin" && waitingForVerificationCode}
              />
            </View>
          ) : null}
          {this.getAppleGoogleButtons(buttonStyle)}
        </View>
      );
    } else {
      return (
        <Button
          title="..."
          onPress={() => this.setState({ showOtherButtons: !showOtherButtons })}
        />
      );
    }
  }

  render() {
    const { oAuthUser, oAuthError } = this.props;
    const {
      dlgstate,
      showEmailPassword,
      passwordErrorMessage,
      emailErrorMessage,
      verificationCodeErrorMessage,
      newpasswordErrorMessage,
      generalErrorMessage,
      loading,
    } = this.state;
    const { showVerificationCode, showNewpassword, showPassword } =
      this.showFields();

    const inputContainerProps = {
      style: { margin: styles.margin, marginTop: 2 * styles.margin },
    };
    const buttonStyle = { width: 200, margin: styles.margin };

    let content: any = null;
    if (loading) {
      return <Spinner />;
    } else if (oAuthError === "verifyContact") {
      const unverifiedEmail =
        oAuthUser?.unverified?.email || oAuthUser?.attributes?.email;
      const providerName = AuthUtils.getProviderName(oAuthUser);
      const icon = getIconFromProvider(providerName);

      content = (
        <View>
          {this.getDialogState()}
          <SuperInput
            key={"txtsignedinuser"}
            label="Email"
            autoCompleteType="email"
            leftIcon={icon}
            value={unverifiedEmail}
            disabled={true}
            containerProps={inputContainerProps}
          />
          <Button
            title="Verify email"
            type="clear"
            style={{ ...buttonStyle, alignSelf: "flex-end" }}
            icon={getIconElement(Icons.submit)}
            onPress={this.manuallyVerify.bind(this)}
          />

          <Button
            title="Sign out"
            type="clear"
            style={{ ...buttonStyle, alignSelf: "flex-start" }}
            icon={getIconElement(Icons.signOut)}
            onPress={this.signOut.bind(this)}
          />
        </View>
      );
    } else {
      const startButtonStyle = {
        width: 200,
        margin: styles.margin,
        textAlign: "start",
      };
      const appleGoogleButtons = this.getAppleGoogleButtons({
        ...startButtonStyle,
      });
      appleGoogleButtons.push(
        <Button
          key="emailbutton"
          style={{ ...startButtonStyle }}
          title="Email and password"
          leftIcon={getIconElement(Icons.email)}
          onPress={this.onShowEmailPassword.bind(this)}
          type="clear"
        />,
      );
      content = (
        <View key="signin">
          {!showEmailPassword
            ? appleGoogleButtons?.map((x, i) => {
                return <View key={"signInB" + i}>{x}</View>;
              })
            : null}
          {showEmailPassword ? (
            <View>
              <View
                style={{
                  margin: "auto",
                  width: styles.drawWidth,
                }}
              >
                {this.getDialogState()}
                <SuperInput
                  key={"txtusername"}
                  label="Email"
                  ref={this.refUserName}
                  leftIcon={getIconElement(Icons.email)}
                  keyboardType={"email-address"}
                  autoCompleteType="email"
                  onChange={this.onChangeEmail.bind(this)}
                  errorMessage={emailErrorMessage}
                  containerProps={inputContainerProps}
                />
                {showVerificationCode ? (
                  <SuperInput
                    key={"verificationCode"}
                    label="Verification"
                    ref={this.refVerificationCode}
                    leftIcon={getIconElement(Icons.numeric)}
                    onChange={this.onChangeVerificationCode.bind(this)}
                    errorMessage={verificationCodeErrorMessage}
                    containerProps={inputContainerProps}
                  />
                ) : null}
                {showPassword ? (
                  <SuperInput
                    key={"txtpassword"}
                    label="Password"
                    ref={this.refPassword}
                    secureTextEntry={true}
                    leftIcon={getIconElement(Icons.password)}
                    onChange={this.onChangePassword.bind(this)}
                    errorMessage={passwordErrorMessage}
                    containerProps={inputContainerProps}
                  />
                ) : null}
                {showNewpassword ? (
                  <SuperInput
                    key={"txtnewpassword"}
                    label="New password"
                    ref={this.refNewpassword}
                    secureTextEntry={true}
                    leftIcon={getIconElement(Icons.password)}
                    onChange={this.onChangeNewpassword.bind(this)}
                    errorMessage={newpasswordErrorMessage}
                    containerProps={inputContainerProps}
                  />
                ) : null}
                {dlgstate === "signin" ? (
                  <View
                    key="signinbutton"
                    style={{ textAlign: "end", marginRight: styles.margin }}
                  >
                    <Button
                      title="Sign in"
                      type="clear"
                      icon={getIconElement(Icons.signIn)}
                      disabled={!this.isSigninEnabled()}
                      onPress={this.signIn.bind(this)}
                    />
                  </View>
                ) : null}
                {dlgstate !== "signin" ? (
                  <View key="submitnbutton">
                    <Button
                      title="Submit"
                      type="clear"
                      style={{ ...startButtonStyle, alignSelf: "flex-end" }}
                      icon={getIconElement(Icons.submit)}
                      disabled={!this.isSubmitEnabled()}
                      onPress={this.submit.bind(this)}
                    />
                  </View>
                ) : null}
                {this.getOtherButtons()}
              </View>
            </View>
          ) : null}
        </View>
      );
    }

    return (
      <View key="Authpage">
        <View style={{ margin: styles.margin }}>
          <Link to={"/eula"}>{"By signing in, I agree to EULA"}</Link>
        </View>
        <View style={{ margin: styles.margin }}>
          <Link to={"/terms"}>
            {"By signing in, I agree to Terms of Service"}
          </Link>
        </View>

        {generalErrorMessage ? (
          <Text
            style={{ fontSize: 15, fontWeight: "bold", color: "red" }}
            value={generalErrorMessage}
          />
        ) : null}
        {content}
        <View>
          <Link to={"/privacypolicy"}>{"Privacy Policy"}</Link>
        </View>
      </View>
    );
  }
}
